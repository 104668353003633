import { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave, faSyncAlt, faTrash } from '@fortawesome/free-solid-svg-icons'
import { ALLERGENS, formatDate, penceToCurrency } from '../../helpers/utils';

import { supabase } from '../../api'

export const RecipeForm = ({defaultItem, toggleModal, handleChange }) => {


    const [ recipe, setRecipe ] = useState(defaultItem || {});
    const [ saving, setSaving ] = useState(false);

    const save = async (cleanRecipe, newRecipe) => {

        //save recipe
        if (cleanRecipe.pid) {
          const { data, error } = await supabase
          .from('recipes')
          .update(cleanRecipe)
          .match({ pid: cleanRecipe.pid })
          .single()
          if (error) {
            console.error('error updating', error)
            return undefined
          }
          return newRecipe
        }
        else {
            const { data, error } = await supabase
            .from('recipes')
            .insert([
                cleanRecipe
            ])
            .single()
            if (error) {
                console.error('error inserting', error)
                return undefined
            }
            return newRecipe
        }
    }

      
    const handleSave = async () => {
        setSaving(true)
        const newRecipe = {...recipe, updated_at: (new Date()).toISOString()}

        setRecipe(newRecipe)
        const cleanRecipe = {...newRecipe}
        delete cleanRecipe.ingredients
        delete cleanRecipe.steps

        const result = await save(cleanRecipe, newRecipe)
        setSaving(false)
        
        if (result) {
            handleChange(result)
            toggleModal()
        }
    }

    

    return (
    <>
        <table style={{width:500}}>
        
        <tr>
            <td>Type</td>
            <td>
               {recipe.type}
            </td>
        </tr>

        <tr>
            <td>Imported Name</td>
            <td>
               {recipe.name}
            </td>
        </tr>

        <tr>
            <td>Display name</td>
            <td><input style={{width:500}} name='display_name' placeholder='Name to display on recipe card'  defaultValue={recipe.display_name} onChange={e => setRecipe({...recipe, display_name: e.target.value })} /></td>
        </tr>

        <tr>
            <td>Label name</td>
            <td><input style={{width:500}} name='label_name' placeholder='Name to display on label'  defaultValue={recipe.label_name} onChange={e => setRecipe({...recipe, label_name: e.target.value })} /></td>
        </tr>

        

        <tr>
            <td>Desc</td>
            <td>
               {recipe.desc}
            </td>
        </tr>

        <tr>
            <td>Terms</td>
            <td>
               {recipe.terms}
            </td>
        </tr>
        <tr>
            <td>Quantity</td>
            <td>
               {recipe.quantity} {recipe.quantity_unit}
            </td>
        </tr>

        {recipe.type === 'PRODUCT' && <tr>
            <td>Allergens <br/><br/>(on this product directly)</td>
            <td>
            <div className='check-list'>
            {ALLERGENS.map(a => {

                const toggleAllergen = (e) => {
                    let newArray = recipe.allergens ? recipe.allergens.split(',') : []
                    if (e.target.checked) {
                        newArray.push(a)
                    }
                    else {
                        newArray = newArray.filter(r => r !== a)
                    }
                    newArray = [...new Set(newArray)].sort((a,b)=>(a > b ? 1 : -1));
                    setRecipe({...recipe, allergens: newArray.length ? newArray.join(',') : null })
                }
                const defaultChecked = (recipe.allergens || '').split(',').includes(a)
            
                return (<label className={defaultChecked ? 'checked' : ''}>{a}<input type="checkbox" name='allergens' defaultChecked={defaultChecked} onClick={toggleAllergen} /></label>)
            
            })}
            </div>
                
        </td>
        </tr>}
        <tr>
            <td>Active/Visible</td>
            <td><label><input type="checkbox" name='active' defaultChecked={recipe.active} onClick={e => setRecipe({...recipe, active: e.target.checked })} /></label>
            &nbsp;&nbsp;<span className='sml'>When unchecked this item will be 'archived' and will not appear as a sub-recipe</span>
            </td>
        </tr>
        </table>

        <div className='right'>
        {recipe?.updated_at && <span className='sml'>last updated: {formatDate(recipe?.updated_at, true)}&nbsp;&nbsp;&nbsp;</span>}
                                

            <button className='clear' onClick={handleSave}>
                <FontAwesomeIcon spin={saving} icon={saving ? faSyncAlt : faSave} />&nbsp;
                Save
            </button>
        </div>
    </>
    )
}

export default RecipeForm;