import { useState } from 'react'
import { formatDate, stringToPence } from '../../helpers/utils';
import { format } from 'date-fns'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faTimes, faFileImport } from '@fortawesome/free-solid-svg-icons'
import { Loader } from '../../helpers/loader';
import { supabase } from '../../api'


import { read, utils } from 'xlsx';



const DO_SAVE = false


var Strings = {
    /**
     * Wrapped CSV line parser
     * @param s      String delimited CSV string
     * @param sep    Separator override
     * @attribution: http://www.greywyvern.com/?post=258 (comments closed on blog :( )
     */
    parseCSV : function(s,sep) {
        // http://stackoverflow.com/questions/1155678/javascript-string-newline-character
        var universalNewline = /\r\n|\r|\n/g;
        var a = s.split(universalNewline);
        for(var i in a){

            if (typeof a[i] !== 'function') {
            for (var f = a[i].split(sep = sep || ","), x = f.length - 1, tl; x >= 0; x--) {
                if (f[x].replace(/"\s+$/, '"').charAt(f[x].length - 1) === '"') {
                    if ((tl = f[x].replace(/^\s+"/, '"')).length > 1 && tl.charAt(0) === '"') {
                        f[x] = f[x].replace(/^\s*"|"\s*$/g, '').replace(/""/g, '"');
                      } else if (x) {
                    f.splice(x - 1, 2, [f[x - 1], f[x]].join(sep));
                  } else f = f.shift().split(sep).concat(f);
                } else f[x].replace(/""/g, '"');
              } a[i] = f;
            }
    }
    return a;
    }
}

export const ImportShopify = ({clients, products, categories, currentUser}) => {

    const [data, setData] = useState([])

    const [productsInImport, setProductsInImport] = useState()
    const [orders, setOrders] = useState([])
    const [loading, setLoading] = useState(false)
    const [fileName, setFileName] = useState('')
    const handleFile = async (e) => {
        const content = e.target.result;

        const csv = Strings.parseCSV(content)


        console.log(csv)
        setData(csv)



        //const products = await processProducts(data)
        //setProductsInImport(products)
        //const orders = await processOrders(data)
        //setOrders(orders)
        setLoading(false)
    }
      
    const handleChangeFile = (file) => {
        setFileName(file.name)

        setLoading(true)
        let fileData = new FileReader();
        fileData.onloadend = handleFile;
        fileData.readAsText(file);
    }

    const getCol = (row, name) => {
        return row.findIndex(col => col === name)
    }

    if (loading) return (
        <div><Loader /></div>
    )

    const processProducts = async (data) => {
        const nameCol = getCol(data[0], 'Product Description')
        const descCol = getCol(data[0], 'Pack Description')
        const pidCol = getCol(data[0], 'PID')
        const unitCost = getCol(data[0], 'PO UnitCost')
        const supplierCol = getCol(data[0], 'Supplier')

        const productsInImport = {}

        const { data: categories, error: cErr } = await supabase
          .from('categories')
          .select()
          .order('name')

        const { data: suppliers, error: sErr } = await supabase
          .from('suppliers')
          .select()
          .order('name')

        const { data: products, error: pErr } = await supabase
          .from('products')
          .select('*, categories (*), suppliers (*)')

        const newProducts = []
        
        data.map((row,i) => {

            if (i===0) return
            
            if (!row[pidCol]) return

            const existingProduct = products.find(p => parseInt(p.pid) === parseInt(row[pidCol]))

            const supplier = suppliers.find(s => s.name === row[supplierCol])
            const category = categories.find(c => row[nameCol].includes(c.name))

            console.log('>>', existingProduct, supplier, category)
            if (existingProduct) console.log(existingProduct.suppliers.name)

            if (row[pidCol]) productsInImport[row[pidCol]] = {
                name: row[nameCol],
                desc: row[descCol],
                category: category,
                supplierName: row[supplierCol],
                supplier: supplier,
                exists: !!existingProduct,
                existingProduct: existingProduct,
                unitCost: row[unitCost],
            }

        })

        for (var row of Object.entries(productsInImport)) {

            const product = row[1]

            if (!product.exists) newProducts.push({
                pid: parseInt(row[0]),
                name: product.name,
                details: product.desc,
                category_id: product.category?.id || null,
                supplier_id: product.supplier?.id || null,
                unit_cost: stringToPence(product.unitCost),
                active: true,
            })

        }

        // save new products now
        if (DO_SAVE && newProducts.length) {
            const { data, error } = await supabase
            .from('products')
            .insert(newProducts)
            .select()
            if (error) {
                console.error('error inserting new products', error)
            }
            if (data) {
                console.log('new products created', data)
            }
        }


        // update ALL default prices

        console.log('> > > > > > > > >')
        console.log('> > > > > > > > >')
        console.log('> > > > > > > > >')
        console.log('> > > > > > > > >')
        
        for (var row of Object.entries(productsInImport)) {

            const product = row[1]
            const pid = parseInt(row[0])
            const obj = { unit_cost: stringToPence(product.unitCost) }
            
            console.log('want to set price [', pid, '] to ', obj)

            if (DO_SAVE) {
                const { data, error } = await supabase
                    .from('products')
                    .update(obj)
                    .match({ pid: pid })
                    .select()

                    if (error) {
                        console.error('error updating price', error)
                    }
                    if (data) {
                        console.log(`price changed correctly [x ${data.length}]`, data)
                    }
            }
        }


        return productsInImport

    }


    const processOrders = async (data) => {
        const orderStatusCol = getCol(data[0], 'Order Status')
        const deliveryDateCol = getCol(data[0], 'Delivered Date')
        const shopCol = getCol(data[0], 'Purchaser Unit')
        const pidCol = getCol(data[0], 'PID')
        const poNumCol = getCol(data[0], 'OrderID')
        const quantity = getCol(data[0], 'PO QTY')
        const unitCost = getCol(data[0], 'PO UnitCost')

        const orders = {}

        const { data: products, error: pErr } = await supabase
          .from('products')
          .select('*, categories (*), suppliers (*)')


        const { data: shops, error: sErr } = await supabase
            .from('shops')
            .select('*, brands (*)')

        // orders

        const rowRef = (row) => {
            return row[deliveryDateCol]+'::'+row[shopCol]
        }

        console.log('products=',products)

        data.map((row,i) => {

            if (i===0) return
            if (!row[shopCol]) return
            if (row[orderStatusCol] !== 'Awaiting Delivery') return
            const shopObj = shops.find(s => s.import_as === row[shopCol])

            const dtp = row[deliveryDateCol].split('/')
            const dt = new Date(`${dtp[2]}/${dtp[1]}/${dtp[0]}`)

            orders[rowRef(row)] = {
                shop_name: row[shopCol],
                shop_id: shopObj.id,
                delivery_dt: row[deliveryDateCol],
                dt: dt,
                products: [],
                purchase_order_nums: [],
            }

        })

        data.map((row,i) => {

            if (i===0) return
            if (!row[shopCol]) return
            if (!orders[rowRef(row)]) return 

            //still match on PID as we've just created some new records 
            const product = products.find(p => parseInt(p.pid) === parseInt(row[pidCol]))

            if (product) {
                const orderProduct = {
                    pid: row[pidCol],
                    product: product,
                    quantity: parseInt(row[quantity]),
                    unit_cost: stringToPence(row[unitCost]),
                }

                console.log('orderProduct=', orderProduct)

                const duplicateProduct = orders[rowRef(row)].products.find(p => parseInt(p.pid) === parseInt(row[pidCol]))

                if (duplicateProduct) duplicateProduct.quantity += parseInt(row[quantity])
                else orders[rowRef(row)].products.push(orderProduct)

                //purchase_order_num
                if (!orders[rowRef(row)].purchase_order_nums.includes(row[poNumCol])) orders[rowRef(row)].purchase_order_nums.push(row[poNumCol])
            }

        })

        const orderArray = Object.entries(orders)

        //save orders
        for (let index = 0; index < orderArray.length; index++) {
            await saveOrder(fileName, orderArray[index][1])
        }
        
        return orders
    }




    const saveOrder = async (fileName, order) => {


        if (!order.shop_id) {
            order.saved = false;
            return;
        }

        const data = {
            import_file: fileName,
            created_by: currentUser.id,
            created_at: new Date(),
            imported_at: new Date(),
            imported_by: currentUser.id,
            shop_id: order.shop_id,
            purchase_order_num: order.purchase_order_nums.length ? order.purchase_order_nums.join(', ') : '',
            delivery_dt: format(order.dt,'dd-MMM-yyyy')
        }

        // delete existing orders
        const { data: existingOrder, error: existingErr } = await supabase
          .from('orders')
          .select()
          .match({ shop_id: order.shop_id, delivery_dt: format(order.dt,'dd-MMM-yyyy') })
          .single()

        if (existingErr) console.error(existingErr)

        if (DO_SAVE && existingOrder) {
            console.log('existing order found: ', existingOrder.id)

            const { data: deleteExistingOP, error: delOPErr } = await supabase
                .from('orderProducts')
                .delete()
                .match({ order_id: existingOrder.id })

            if (delOPErr) console.error(delOPErr)
            if (deleteExistingOP) console.log('deleted ', deleteExistingOP)

            const { data: deleteExistingO, error: delOrderErr } = await supabase
                .from('orders')
                .delete()
                .match({ id: existingOrder.id })

            if (delOrderErr) console.error(delOrderErr)
            if (deleteExistingO) console.log('deleted ', deleteExistingO)
        }


        if (DO_SAVE) {
            const { data: orderSaved, error: orderError } = await supabase
                .from('orders')
                .insert([
                    data
                ])
                .single()
                .select()
            if (orderError) {
                console.error('order not saved:', orderError)
                order.saved = false
                return
            }
            
            order.saved = true;
            console.log('saved order', orderSaved)
        

            // insert productOrders
            if (orderSaved) {

                const orderProducts = order.products.map(op => ({
                    order_id: orderSaved.id,
                    product_id: op.product.id,
                    quantity: op.quantity,
                    unit_cost: op.unit_cost,
                }))

                const { data2, error2 } = await supabase
                    .from('orderProducts')
                    .insert(orderProducts)
                    .select()
                if (data2) {
                    console.log('created ops', data2)
                }
                if (error2) {
                    console.error('error creating ops', error2)
                }
            }
        
        }
    }

    //

    const showProducts = () => {
        if (!productsInImport) return 'Loading...'

        return (
            <table>
            {
            Object.entries(productsInImport).map(row => (
                <tr>
                    <td>{row[0]}</td>
                    <td>{row[1].existingProduct?.id || ''}</td>
                    <td>{row[1].supplier ? row[1].supplier.name : `${row[1].supplierName} - UNEXPECTED!`}</td>
                    <td>{row[1].category ? row[1].category.name : 'UNKNOWN!'}</td>
                    <td>{row[1].name}</td>
                    <td>{row[1].desc}</td>
                    <td>{row[1].exists ? 'FOUND' : 'NEW'}</td>
                </tr>
            ))}
            </table>
        )
    }

    const showOrders = () => {
        if (!orders) return 'Loading...'

        
        return Object.entries(orders).map(row => (
                <table className={`order ${row[1].saved ? '' : 'failed'}`}>
                    <tr>
                        <td colspan='3'><b>{row[1].shop_name} - for delivery on {formatDate(row[1].dt, false)}</b></td>
                    </tr>
                    <tr>
                        <td colspan='3'>purchase order number: <b>{row[1].purchase_order_nums.join(', ')}</b></td>
                    </tr>
                    <tr>
                        <td colspan='3'>{row[1].shop_id ? 'SHOP RECOGNISED': 'UNRECOGNISED SHOP'}</td>
                    </tr>
                    {row[1].products.map(p => (
                     <tr>
                         <td>{p.product?.categories.name}</td>
                         <td>{p.product?.name || '?'}</td>
                         <td className='right'>{p.quantity}</td>
                     </tr>
                     
                    ))}
                    <tr>
                         <td className='right' colspan='3'>
                             {row[1].saved ? 'SAVED SUCCESSFULLY' : 'NOT SAVED'}&nbsp;&nbsp;
                             <FontAwesomeIcon icon={row[1].saved ? faCheck : faTimes} />

                         </td>
                     </tr>
                </table>
            ))

    }

    if (data.length) {

        return (
            <>
                <h1>Filename</h1>
                <div className='center'>{fileName}</div>

                <br/><br/><br/>

                <h1>Products</h1>
                {showProducts()}

                <h1>Orders</h1>
                {showOrders()}
            </>
        )
    }

    return (
        <div className='outer'>
            <div className='file-upload-wrapper'>
            <label tabIndex={0} className='file-upload-button' htmlFor='file-upload-shopify'><FontAwesomeIcon icon={faFileImport} />&nbsp;&nbsp;Import from SHOPIFY</label>
            <input id='file-upload-shopify' type="file" accept=".csv" onChange={e => handleChangeFile(e.target.files[0])} /> 
            </div>
        </div>
    )
    
}

export default ImportShopify;
