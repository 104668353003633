
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";

import { ELNStore } from '../../store'
import groupBy from 'lodash.groupby';

import { useLocation } from 'react-router-dom';

import { Loader } from '../../helpers/loader';
import { format, eachDayOfInterval } from 'date-fns'
import ExportCal from '../../components/ExportCal'
import { CsvBuilder } from 'filefy';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPrint, faFileExport, faPlayCircle, faSpinner, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import { supabase } from '../../api'
import { penceToCurrency } from "../../helpers/utils";

export const Exports = ({match}) => {

  const currentUser = ELNStore.useState(s => s.currentUser);
  
  const range = ELNStore.useState(s => s.range);

  const fromDate = range && range.length && range[0] || new Date()
  const toDate = range && range.length && range[1] || new Date()


  const dateArray = fromDate && toDate ? eachDayOfInterval( { start: fromDate, end: toDate }) : []

  const [loading, setLoading] = useState(false)
  const [active, setActive] = useState(false)
  const [complete, setComplete] = useState(false)

  const [orders, setOrders] = useState()

  const [supplier, setSupplier] = useState()

  const [products, setProducts] = useState([])
  const [suppliers, setSuppliers] = useState([])

  const rangeInWords = () => {
    if (!fromDate || !toDate || !dateArray || !dateArray.length) return 'Loading...'
    const fromStr = format(fromDate,'dd MMM yyyy')
    const toStr = format(toDate,'dd MMM yyyy')
    if (fromStr === toStr) return fromStr
    return `${fromStr} - ${toStr}`
  }

  const abort = () => {
      setActive(false)
      setComplete(false)
      setLoading(false)
  }

  const loadAll = async () => {

    if (!dateArray || !dateArray.length || !products || !products.length) return

    let orderList = []

    setActive(true)
    setLoading(true)
    setComplete(false)

    if (!dateArray.length) return
    
    products.forEach(p => {
        p.table = []
    })

    for (let dti = 0; dti < dateArray.length; dti++) {

        const dt = dateArray[dti]

        const { data: orders, error } = await supabase
          .from('orders')
          .select('*, orderProducts (quantity, unit_cost, products:product_id (*)), shops (*, brands (*))')
          .match({ voided: false, order_type: 0, delivery_dt: format(new Date(dt),'yyyy-MM-dd') })

        orderList = [...orderList, ...orders]
    }

    for (let index = 0; index < orderList.length; index++) {
        const order = orderList[index];
        const shopOnDate = `${order.shop_id}:${format(new Date(order.delivery_dt),'dd-MMM-yyyy')}`
        order.orderProducts?.forEach(op => {
          //product array
          const productMaster = products.find(q => q.id === op.products.id)

          if (!productMaster.table[shopOnDate]) productMaster.table[shopOnDate] = []
          
          productMaster.table[shopOnDate].push({quantity: op.quantity, unit_cost: op.unit_cost})

        })
    }

    
    setOrders(orderList)
    setComplete(true)
    
    setLoading(false)
    setActive(false)
  }

  useEffect(() => {
    async function initialize() {

      const { data: products, error: pErr } = await supabase
          .from('products')
          .select('*, categories (*), suppliers (*)')
      
      setProducts(products)

      const { data: suppliers, error: sErr } = await supabase
          .from('suppliers')
          .select()
          .order('name')

      setSuppliers(suppliers)

    }
    initialize();
  }, [])


  useEffect(() => {
    
    setComplete(false)
    setLoading(false)
  }, [range])


  const exportCSV = () => {
    new CsvBuilder(`PW-${rangeInWords()}.csv`)
    .setColumns(productHeadings)
    .addRows(productTable())
    .exportFile();
  }

  const doPrint = () => {
    window.print()
  }


  const productHeadings = ['report id', 'section', 'Item / EPos Code', 'Quantity', 'Value', 'Date', 'Reference', 'Internal Description']

  const productTable = () => {

    let tbl = []
    products.map((product,i) => {
        if (product.table) {
            Object.keys(product.table).map((key) => {

                const shop_id = key.split(':')[0]
                const dt_str = key.split(':')[1]
                const dt = format(new Date(dt_str),'dd/MM/yyyy')
                const dtSort = new Date(dt_str).toISOString() 

                const total = penceToCurrency(product.table[key].reduce(function (acc, item) { return acc + (item.quantity * item.unit_cost) }, 0));

                const quantity = product.table[key].reduce(function (acc, item) { return acc + item.quantity }, 0)
                //const total = penceToCurrency(product.table[key].quantity * product.table[key].unit_cost)
                tbl.push([
                    `{CPU-Cakes}`,
                    'dishsales',
                    product.pid ? `${shop_id}-${product.pid}` : '',
                    quantity,
                    total,
                    dt,
                    '',
                    product.name,
                    dtSort,
                ])
            })
        }
    })

    //sort on extra date field
    tbl = tbl.sort(function(a, b) { return (`${a[8]} ${a[7]}` > `${b[8]} ${b[7]}` ? 1 : -1) })
    // remove date field
    tbl = tbl.map(t => t.slice(0,-1))
  
    return tbl
  }

  const drawProductTable = () => {
      const tbl = productTable()
      return (
          <>
          <div className='table-wrapper'>
            <table>
                <thead>
                <tr>
                    {productHeadings.map(p => <th>{p}</th>)}
                </tr>  
                </thead>
                <tbody>

                    {tbl.map((row,i) => {

                        return (
                            <tr>
                                {row.map(r => (
                                    <td>{r}</td>
                                ))}        
                            </tr>
                        )
                    })}

                </tbody>
            </table>
          </div>
          </>
      )
  }


  if (!suppliers || !suppliers.length || !products) return <></>
  return (
    <>
        
        <div className='outer'>
          <h1 className={''}>For Delivery: {rangeInWords()}</h1>
          <h2 className='print-only'>{supplier?.name || ''}</h2>
          <div className='center'><ExportCal />
            {active && <button disabled onClick={abort}><FontAwesomeIcon icon={faSpinner} spin={true} />&nbsp;&nbsp;Processing...</button>}
            {!active && dateArray.length<=31 && <button onClick={loadAll}><FontAwesomeIcon icon={faPlayCircle} />&nbsp;&nbsp;START LOAD</button>}
            {!active && dateArray.length>31 && <button><FontAwesomeIcon icon={faExclamationTriangle} />&nbsp;&nbsp;31 days limit</button>}
          </div>
          <br/>
          <div className='group'>
            <div className='group-name'>Filter Supplier</div>
            <ul className='button-list'>
              {suppliers && suppliers.sort().map(s => {
                const setThisSupplier = () => {
                    setSupplier(supplier?.id === s.id ? undefined : s)
                }
                return (
                    <li key={s} className={supplier?.id === s.id ? 'selected' : ''}>
                        <button onClick={setThisSupplier}>{s.name || '[NOT SET]'}</button>
                    </li>
                )
              })}
            </ul>
          </div>
          {loading && <Loader />}
          {orders?.length && !active && complete && !loading ? 
          <>
            <ul className='button-list'>
                

              <li><button onClick={exportCSV}><FontAwesomeIcon icon={faFileExport} />&nbsp;&nbsp;Export CSV</button></li>
              <li><button onClick={doPrint}><FontAwesomeIcon icon={faPrint} />&nbsp;&nbsp;Print</button></li>
            </ul>
            {drawProductTable()}
          </>
          : ''}
        </div>
    </>
  )
}

export default withRouter(Exports);
