import { NavLink } from "react-router-dom";
import { ELNStore } from '../../store';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStore, faCheese, faFileImport, faFileExport, faUser } from '@fortawesome/free-solid-svg-icons'

export const AdminMenu = () => {

    const currentUser = ELNStore.useState(s => s.currentUser);

    if (!currentUser || !currentUser.admin) return ''
    return (
        
            <ul className='row'>

                {currentUser.p_admin===2 && <li>
                    <NavLink to="/admin" exact activeClassName="current"><FontAwesomeIcon icon={faFileImport} />&nbsp;&nbsp;Imports</NavLink>
                </li>}
                
                {currentUser.p_admin>=1 && <li>
                    <NavLink to="/admin/shops" exact activeClassName="current"><FontAwesomeIcon icon={faStore} />&nbsp;&nbsp;Shops</NavLink>
                </li>}
                {currentUser.p_admin>=1 && <li>
                    <NavLink to="/admin/products" exact activeClassName="current"><FontAwesomeIcon icon={faCheese} />&nbsp;&nbsp;Products</NavLink>
                </li>}
                {currentUser.superadmin ? <li>
                    <NavLink to="/admin/users" exact activeClassName="current"><FontAwesomeIcon icon={faUser} />&nbsp;&nbsp;Users</NavLink>
                </li> : ''}
                {currentUser.p_admin>=1 && <li>
                    <NavLink to="/admin/exports" exact activeClassName="current"><FontAwesomeIcon icon={faFileExport} />&nbsp;&nbsp;PW Export</NavLink>
                </li>}
                {currentUser.p_admin>=1 &&<li>
                    <NavLink to="/admin/invoice-exports" exact activeClassName="current"><FontAwesomeIcon icon={faFileExport} />&nbsp;&nbsp;Invoice Export</NavLink>
                </li>}
                

            </ul>
    )
}

export default AdminMenu;