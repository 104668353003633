import React from "react";
import { Importer } from '../../components/Importer'
import { RecipeImporter } from '../../components/RecipeImporter'
import { ELNStore } from '../../store'
import { Loader } from '../../helpers/loader';
import ImportShopify from "../../components/ImportShopify";

export const Admin = () => {

    const currentUser = ELNStore.useState(s => s.currentUser);

    if (!currentUser) return <Loader />

    return (
    <div className='outer'>
        

        {currentUser.p_admin>=2 && 
        <>
        <hr></hr>
        <Importer currentUser={currentUser} />

        <hr></hr>
        <RecipeImporter currentUser={currentUser} />
   
        <hr></hr>
    
        <ImportShopify currentUser={currentUser} />
        </>}

    </div>
    )
}

export default Admin;