import { faImage, faTimes, faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { supabase } from '../../api';
import useModal from '../../helpers/useModal';
import ELNStore from '../../store';
import Modal from '../Modal';

const STORAGE_URL = 'https://ciemyksmehzdtljwdyot.supabase.co/storage/v1'


export const Photo = ({bucket, path, disabled, filename, ext = 'png', onStatusUpdate, order}) => {

    const currentUser = ELNStore.useState(s => s.currentUser);

    const [status, setStatus] = useState('LOADING')
    const { showModal, toggleModal } = useModal()

    const handleFailure = () => {
        setStatus('FAILED')
        if (onStatusUpdate) onStatusUpdate(false)
    }

    const handleSuccess = () => {
        setStatus('LOADED')
        if (onStatusUpdate) onStatusUpdate(true)
    }

    const handleUpload = async (e) => {
        const selectedFile = e.target.files[0]
        const { data, error } = await supabase
        .storage
        .from(bucket)
        .upload(`${path}/${filename}.${ext}`, selectedFile, {
            cacheControl: '3600',
            upsert: false
        })

        if (error) console.error(error)
        if (data) setStatus('LOADED')
        
    }

    const handleRemove = async () => {
        if (!window.confirm('Really remove this image?')) {
            return
        }
        const { data, error } = await supabase
        .storage
        .from(bucket)
        .remove([`${path}/${filename}.${ext}`])
        if (error) console.error('Error sent ', error)
        if (data) handleFailure()
    }

    const fullFileName = `${bucket}/${path}/${filename}.${ext}`
    
    const hasPermission = order ? currentUser.p_orders>=2 : currentUser.p_speccards>=2

    if (status === 'FAILED') return (
        <div className='photoWrapper'>
            {hasPermission &&
            <><label className={`file-upload-button ${disabled ? 'disabled' : ''}`} for={`photo-upload-${path}-${filename}`}><FontAwesomeIcon icon={faImage} /> &nbsp;Upload photo</label>
            <input disabled={disabled} id={`photo-upload-${path}-${filename}`} type="file" accept=".png,.jpg,.jpeg" onChange={handleUpload} /></>}
        </div>)

    else return (
        <>
        <Modal isShowing={showModal} hide={toggleModal} showHeader={false}>
        <img onClick={toggleModal} className='photo-fullsize' src={`${STORAGE_URL}/object/public/${bucket}/${path}/${filename}.${ext}?random=${Math.floor(Math.random()*99999999)}`} alt={fullFileName} />
        </Modal>
        <div className='photoWrapper photoShown'>
            {status === 'LOADED' ? <button disabled={disabled} className='inv' onClick={handleRemove}><FontAwesomeIcon icon={faTimes} /></button> : ''}
            <img className='photo' onClick={toggleModal} src={`${STORAGE_URL}/object/public/${fullFileName}?random=${Math.floor(Math.random()*99999999)}`} onError={handleFailure} onLoad={handleSuccess} alt={fullFileName} />
        </div>
        </>
    )
    
  }

export default Photo;