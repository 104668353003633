import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave, faSyncAlt } from '@fortawesome/free-solid-svg-icons'

import { supabase } from '../../../api'

export const CampaignForm = () => {


    const [ lead, setLead ] = useState({leadtype:'CC2023'})
    
    const [ saving, setSaving ] = useState(false);

    const [ done, setDone ] = useState(false)


    const save = async (lead) => {

        let success = true

            const { data, error } = await supabase
            .from('leads')
            .insert(lead)
            .single()
            if (error) {
                console.error('error inserting', error)
                success = false
            }
        

        return success
    }

      
    const handleSave = async () => {
        

        if (!lead.first_name || (!lead.tel && !lead.email)) {
            alert('Please enter either a telephone number or email address')
            return
        }

        setSaving(true)
        const result = await save(lead)

        if (result) setDone(true)

        setSaving(false)

    }

    return (
        <div className='campaignWrapper'>
    <div className='campaign'>
    <div className="logo">
              <img src='/eln-london.png' alt="EL&amp;N London" />
              <h1>London's most instagrammable cafe</h1><br/>
              
                <img style={{width:'100%', maxWidth: 600, padding:'0 10px'}} alt='International coffee day' src='/cc2023.jpg' />
            </div>

        <table className='customerForm'>
 
        <tr>
            <td>First name<br/><input name='first_name' style={{width:280}} placeholder=''  defaultValue={lead.first_name} onChange={e => setLead({...lead, first_name: e.target.value })} /></td>
        </tr>
        <tr>
            <td>Last name<br/><input name='last_name' style={{width:280}} placeholder=''  defaultValue={lead.last_name} onChange={e => setLead({...lead, last_name: e.target.value })} /></td>
        </tr>
        <tr>
            <td>Telephone number<br/><input name='tel' style={{width:280}} placeholder=''  defaultValue={lead.tel} onChange={e => setLead({...lead, tel: e.target.value })} /></td>
        </tr>
        <tr>
            <td>Email address<br/><input name='email' style={{width:280}} placeholder=''  defaultValue={lead.email} onChange={e => setLead({...lead, email: e.target.value })} /></td>
        </tr>

        <tr>
            <td>Interest<br/><textarea name='details' style={{width:280, height:200}} placeholder='' defaultValue={lead.details} onChange={e => setLead({...lead, details: e.target.value })} /></td>
        </tr>

        {done && <tr>
            <td>
                <h1>Thank you.</h1>
            </td>
            </tr>}

        {!done && <tr>
            <td className='right'><button className='inv' onClick={handleSave}>
                <FontAwesomeIcon spin={saving} icon={saving ? faSyncAlt : faSave} />&nbsp;
                Submit
            </button></td>
        </tr>}

        
        </table>

        <br/><br/><br/><br/><br/><br/><br/><br/><br/>
            

    </div>
    </div>
    )
}

export default CampaignForm;