import { supabase } from '../../api'
import { Logo } from "../../components/Logo";
import ELNStore from '../../store';

export default function Locked() {


    const currentUser = ELNStore.useState(s => s.currentUser);

    const signOut = async () => {

        ELNStore.update(s => { s.currentUser = undefined })
        const { error } = await supabase.auth.signOut()
        document.location.reload()
      }


    if (!currentUser) return ''
    return (
      <>
         
        <div className='outer signed-out'>

        <Logo />
          <div className='login-form'>
            <form>
            <h2>Your account is not yet active</h2>
            <br/>
            You have logged in with the following email address:<br/><br/>
            <strong>{currentUser.auth.email}</strong>
            
            <br/><br/>
            Please contact us to finish setting up your account

            
            <br/><br/>
            <button className='inv' onClick={signOut}>Sign in with another account</button>

            </form>
          </div>
         
        </div>
        </>
    )
}