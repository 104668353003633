import React, { useEffect, useState } from "react";

import './normalize.css';
import './App.css';

// dont forget we're using firestore v8 connection syntax here
import 'firebase/firestore';

import "firebase/auth";

import Menu from './components/Menu';

import Home from './pages/Home';
import Shops from './pages/Shops';
import Products from './pages/Products';
import SpecCards from './pages/SpecCards';
import Users from './pages/Users';
import Admin from './pages/Admin';
import Exports from './pages/Exports';
import InvoiceExports from './pages/InvoiceExports';
import Table from './pages/Table';
import Order from './pages/Order';
import Orders from './pages/Orders';
import SignIn from './pages/SignIn';
import SignUp from './pages/SignUp';
import NoPermission from './pages/NoPermission';

import { ELNStore } from './store';
import { Loader } from './helpers/loader';

import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import { supabase } from './api'
import OrderSearch from "./pages/OrderSearch";
import { sortShops } from "./helpers/utils";
import WeddingForm from "./pages/campaigns/Wedding";
import CampaignForm from "./pages/campaigns/cc2023";
import ResetPassword from "./pages/ResetPassword";
import UpdatePassword from "./pages/UpdatePassword";
import Locked from "./pages/Locked";

// eslint-disable-next-line no-extend-native
Array.prototype.groupBy = function (funcProp) {
  return this.reduce(function (acc, val) {
      (acc[funcProp(val)] = acc[funcProp(val)] || []).push(val);
      return acc;
  }, {});
};



const App = () => {

  const viewAsCurrentUser = ELNStore.useState(s => s.currentUser);
  const viewAs = ELNStore.useState(s => s.viewAs);

  const [loading,setLoading] = useState(true);
  const [currentUser, setCurrentUser] = useState();
  const [locked, setLocked] = useState(false)

  //const [user, setUser] = useState(null);

  useEffect(() => {
    
    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {

      if (_event === 'INITIAL_SESSION') return
      if (_event === 'SIGNED_IN') return
      if (_event === 'SIGNED_OUT') {
        document.location.reload()
        return
      }
      loadAll()
      
    })

    
    
    loadAll()
    return () => subscription.unsubscribe()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (viewAs && viewAsCurrentUser) setCurrentUser(viewAsCurrentUser)
  },[viewAs, viewAsCurrentUser])

  const loadAll = async () => {

    setLoading(true);
    const processedUser = await initializeUser()

    const { data: allUsers } = await supabase
      .from('users')
      .select('id, firstname, lastname')

    setCurrentUser(processedUser)

    if (processedUser) {
      ELNStore.update(s => {
        s.allUsers = allUsers;
        s.currentUser = processedUser;
      })
    }

    setLoading(false);
  }


  const initializeUser = async () => {

    const {
      data: { session },
    } = await supabase.auth.getSession()

    const user  = session?.user

    let currentUser = {}

    if (!user) return
    // search for db user record.  if none match id, create one and ask for name???
    const { data: userFromUUID, error1 } = await supabase
      .from('users')
      .select('*, shops (*, brands (*))')
      .match({ uuid: user.id })
      .single()

    if (error1) console.error(error1)

    if (userFromUUID) {
      const userToUpdate = { ...userFromUUID, lastsignin_at: new Date() }
      delete userToUpdate.shops

      const { error2 } = await supabase
        .from('users')
        .update(userToUpdate)
        .match({ uuid: user.id })
        .single()

      if (error2) console.error(error2)

      currentUser = {...userFromUUID, auth: user}
    }
    else {
      const { data: userFromEmail, error2 } = await supabase
        .from('users')
        .select('*, shops (*, brands (*))')
        .filter('email', 'eq', user.email)
        .single()
        .select()
      
      if (error2) {
        console.error(error2)
        return
      }

      if (userFromEmail) {
        const userToUpdate = { ...userFromEmail, uuid: user.id, lastsignin_at: new Date() }
        delete userToUpdate.shops
        const { data: userInserted } = await supabase
          .from('users')

          .update(userToUpdate)
          .match({ email: user.email })
          .single()
          .select()

        if (userInserted) {
          currentUser = {...userInserted, auth: user}
        }
      }   
    }

    if (currentUser.active) {
      if (currentUser.admin) {
        const { data } = await supabase
          .from('shops')
          .select('*, brands (*)')
          currentUser = { ...currentUser, shops: sortShops(data) }
      }
      else {
        currentUser = { ...currentUser, shops: sortShops(currentUser.shops) }
      }

    }

    if (!currentUser.active || currentUser.shops?.length === 0) {
      setLocked(true)
      currentUser = {locked: true, auth: user}
    }
    else setLocked(false)

    return currentUser
  }

  if (loading) return (<div className='outer loader'><Loader /></div>)

  return (
    <div id="app">

      <Router>
        {!locked && currentUser && <Menu />}
        <main>
          
          {!locked && currentUser && (
          <Switch>
            
            <Route path="/spec-cards/:search?/:pid?" component={currentUser.p_speccards>=1 ? SpecCards : NoPermission} />
            
            
            <Route path="/admin/shops" component={currentUser.admin && currentUser.p_admin>=1 ? Shops : NoPermission} />
            <Route path="/admin/products" component={currentUser.admin && currentUser.p_admin>=1 ? Products : NoPermission} />
            
            <Route path="/admin/exports" component={currentUser.admin && currentUser.p_admin>=1 ? Exports : NoPermission} />
            <Route path="/admin/invoice-exports" component={currentUser.admin && currentUser.p_admin>=1 ? InvoiceExports : NoPermission} />
            <Route path="/admin/users" component={currentUser.superadmin ? Users : NoPermission} />

            <Route path="/admin" component={currentUser.admin && currentUser.p_admin>=1 ? Admin : NoPermission} />

            

            <Route path="/orders/search/:term?" exact component={currentUser.p_orders>=1 ? OrderSearch : NoPermission} />
            <Route path="/orders/:date" exact component={currentUser.p_orders>=1 ? Orders : NoPermission} />
            <Route path="/orders/:date/NEW/:shopID" exact component={currentUser.p_orders>=2 ? Order : NoPermission} />
            <Route path="/orders/:date/:orderID" exact component={currentUser.p_orders>=1 ? Order : NoPermission} />
            
            <Route path="/production-sheet/:date" component={currentUser.p_orders>=1 ? Table : NoPermission} />
            <Route path="/reset-password" component={ResetPassword} />
            <Route path="/update-password" component={UpdatePassword} />
            <Route path="/sign-in" component={SignIn} />
            <Route path="/sign-up" component={SignUp} />

            <Route path="/" exact component={Home} />
          </Switch>
          )}
          {locked && 
          <Switch>
            <Route path="/" component={Locked} />

            </Switch>}
          {!currentUser && 
          <Switch>
            <Route path="/wedding" component={WeddingForm} />
            <Route path="/cc2023" component={CampaignForm} />
            
            <Route path="/reset-password" component={ResetPassword} />

            <Route path="/sign-in" component={SignIn} />
            <Route path="/sign-up" component={SignUp} />
            <Route path="/" component={SignIn} />
            </Switch>
            }
          
          
        </main>

        
      </Router>

    </div>
  );
};

export default App;
