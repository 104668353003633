
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";

import { ELNStore } from '../../store'

import { deliveryAddress, getUniqueBy, OrderLink, plural, toStartOfDay } from '../../helpers/utils';
import { Loader } from '../../helpers/loader';
import { format, isEqual } from 'date-fns'
import Cal from '../../components/Cal'
import { CsvBuilder } from 'filefy';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPrint, faFile, faFileExport, faBirthdayCake, faFileAlt } from '@fortawesome/free-solid-svg-icons'
import { supabase } from '../../api'

export const Table = ({match}) => {

  const currentUser = ELNStore.useState(s => s.currentUser);
  
  const dateStr = match.params.date;
  const currentDateFromPath = toStartOfDay(new Date(dateStr))
  let currentDate = ELNStore.useState(s => s.currentDate);

  if (!isEqual(currentDateFromPath, currentDate)) {
    ELNStore.update(s => { s.currentDate = currentDateFromPath })
    currentDate = currentDateFromPath
  }

  const [loading, setLoading] = useState(true)
  const [orders, setOrders] = useState()

  const [customOrders, setCustomOrders] = useState()

  const [rowDetails, setRowDetails] = useState()
  const [rowHeadings, setRowHeadings] = useState()
  const [rows, setRows] = useState()

  const [supplier, setSupplier] = useState()

  const [products, setProducts] = useState()
  const [suppliers, setSuppliers] = useState()
  const [categories, setCategories] = useState()

  const [filteredOrders, setFilteredOrders] = useState()
  const [filteredOrdersToHide, setFilteredOrdersToHide] = useState()

  const [showDeliveryNotes, toggleShowDeliveryNotes] = useState(false)

  const categoryName = (id) => {
    return categories.find(c => c.id === id).name
  }
  const buildTable = () => {

    // called again when switching between table and delivery-note mode because in delivery-note mode we want to show ALL Shops
    if (!orders || !categories) return

    const filteredProducts = supplier!==undefined ? products.filter(product => product.supplier_id === supplier.id) : products
          
    const filteredOrders = orders.filter(order => {
      let subCount = 0
    
      filteredProducts.map(product => {
        const op = order.orderProducts.find(op => op.products.id === product.id)
        const quantity = op && op.quantity ? op.quantity : 0;
        if (quantity>0) subCount += 1;
      })

      if (order.order_type === 1) {
        order.subCount = order.orderCustoms.length
        return true
      }
      return subCount > 0
    })

    const filteredOrdersToHide = filteredOrders.filter(o => !o.shops.show_in_delivery_table)
    const filteredOrdersToShow = showDeliveryNotes ? filteredOrders : filteredOrders.filter(o => o.order_type === 0 && o.shops.show_in_delivery_table)
    
    const shopColumns = getUniqueBy(filteredOrdersToShow?.map(o => o.shops), 'id')
    let rows = categories.map((category) => {

      const products = filteredProducts.filter(p => p.categories.id === category.id).sort((a,b) => a.name > b.name ? 1 : -1)

      return (
        products.map(product => {
          let total = 0;
          
          return (
            [categoryName(product.category_id), product.pid, `${product.name}${product.details ? ' ('+product.details+')' : ''}`,
            ...shopColumns.map(shop => {

              const ordersForShop = filteredOrdersToShow.filter(o => o.shop_id === shop.id)

              if (!shop.rows || shop.rows.length===0) shop.rows = []
              let count = 0

              ordersForShop.forEach(order => {
                const op = order.orderProducts.find(op => op.products.id === product.id)
                const quantity = op && op.quantity ? op.quantity : 0;
                count += quantity;
                if (quantity>0) shop.rows.push(product.id)
              })

              total += count;
              return (
                (count || '')
              )
              
            }),
            total]
          )
        })
      )
    })

    rows = [].concat.apply([], rows);

    
    const shopNames = shopColumns?.map(s => {
      const arr = []
      if (s.brands.name) arr.push(s.brands.name)
      arr.push(s.name)
      return arr.join('\n')
    })

    const shopDetails = shopColumns?.map(s => {
      const arr = []
      const ordersForShop = filteredOrdersToShow.filter(o => o.shop_id === s.id)
      ordersForShop.map(o => {

        arr.push(`${o.id}${o.purchase_order_num ? `: ${o.purchase_order_num}` : ''}${o.notes ? '*' : ''}\n`)
      })
      arr.push(`(${[...new Set(s.rows)].length})`)
      return arr.join('')
    })
    
    //clean
    rows = rows.filter(r => r[r.length-1] !== 0) // remove rows with a total of zero

    setFilteredOrders(filteredOrders)
    setFilteredOrdersToHide(filteredOrdersToHide)

    setRowHeadings(["", "", "", ...shopNames, "TOTAL"])
    setRowDetails(["", "", "", ...shopDetails, ""])
    setRows(rows)
    
  }


  useEffect(() => {
    async function initialize() {

      setLoading(true)

      if (!products) {
        const { data: categories, error: cErr } = await supabase
          .from('categories')
          .select()
          .order('name')

        const { data: suppliers, error: sErr } = await supabase
          .from('suppliers')
          .select()
          .order('name')

        const { data: products, error: productsErr } = await supabase
          .from('products')
          .select('*, categories (*)')
          
        setProducts(products)
        setCategories(categories)
        setSuppliers(suppliers)
      }

      let op = 'not.eq'
      let arr = '0'

      if (!currentUser.admin) {
        op = 'in'
        arr = `(${currentUser.shops.map(s => s.id)})`
      }

      const { data: orders, error: ordersErr } = await supabase
        .from('orders')
        .select('*, shops (*, brands (*)), orderProducts (quantity, products:product_id (*)), orderCustoms (*)')
        .match({ delivery_dt: format(new Date(currentDate),'yyyy-MM-dd') })
        .filter('shop_id', op, arr)
        .eq('voided',false)

      const sortedOrders = orders.sort(function(a,b) { return `${a.shops.brands.name.split(' ').join('') || 'x'} ${a.shops.name}` > `${b.shops.brands.name.split(' ').join('') || 'x'} ${b.shops.name}` ? 1 : -1})

      const userShops = currentUser.shops.map(s => s.id)

      const filtered = sortedOrders.filter(o => userShops.includes(o.shop_id))

      const custom = sortedOrders.filter(o => o.order_type === 1 && userShops.includes(o.shop_id))

      setOrders(filtered)
      setCustomOrders(custom)
      
      setLoading(false)

    }
    initialize();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDate])

  useEffect(() => {
    buildTable()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orders, supplier, showDeliveryNotes])


  const toggleDeliveryNotes = () => {
    toggleShowDeliveryNotes(t => !t)
  }

  if (loading || !rowHeadings) return (
    <>
      <Cal tablePage />
      <div className='outer'>
        <h1 style={{marginBottom:40}}>For delivery on {format(currentDate,'PPPP')}</h1>
        <Loader />
      </div>
    </>
  )

  const exportCSV = () => {
    new CsvBuilder(`${format(currentDate,'yyyy-MM-dd')}.csv`)
      .setColumns(rowHeadings)
      .addRow(rowDetails)
      .addRows(rows)
      .exportFile();
  }

  const doPrint = () => {
    window.print()
  }



  const drawDeliveryNotes = () => {

    if (!categories) return
    return (
      <>

        {filteredOrders.map((order,i) => {
          const orderProducts = order.orderProducts.map(op => {
            const catName = categoryName(op.products.category_id)
            return { ...op, catName }
          }).sort((a,b) => { return `${a.catName}:${a.products.name}` > `${b.catName}:${b.products.name}` ? 1 : -1 })

          let lastCategory = ''
          return (
            <div className='delivery-note'>
              <table className='page'>
              <thead><tr><th></th></tr></thead>
              <tbody className='stretch'>
                <div className='delivery-note-top'>
                  <div className='left'>
                    <h4>{order.order_type === 1 ? 'CUSTOM ' : ''}ORDER</h4>
                    <h2><OrderLink order={order} /></h2>
                    
                  </div>
                  <div className="logo">
                    <img src='/eln-london.png' alt="EL&amp;N London" />
                    <h1>B2B</h1>
                  </div>
                  <div className='right'>
                    <h4>PO NUM</h4>
                    <h2>{order?.purchase_order_num?.split(',').map(o => <>{o}<br/></>)}</h2>
                  </div>
                  
                </div>
                
                <h3>Delivery note</h3>
                
                <h1><span>{order.shops.brands.name}</span>{order.shops.name}</h1>
                <h2>{format(currentDate,'PPPP')}</h2>
                {order.purchaseOrderNum ? <h1><span>PO num: {order.purchaseOrderNum}</span></h1> : ''}
               
                {order.order_type === 0 ? <table>
                  {orderProducts.map(op => {
                    const product = op.products
                    if (isNaN(parseInt(op.quantity))) return ''

                    let showCat = false

                    const catName = categoryName(product.category_id)
                    if (catName !== lastCategory) {
                      lastCategory = catName
                      showCat = true
                    }

                    return (
                        <tr className={showCat ? 'cat' : ''}>
                          <td><b>{showCat ? catName : ''}</b></td>
                          <td className={``}>{product.pid}</td>
                          <td className={``}>{product.name || ''}{product.details ? ' ('+product.details+')' : ''}</td>
                          <td className='quantity'>{op.quantity || ''}</td>
                        </tr>
                    )
                  })}
                </table> : <br/>}
                

                {order.order_type === 1 ? 
                  <>
                  <h4 className='pre-line'><b>DELIVER TO</b>
                  {order.deliver_to_shop ? order.shops.name : deliveryAddress(order)}
                  </h4>
                  <CustomOrder deliveryNote order={order} />
                  
                  </> : ''}

                {order.notes ? <h4 className='pre-line'><b>SPECIAL INSTRUCTIONS</b>{order.notes}</h4> : ''}
              </tbody>
              <tfoot>
              <tr>
                <td className="spacing-for-footer">&nbsp;</td>
              </tr>
              </tfoot>
              </table>
              <div className='fixed-footer'>
                    Damask Catering Ltd - 19 Gorst Road, NW10 6LA<br/>
                    Exclusive suppliers to EL&amp;N<br/>
                    Company No: 07974944<br/>
                    Registered Address: Jubilee House, Townsend Lane, London, NW9 8TZ
              </div>
            </div>
          )
        })}
          
      </>
    )
  }

  const drawTable = () => {

    let lastCategory = ''

    if (rows.length === 0) return ''
    return (
      <>
        <div className='table-wrapper'>
          <table className='production-sheet'>
            <thead>
              <tr>
                {rowHeadings.map((heading,i) => {
                  if (i<2) return ''
                  return (
                      <th className='center'>
                        {heading}
                      </th>
                  )
                })}
              </tr>  
              </thead>
              <tbody>
              <tr>
                {rowDetails.map((brand,i) => {
                  if (i<2) return ''
                  return (
                      <th className='center sml pre' >
                        {brand}
                      </th>
                  )
                })}
              </tr>   
 
            
              {rows && rows.map((row) => {

                let showCategory = false;
                  if (lastCategory !== row[0]) {
                    showCategory = true
                    lastCategory = row[0]
                  }
                  return (
                    <>
                      {showCategory && (
                      <tr>
                        <td className='category' colSpan={rowHeadings.length-3}>{row[0]}</td>
                        <td className='category'></td>
                      </tr>
                      )}
                      <tr>
                        {
                        row.map((cell,i) => {
                          if (i<2) return ''
                          const cls = (i===2) ? '' : 'center' 
                          if (i === row.length-1) return (
                            <td className={`${cls} lge`}>{cell || ''}</td>
                        )
                          return (
                              <td className={cls}>{cell || ''}</td>
                          )
                        })}
                        
                      </tr>
                      
                    </>
                  )
              })}
            </tbody>
          </table>
        </div>
        <br/><br/>
        {ordersWithNotes?.length ?
        <div className='table-footer'>
          <h2><FontAwesomeIcon icon={faFileAlt} />&nbsp;&nbsp;*SPECIAL INSTRUCTIONS</h2>

          {ordersWithNotes.map(order => {
              if (!order.notes) return ''
              return (
                <div className='special-instructions'>
                  <h2 className='center'>{order.shops.brands.name} {order.shops.name} - <OrderLink order={order}/></h2>
                  <div className='pre-line' style={{minWidth:'300px'}}>{order.notes}</div>
                </div>
              )
            })}
        </div>
        : ''}

        {filteredOrdersToHide?.length ?
        <div className='table-footer'>
          <h2><FontAwesomeIcon icon={faFileAlt} />&nbsp;&nbsp;ADDITIONAL ORDERS</h2>
          
          {filteredOrdersToHide.map(order => {
              return (
                <div className='additional-order'>
                  <h2 className='center'>{order.shops.brands.name} {order.shops.name} - <OrderLink order={order}/></h2>
                </div>
              )
            })}
        </div>
        : ''}
      </>
    )
  }
  

  const CustomOrder = ({deliveryNote, order}) => {
    return (
      <div className='custom-order'>
        {deliveryNote ? '' : <><h2 className='center'>{order.shops.brands.name} {order.shops.name} - <OrderLink order={order}/></h2>
        {order.notes}
        <br/></>}
        <div className='custom-items'>
        {order.orderCustoms.map((c,i) => 
        
            <table>
              <tr>
                <th></th>
                <th >Item {i+1}</th>
              </tr>
              <tr>
                <td>Sponge</td>
                <td>{c.sponge}</td>
              </tr>
              <tr>
                <td>Filling</td>
                <td>{c.filling}</td>
              </tr>
              <tr>
                <td>Fruit/Jam</td>
                <td>{c.fruit}</td>
              </tr>
              <tr>
                <td>Topping</td>
                <td>{c.topping}</td>
              </tr>
              <tr>
                <td>Size</td>
                <td>{c.size}</td>
              </tr>
              <tr>
                <td>Shape</td>
                <td>{c.shape}</td>
              </tr>
              <tr>
                <td>Message</td>
                <td>{c.message}</td>
              </tr>
              <tr>
                <td>Notes</td>
                <td>{c.notes}</td>
              </tr>
            </table>

          )}
        </div>
      </div>
    )
  }

  const ordersWithNotes =  filteredOrders?.filter(o => o.order_type === 0 && !!o.notes);

  return (
    <>
        <Cal tablePage />
        <div className='outer'>
          <h1 className={showDeliveryNotes ? 'no-print' : ''}>For delivery on {format(currentDate,'PPPP')}</h1>
          <h2 className='print-only'>{supplier?.name || ''}</h2>
          {currentUser.admin && <div className='group'>
            <div className='group-name'>Filter Supplier</div>
            <ul className='button-list'>
              {suppliers && suppliers.sort().map(s => {
                const setThisSupplier = () => {
                    setSupplier(supplier?.id === s.id ? undefined : s)
                }
                return (
                    <li key={s} className={supplier?.id === s.id ? 'selected' : ''}>
                        <button onClick={setThisSupplier}>{s.name || '[NOT SET]'}</button>
                    </li>
                )
              })}
            </ul>
          </div>}

          {filteredOrders.length ? 
          <>
            <ul className='button-list'>
              {rows.length ? <li><button onClick={exportCSV}><FontAwesomeIcon icon={faFileExport} />&nbsp;&nbsp;Export Table as CSV</button></li> : ''}
              <li className={showDeliveryNotes ? 'selected' : ''}><button  onClick={() => toggleDeliveryNotes()}><FontAwesomeIcon icon={faFile} />&nbsp;Delivery Notes</button></li>
              <li><button onClick={doPrint}><FontAwesomeIcon icon={faPrint} />&nbsp;&nbsp;Print</button></li>
            </ul>
            {showDeliveryNotes ? drawDeliveryNotes() : drawTable()}
          </>
          : ''}

          {!showDeliveryNotes && 
          <div className='table-footer'>
          <h2><FontAwesomeIcon icon={faBirthdayCake} />&nbsp;&nbsp;{plural(customOrders.length, 'Custom Order')}</h2>
          
          {customOrders?.map(order => {
              return (
                <CustomOrder order={order} />
              )
            })}
          
          </div>
          }

          {!filteredOrders.length && !customOrders.length ? <div className='center'><br/><br/><br/>No orders found - change filter</div> : ''}

        </div>

        {showDeliveryNotes ? '' : 
        <style dangerouslySetInnerHTML={{__html: `
          @page {
            size: A4 landscape;
          }
        `}} />
        }
    </>
  )
}

export default withRouter(Table);
