import React from "react";
import { ELNStore } from '../../store';

import { useHistory } from 'react-router-dom';
import { format } from 'date-fns'


export const Home = () => {

    const history = useHistory();

    const currentUser = ELNStore.useState(s => s.currentUser);
    const currentDate = ELNStore.useState(s => s.currentDate);

    if (currentUser && !currentUser.admin) {
        history.push(`/orders/${format(currentDate,'dd-MMM-yyyy')}`)
    }

    if (!currentUser) {
        document.location = '/'
        return ''
    }
    
    return (
    <div className='outer'>        
        <h1>Welcome {currentUser.firstname} {currentUser.lastname}</h1>
        <br/>
        <div className='col centre'>
        {(!currentUser || !currentUser.admin) && <>Click the ORDERS button above to start</>}
        </div>
    </div>
    )
}

export default Home;