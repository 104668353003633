
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";

import { ELNStore, updateProducts } from '../../store'
import groupBy from 'lodash.groupby';
import { Loader } from '../../helpers/loader';
import { format, eachDayOfInterval, add, sub } from 'date-fns'
import ExportCal from '../../components/ExportCal'
import { CsvBuilder } from 'filefy';
import { useHistory } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPrint, faFileExport, faPlayCircle, faSpinner, faExclamationTriangle, faSearch, faTable, faFileAlt, faFileInvoiceDollar } from '@fortawesome/free-solid-svg-icons'
import { supabase } from '../../api'
import { formatDate, OrderIcon, penceToCurrency, StatusToken } from "../../helpers/utils";
import { DebounceInput } from "react-debounce-input";
import ShopList from "../../components/ShopList";
import OrderForm from "../../components/OrderForm";

export const OrderSearch = ({match}) => {

  const MAX_RECORDS = 500

  const history = useHistory();

  const currentUser = ELNStore.useState(s => s.currentUser);
  const allUsers = ELNStore.useState(s => s.allUsers);
  const products = ELNStore.useState(s => s.products);

  const productsLastRequested = new Date().getTime() - ELNStore.useState(s => s.productsLastRequested)

  const range = ELNStore.useState(s => s.range);

  const [view, setView] = useState('TABLE')

  const [loading, setLoading] = useState(false)

  
  const [active, setActive] = useState(false)
  const [term, setTerm] = useState(match.params.term || '')
  const [complete, setComplete] = useState(false)

  const [orders, setOrders] = useState()
  const [shops, setShops] = useState()
  const [brands, setBrands] = useState()

  const [shopFilter, setShopFilter] = useState()


  const doPrint = () => {
    window.print()
  }

  const abort = () => {
      setActive(false)
      setComplete(false)
      setLoading(false)
  }

  const setViewTable = () => {
    setView('TABLE')
  }

  const setViewInvoices = () => {
    setView('INVOICES')
  }

  useEffect ( () => {
    async function fetchData() {

      if (productsLastRequested > 60000) updateProducts()

      const brandArray = [...currentUser.shops].sort((a,b)=>(a.brands.name > b.brands.name ? 1 : -1)).groupBy(s => s.brands.name)
      setBrands(brandArray)

    }
    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const loadAll = async () => {

    setActive(true)
    setLoading(true)
    setComplete(false)
    setViewTable()

    const fromDateAdjusted = add(range[0], { hours: 4}).toISOString().slice(0,10)
    const toDateAdjusted = sub(range[1], { hours: 4}).toISOString().slice(0,10)
    
    let query = supabase
      .from('orders')
      .select('*, orderProducts (quantity, unit_cost, products:product_id (*)), shop:shops (*, brands (*)), orderCustoms (*)')
      .gte('delivery_dt', fromDateAdjusted)
      .lte('delivery_dt', toDateAdjusted)
      .limit(500)
    // shop
    if (shopFilter) {
      let arr = shopFilter.split(',')
      let op = 'eq'
      if (arr.length === 1) {
        arr = arr[0]
      }
      else {
        op = 'in'
        arr = `(${arr.join(',')})`
      }

      query = query.filter('shop_id', op, arr)
    } else {
      query = query.filter('shop_id', 'in', `(${currentUser.shops.map(s => s.id)})`)
    }

    // search param
    
    if (term && term.length>1) {
      const ors = []
      if (!isNaN(term)) ors.push(`id.eq.${term}`)
      ors.push(`purchase_order_num.ilike.%${term}%`)
      ors.push(`notes.ilike.%${term}%`)
      ors.push(`delivery_customer.ilike.%${term}%`)
      query = query.or(ors.join(','))
    }

    query = query.order('delivery_dt')

    const { data: orders, error } = await query

    setOrders(orders)

    setComplete(true)
    
    setLoading(false)
    setActive(false)
  }

  useEffect(() => {
    setComplete(false)
    setLoading(false)
  }, [range])
  
  const getTotal = (order) => {
    let total = 0
    if (order.order_type === 0) total = order.orderProducts?.reduce(function (acc, row) { return acc + (row.quantity * row.unit_cost) }, 0);
    
    if (order.order_type === 1) total = order.orderCustoms?.reduce((agg, oc) => agg + oc.unit_cost, 0)

    return total
  }

  const getItems = (order) => {
    if (order.orderCustoms?.length) return order.orderCustoms.length
    if (!order.orderProducts.length) return 0
    const total = order.orderProducts.reduce(function (acc, row) { return acc + (row.quantity) }, 0);
    return total
  }

  const handleChangeSearch = (e) => {
    setTerm(e.target.value)
    //updateSearch(search)
  }
  
  const ShowOrders = ({view}) => {

    if (view === 'TABLE') return <Table />
    else {

      return orders.map( o => {      
        const currentDate = new Date(o.delivery_dt)
        return <OrderForm readOnly invoiceView={view === 'INVOICES'} defaultOrder={o} shop={o.shop} products={products} currentUser={currentUser} allUsers={allUsers} currentDate={currentDate} />
        
      })

    }

  }

  const Table = () => {
      return (
          <>
          <div className='table-wrapper'>
            <table>
                <thead>
                <tr>
                  <th>Order num</th>
                  <th>For delivery</th>
                  <th>Brand</th>
                  <th>Shop</th>
                  <th>PO num</th>
                  <th>Status</th>
                  <th>Items</th>
                  <th className='right'>Total</th>
                  <th></th>
                </tr>  
                </thead>
                <tbody>

                    {orders.map((o,i) => {         
                      const viewOrder = () => {
                        history.push(`/orders/${format(new Date(o.delivery_dt),'dd-MMM-yyyy')}/${o.id}`)
                      }

                      return (
                        <tr>
                          <td className='no-wrap'><OrderIcon order={o} />&nbsp;<strong>{o.id}</strong></td>
                          <td className='no-wrap'>{formatDate(new Date(o.delivery_dt))}</td>
                          <td>{o.shop.brands.name}</td>
                          <td>{o.shop.name}{o.shop.active ? '' : <i>inactive</i>}</td>
                          <td>{o.purchase_order_num}</td>
                          <td><StatusToken order={o} /></td>
                          <td>{getItems(o)}</td>
                          <td className='right'>{penceToCurrency(getTotal(o))}</td>
                          <td><button onClick={viewOrder} className={`inv sml order-${o.order_type}`}>{o.id}</button></td>
                      </tr>  
                      )
                    })}

                    
                </tbody>
            </table>
          </div>
          </>
      )
  }

  return (
    <>
        
        <div className='outer search-outer'>
        
        <h1 className='no-print'>Search orders</h1>
          
              <div className='center no-print'>
              
              <DebounceInput autoFocus disabled={loading} placeholder='search ORDER ID, PO NUM etc' debounceTimeout={10} className='search' onChange={handleChangeSearch} value={term} />
              &nbsp;&nbsp;
              <ExportCal showReset />
              &nbsp;&nbsp;
              <ShopList brands={brands} handleSelect={setShopFilter} />
              &nbsp;&nbsp;&nbsp;&nbsp;
              {active && <button disabled onClick={abort}><FontAwesomeIcon icon={faSpinner} spin={true} />&nbsp;&nbsp;Searching...</button>}
                {!active && <button className='inv' onClick={loadAll}><FontAwesomeIcon icon={faSearch} />&nbsp;&nbsp;SEARCH</button>}
              
              </div>
              <br/>

              {orders?.length ? <ul className='button-list no-print'>
              <li>Display</li>
              <li className={view === 'TABLE' ? 'selected' : ''}>
                <button onClick={setViewTable}><FontAwesomeIcon icon={faTable} />&nbsp;&nbsp;Table</button>
              </li>
              <li className={view === 'INVOICES' ? 'selected' : ''}>
                <button disabled={orders?.length > 40} title={orders?.length > 40 ? 'Can only print up to 40 invoices at a time' : 'Click to view list of invoices'} onClick={setViewInvoices}><FontAwesomeIcon icon={faFileInvoiceDollar} />&nbsp;&nbsp;Invoices</button>
              </li>
              <li>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <button disabled={loading} className='inv' onClick={doPrint}><FontAwesomeIcon icon={faPrint} />&nbsp;&nbsp;Print</button>                
              </li>
            </ul> : ''}
  

          {loading && <Loader />}
          
          {orders?.length && !active && complete && !loading ? <ShowOrders view={view} /> : ''}

        </div>

        <div className='no-print'>{orders?.length && view === 'TABLE' && complete ? <><br/><div className='center'>{orders?.length} orders shown {orders?.length === MAX_RECORDS ? ' - Limit reached! - change filter' : ''}</div><br/></> : ''}</div>
    </>
  )
}

export default withRouter(OrderSearch);
