import { supabase } from '../../api'
import { useState } from "react";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
import { Logo } from "../../components/Logo";

export default function Login() {

  const [email, setEmail] = useState('')
  
  const [status, setStatus] = useState('NEW')
  const [message, setMessage] = useState('')

  const send = async () => {

    setStatus('SAVING')
    setMessage('Saving...')
    const { data, error } = await supabase.auth.resetPasswordForEmail(email, {
      redirectTo: `https://elnb2b.com/update-password`,
    })

    if (error) {
        setStatus('FAILED')
        setMessage(error.message)
    }
    else {
      setStatus('DONE')
      setMessage('')
    }
  }

    return (
      <>
        
            
        <div className='outer signed-out'>

        <Logo />
          <div className='login-form'>
            <form>
            <h2>How to change your password:</h2>

            <ol>
                <li>Enter your email address here</li>
                <li>Click the button</li>
                <li>Wait for an email to arrive in your inbox</li>
                <li>Click the link in the email and enter a new password</li>
            </ol>

            <br/>

            <label><span>Email address</span><input type='text' name='email' autoComplete="email" onChange={e => setEmail(e.target.value)} /></label>
  
            <button disabled={status==='SAVING' || status==='DONE'} className='inv' onClick={send}>Send email</button>

            <br/><br/>
            
            {status==='DONE' && <strong className='important'>You should receive an email shortly.<br/>Click the link within it to continue.</strong>}
            <strong>{message}</strong>
            <br/><br/>
            <NavLink to='/sign-in'>I know my password and I want to sign in</NavLink>

            </form>
          </div>
         
        </div>
        </>
    )
}