
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";

import { formatDate, getCutoff, inPast, formatDateInWords, penceToCurrency, displayUser, wait, sameDay, BakingIcon } from '../../helpers/utils'
import useDynamicRefs from 'use-dynamic-refs';

import { useHistory } from 'react-router-dom';
import { add, format, sub } from 'date-fns'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLockOpen, faCopy, faCheck, faSave, faSyncAlt, faTimes, faPrint, faFileInvoiceDollar, faFileAlt, faClock, faTruck, faTrashAlt, faExclamationTriangle, faSpinner, faBirthdayCake, faPlus, faSquare, faCheckCircle, faCircle, faCheckSquare, faLock } from '@fortawesome/free-solid-svg-icons'

import { supabase } from '../../api'

import { triggerEmail } from '../../pages/Orders/email'
import Modal from "../../components/Modal";
import useModal from "../../helpers/useModal";
import { Calendar } from "react-calendar";
import Photo from "../../components/Photo";

const customFields = ['sponge','filling','topping','fruit','size','shape','message','notes','unit_cost']

export const OrderForm = ({defaultOrder, shop, products, currentUser, allUsers, currentDate, readOnly, invoiceView = false}) => {

  const history = useHistory();

  const [deleting, setDeleting] = useState(false)
  const { showModal, toggleModal } = useModal()
  const [voiding, setVoiding] = useState(false)
  const { showModal: showDeleteModal, toggleModal: toggleDeleteModal } = useModal()

  const [loading, setLoading] = useState(false)
  
  const [saving, setSaving] = useState(false)
  const [order, setOrder] = useState(defaultOrder)

  const [selectingCopyDate, setSelectingCopyDate] = useState(false)
  const [copyingMessage, setCopyingMessage] = useState('')
  const [copyDate, setCopyDate] = useState()
  const [copying, setCopying] = useState(false)

  const [changes, setChanges] = useState({})
  const [temporary, setTemporary] = useState()

  const [cutoffTime, setCutoffTime] = useState()
  const [pastCutOff, setPastCutOff] = useState()
  const [locked, setLocked] = useState(true)

  const [showInvoice, setShowInvoice] = useState(invoiceView)

  const dateStr = format(currentDate,'dd-MMM-yyyy')

  const [getRef, setRef] =  useDynamicRefs();

  useEffect ( () => {
    async function fetchData() {

        setLoading(true)

        // process changes

        const currentValues = {}
        order.orderProducts?.forEach(op => {
            currentValues[op.products.id] = { quantity: op.quantity, unit_cost: op.unit_cost }
        })

        setChanges(currentValues)

        setLoading(false)

    }
    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect ( () => {
    if (showInvoice) setLocked(true)
    else setLocked(order?.voided || readOnly || pastCutOff)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showInvoice])


  useEffect ( () => {
    const co = getCutoff(currentDate)
    const pco = inPast(co)
    setCutoffTime(co)
    setPastCutOff(pco)
    setLocked(order?.voided || readOnly || pco)
  
  }, [currentDate, readOnly])

  const showTemporary = async (message) => {

    setTemporary(message)
    await wait(3000)
    setTemporary(undefined)

  }

  const viewOrder = () => {
    history.push(`/orders/${format(currentDate,'dd-MMM-yyyy')}/${order.id}`)
  }

  const changeQuantity = (event, productID, quantity, unit_cost) => {
    const newChanges = {...changes}
    if (isNaN(quantity) || quantity < 0) {
        quantity=0
    }
    if (quantity > 250) quantity = 250 

    event.target.value = parseInt(quantity)
    newChanges[productID] = { quantity: quantity, unit_cost: unit_cost };

    setChanges(newChanges)
  }

  const adminUnlock = async () => {
      setLocked(false)
  }

  const handleDelete = () => {
    toggleDeleteModal()
  }

  const handleVoid = () => {
    toggleModal()
  }

  const doVoid = async () => {
    setVoiding(true)

    const orderData = {
        ...order,
        voided: true,
        voided_at: new Date().toISOString(),
        voided_by: currentUser.id,
    }

    delete orderData.orderProducts
    delete orderData.orderCustoms

    const { data, error } = await supabase
        .from('orders')
        .update(orderData)
        .match({ id: order.id })
        .single()
        .select()
    if (error) console.error('error updating order', error)
    if (data) {
        document.location = `/orders/${format(currentDate,'dd-MMM-yyyy')}/${order.id}`
    }
    
  }



  const doDelete = async () => {
    
    if (!currentUser.superadmin) return false

    setDeleting(true)
    
    const { data: ocData, error: ocError } = await supabase
        .from('orderCustoms')
        .delete()
        .match({ order_id: order.id })

    if (ocError) {
        console.error(ocError)
        return
    }

    const { data: opData, error: opError } = await supabase
        .from('orderProducts')
        .delete()
        .match({ order_id: order.id })

    if (opError) {
        console.error(opError)
        return
    }
    const { data: oData, error: oError } = await supabase
        .from('orders')
        .delete()
        .match({ id: order.id })

    if (oError) {
        console.error(oError)
        return
    }

    await wait(3000)

    setDeleting(false)

    history.push(`/orders/${format(currentDate,'dd-MMM-yyyy')}`)

  }

  const handleSubmit = async () => {
    
    setSaving(true)
    
    const changeArr = Object.entries(changes)

    let productsOrdered = 0
    let productOrderRecords = []

    for (let index = 0; index < changeArr.length; index++) {
        const change = changeArr[index]
        if (change[0]!=='undefined' && !isNaN(change[1].quantity) && change[1].quantity>=1) {
            productOrderRecords.push( { product_id: parseInt(change[0]), quantity: change[1].quantity, unit_cost: change[1].unit_cost } )
            productsOrdered += 1;
        }
    }

    if (productsOrdered === 0 && !order) {
        alert('Order is empty')
        return
    }

    const dateNow = new Date().toISOString()

    const orderData = {
        updated_at: dateNow,
        updated_by: currentUser.id,
        notes: order?.notes || '',
        purchase_order_num: order?.purchase_order_num || '',
    }

    if (!order?.id) {
        orderData.shop_id = shop.id;
        orderData.delivery_dt = dateStr;
        orderData.created_by = currentUser.id;
        orderData.created_at = dateNow;
    }

    // sync order record
    let modifiedOrder = {}

    if (order?.id) {  // update
        const { data, error } = await supabase
          .from('orders')
          .update(orderData)
          .match({ id: order.id })
          .single()
          .select()

        if (error) console.error('error updating order', error)
        if (data) modifiedOrder = data

        console.log(data, error)
    }
    else { // insert

        
        const { data, error } = await supabase
          .from('orders')
          .insert(orderData)
          .single()
          .select()



        if (error) console.error('error inserting order', error)

        if (data) modifiedOrder = data
    }

    // sync orderProducts records

    if (modifiedOrder?.id) {
        const { data, error } = await supabase
            .from('orderProducts')
            .delete()
            .match({ order_id: modifiedOrder.id })

        if (error) console.error('error deleting orderProducts', error)

        productOrderRecords = productOrderRecords.map(p => ({...p, order_id: modifiedOrder.id}))

        const { data: poData, error: poError } = await supabase
            .from('orderProducts')
            .insert(productOrderRecords)
        if (error) console.error('error inserting orderProducts', poError)

        if (order?.id) {
            triggerEmail('UPDATED', modifiedOrder, productOrderRecords, shop, currentDate, products, allUsers)
            setOrder({...order, updated_at: modifiedOrder.updated_at, updated_by: modifiedOrder.updated_by})
            showTemporary('Your changes have been saved')
        }
        else {
            triggerEmail('CREATED', modifiedOrder, productOrderRecords, shop, currentDate, products, allUsers)
        
            //reload to saved version
            document.location = `/orders/${format(currentDate,'dd-MMM-yyyy')}/${modifiedOrder.id}`
            showTemporary(`Order ${modifiedOrder.id} created`)
        }

    }
    else {
        alert('Sorry - your order has not be saved')
    }
    setSaving(false)
  }

  const handleCustomSubmit = async () => {
    
    // get order custom form fields
    
    const orderCustoms = order?.orderCustoms?.map((oc,i) => {
        const obj = {}
        customFields.forEach(f => {
            const val = getRef(`${f}_${i}`).current.value
            obj[f] = val
        })
        obj['unit_cost'] = Math.floor(parseFloat(obj['unit_cost'])*100)
        return obj
    })

    setSaving(true)
    
    const dateNow = new Date().toISOString()

    const orderData = {
        ...order,
        updated_at: dateNow,
        updated_by: currentUser.id,
    }

    if (!order?.id) {
        orderData.shop_id = shop.id;
        orderData.delivery_dt = dateStr;
        orderData.created_by = currentUser.id;
        orderData.created_at = dateNow;
    }

    delete orderData.orderProducts
    delete orderData.orderCustoms

    // sync order record
    let modifiedOrder = {}

    if (order?.id) {  // update
        const { data, error } = await supabase
          .from('orders')
          .update(orderData)
          .match({ id: order.id })
          .single()
          .select()
        if (error) console.error('error updating order', error)
        if (data) modifiedOrder = data

        console.log(data, error)
    }
    else { // insert

        const { data, error } = await supabase
          .from('orders')
          .insert(orderData)
          .single()
          .select()
        if (error) console.error('error inserting order', error)

    
        if (data) modifiedOrder = data
    }

    console.log('modifiedOrder = ', modifiedOrder)

    if (modifiedOrder?.id) {
        const { data, error } = await supabase
            .from('orderCustoms')
            .delete()
            .match({ order_id: modifiedOrder.id })

        if (error) console.error('error deleting orderCustoms', error)

        const orderCustomRecords = orderCustoms?.map(p => ({...p, order_id: modifiedOrder.id }))
        let orderCustomRecordsSaved = []

        if (orderCustomRecords?.length) {
            const { data: poData, error: poError } = await supabase
                .from('orderCustoms')
                .insert(orderCustomRecords)
                .select()
                .select()
            if (poError) console.error('error inserting orderCustoms', poError)
            if (poData) orderCustomRecordsSaved = poData
        }

        if (order?.id) {
            //triggerEmail('UPDATED', modifiedOrder, productOrderRecords, shop, currentDate, products, allUsers)
            setOrder({...order, orderCustoms: orderCustomRecordsSaved, updated_at: modifiedOrder.updated_at, updated_by: modifiedOrder.updated_by})
            showTemporary('Your changes have been saved')
        }
        else {
            //triggerEmail('CREATED', modifiedOrder, productOrderRecords, shop, currentDate, products, allUsers)
        
            //reload to saved version
            document.location = `/orders/${format(currentDate,'dd-MMM-yyyy')}/${modifiedOrder.id}`
            showTemporary(`Order ${modifiedOrder.id} created`)
        }

    }
    else {
        alert('Sorry - your order has not be saved')
    }
    setSaving(false)
  }

  const filterProducts = (products) => {
    //return products
    let filtered

    filtered = products.filter(p => {
        const availableToShop = p.shopProducts.map(s => s.shop_id).includes(shop.id)
        const op = order?.orderProducts?.find(op => op.products.id === p.id)
        return (p.active && availableToShop) || (op && op.quantity>0)
    })

    return filtered
  }

  
  const Allergen = ({name}) => {
    return <div className='allergen'>{name}</div>
    }

  const duplicate = () => {
    setCopyingMessage('Select the new delivery date')
    setSelectingCopyDate(true)
  }

  const doPrint = () => {
      window.print()
  }

  const toggleFormat = () => {
    setShowInvoice(v => !v)
  }

  const onSelectDate = async (dt) => {
    
    const newDate = format(new Date(dt),'yyyy-MM-dd')

    const newDateCutOff = getCutoff(new Date(dt))
    const newDateInPast = inPast(newDateCutOff)

    if (newDateInPast) {
        setCopyingMessage(`Sorry, the cut off for this date was ${formatDate(newDateCutOff,true)}`)
        setCopyDate()
    }
    else {
        setCopyingMessage('')
        setCopyDate(new Date(dt))
    }

  }

  const doCopy = async () => {

    const newDate = format(copyDate,'yyyy-MM-dd')

    const orderCopyFields = {
        ...order,
        delivery_dt: newDate,
        created_at: new Date(),
        created_by: currentUser.id,
    }

    delete orderCopyFields.id
    delete orderCopyFields.orderProducts
    delete orderCopyFields.orderCustoms

    const { data: newOrder, error: newOrderError } = await supabase
        .from('orders')
        .insert(orderCopyFields)
        .single()
        .select()
    if (newOrderError) console.error('error inserting order', newOrderError)

    if (newOrder) {

        //normal order - order products
        if (newOrder.order_type === 0) {
            const orderProducts = order.orderProducts.map(op => {
                const newOP = { ...op, order_id: newOrder.id, product_id: op.products.id }
                delete newOP.products
                return newOP
            })
            
            const { data, error } = await supabase
                .from('orderProducts')
                .insert(orderProducts)
                .select()
            if (error) console.error('error inserting order products', error)

            if (data) {
                setCopyingMessage(`Order copied to ${formatDate(copyDate)}`)
                setCopyDate()
            }
        }
        //custom order - order customs
        if (newOrder.order_type === 1) {
            const orderCustoms = order.orderCustoms.map(oc => {
                const newOC = { ...oc, order_id: newOrder.id }
                delete newOC.id
                return newOC
            })

            const { data, error } = await supabase
                .from('orderCustoms')
                .insert(orderCustoms)
                .select()
            if (error) console.error('error inserting order customs', error)

            if (data) {
                setCopyingMessage(`Custom order copied to ${formatDate(copyDate)}`)
                setCopyDate()
            }
        }
    }

  
  }

  const onSelectDateCancelled = () => {
    setSelectingCopyDate(false)
    setCopyDate('')
  }

  const handleFocus = (e) => {
      e.target.select()
  }

  const switchType = () => {
    if (order.order_type === 1) {
        setOrder({...order, order_type: 0})
    }
    else {
        setOrder({...order, order_type: 1})
    }
  }

  const statusMessage = () => {

    const delivered = order?.id && inPast(currentDate)
    let message = order?.id ? <span><b>DELIVERED</b></span> : <span><b>MISSED DELIVERY</b></span>
    let status = ''
    let processing = false

    if (order?.id) status = order?.order_type === 0 ? 'ORDER RECEIVED' : 'CUSTOM ORDER RECEIVED'
    if (!order?.id) status = order?.order_type === 0 ? 'NEW ORDER' : 'NEW CUSTOM ORDER'

    if (!pastCutOff && order?.id) message = <span><b>CHANGES ALLOWED</b><br/>until {formatDate(cutoffTime,true)}</span>
    if (!pastCutOff && !order?.id) message = <span>Cut off is {formatDateInWords(cutoffTime)}<br/>You have until {formatDate(cutoffTime,true)}</span>

    let leftIcon = order?.order_type === 0  ? faFileAlt : faBirthdayCake
    let rightIcon = pastCutOff ? faCheck : faClock;

    if (pastCutOff && order?.id && !delivered) processing = true;
    
    if (processing) {
        message = <span><b>BAKING!</b><br/>Call for last minute changes</span>
        rightIcon = faSyncAlt;
    }
    else if (sameDay(currentDate, new Date())) {
        message = <span><b>DELIVERING TODAY...</b></span>
        rightIcon = faTruck;
    }

    if (pastCutOff && !order) message = <span>You missed the cut off<br/>{formatDate(cutoffTime,true)}</span>
    
    let classList = ['status']
    if (delivered) classList.push('delivered')
    if (!order?.id) classList.push('new')
    if (processing) classList.push('processing')
    if (order.order_type === 1) classList.push('custom')

    if (order.voided) {
        classList = ['status','voided']
        leftIcon = faTimes
        rightIcon = faTimes
        status = 'VOID'
        message = <span><b>Voided {formatDate(order?.voided_at, false)}</b><br/>this order will not be fullfilled</span>
    }

    return (
        <div className={classList.join(' ')}>
            <div className='left'>
                <FontAwesomeIcon size="2x" icon={leftIcon} /> {status}
                
            </div>
            <div className='locked-status'><FontAwesomeIcon size="2x" icon={locked ? faLock : faLockOpen} /></div>
            <div className='right'>{message} {processing ? <BakingIcon size='2x' /> : <FontAwesomeIcon size="2x" icon={rightIcon} />}</div>
        </div>
    )
  }


  const showBillingAddress = () => {
    const arr = [`${shop.name}${shop.brands?.name ? ` (${shop.brands.name})` : ''}`, shop.billing_line_1, shop.billing_line_2, shop.billing_town, shop.billing_postcode]
    return arr.map(line => line ? <>{line}<br/></> : '')
  }


  const showDeliveryAddress = () => {
      
    let arr = []
    if (order.order_type === 0 || order.deliver_to_shop) arr = [`${shop.name}${shop.brands?.name ? ` (${shop.brands.name})` : ''}`, shop.delivery_line_1, shop.delivery_line_2, shop.delivery_town, shop.delivery_postcode]
    else arr = [order.delivery_customer, order.delivery_company, `Tel: ${order.delivery_tel || ''}`, order.delivery_line_1, order.delivery_line_2, order.delivery_line_3, order.delivery_city, order.delivery_postcode]
    return arr.map(line => line ? <>{line}<br/></> : '')
  }
  
  const showPurchaseOrderNum = !(locked && !order?.purchase_order_num)

  const quantityCount = changes && Object.entries(changes).reduce((agg, change) => agg + change[1].quantity, 0);
  
  let grandTotal = 0
  if (order.order_type === 0) grandTotal = changes && Object.entries(changes).reduce((agg, change) => agg + change[1].quantity * change[1].unit_cost, 0);
  if (order.order_type === 1) grandTotal = order.orderCustoms.reduce((agg, oc) => agg + oc.unit_cost, 0);

  const addCustom = () => {
    const arr = [...order?.orderCustoms, {}]
    setOrder({...order, orderCustoms: arr})
  }

  const renderOrderCustomInvoiceRow = (item, index) => {
  
    const itemCost = penceToCurrency(item.unit_cost)
    return (
        <>
        
        <tr>
            <td>
            <div  className={`order-row`} >
            <input style={{width:'100%', fontWeight:'bold'}} name="product" disabled defaultValue={`Custom Item ${index+1}`} />
            
            <input className='cost' disabled type="number" name="quantity" defaultValue='' />
        

            <input className={`cost`} disabled={true} readOnly name="unit_cost" defaultValue='' />

            <input className={`cost total-cost`} disabled={true} readOnly name="total_cost" value={itemCost} />

            </div>

            </td>
        </tr>
        <tr>
            <td>
                <div className='custom-item-desc'>
                {customFields.map(f => {
                    if (f==='unit_cost') return
                    return (<div>{f}:<br/><strong>{item[f]}</strong></div>)
                })}
                </div>
            </td>
        </tr>
        </>
    )
  }

  const renderOrderCustom = (item, setRef, index) => {

    const removeItem = () => {
        // eslint-disable-next-line no-restricted-globals
        if (confirm('Remove this item?  Please remove any photo first!')) {
            const items = [...order.orderCustoms]
            items.splice(index, 1)
            setOrder({...order, orderCustoms: items})
        }
    }

    const copyToText = (event,fieldName) => {
        const val = event.target.value

        const ocs = [...order.orderCustoms]
        ocs[index][fieldName] = val

        setOrder({...order, orderCustoms: ocs}) 
    }


    return (
        <div className='custom-item'>
            <h3 className='center'>Custom Item {index+1} </h3>
            {!locked ? <button className='remove-button' disabled={locked} onClick={removeItem}><FontAwesomeIcon icon={faTimes} />&nbsp;&nbsp;Remove</button> : ''}

            <table className='clear'>
                <tr>
                    <td className='right'> 
                        <select disabled={locked} value='Sponge' onChange={(e) => copyToText(e,'sponge')} >
                            <option>Sponge</option>
                            <option></option>
                            <option>Chocolate</option>
                            <option>Vanilla</option>
                            <option>Coffee</option>
                            <option>Eggless</option>
                            <option value=''>Other</option>
                        </select>
                    </td>
                    <td key={item.sponge}><input ref={setRef(`sponge_${index}`)} disabled={locked} type='text' name='sponge' defaultValue={item.sponge} /></td>
                </tr>
                <tr>
                    <td className='right'> 
                        <select disabled={locked} value='Filling' onChange={(e) => copyToText(e,'filling')} >
                            <option>Filling</option>
                            <option></option>
                            <option>Fresh Vanilla Cream</option>
                            <option>Fresh Chocolate Cream</option>
                            <option>Chocolate Mousse</option>
                            <option>Chocolate Butter Cream</option>
                            <option>Vanilla Butter Cream</option>
                            <option>Chocolate Icing</option>
                        </select>
                    </td>
                    <td key={item.filling}><input ref={setRef(`filling_${index}`)} disabled={locked}  type='text' name='filling' defaultValue={item.filling}  /></td>
                </tr>
                <tr>
                    <td className='right'> 
                        <select disabled={locked} value='Fruit / Jam' onChange={(e) => copyToText(e,'fruit')} >
                            <option>Fruit / Jam</option>
                            <option></option>
                            <option>No Fruit or Jam</option>
                            <option>Fresh Strawberry</option>
                            <option>Strawberry / Banana</option>
                        </select>
                    </td>
                    <td key={item.fruit}><input ref={setRef(`fruit_${index}`)} disabled={locked}  type='text' name='fruit' defaultValue={item.fruit}  /></td>
                </tr>
                <tr>
                    <td className='right'>  
                        <select disabled={locked} value='Topping' onChange={(e) => copyToText(e,'topping')} >
                            <option>Topping</option>
                            <option></option>
                            <option>Fresh Cream</option>
                            <option>Sugar Paste</option>
                            <option>Marzipan</option>
                            <option>Vanilla Icing</option>
                            <option>Chocolate Icing</option>
                        </select>
                    </td>
                    <td key={item.topping}><input ref={setRef(`topping_${index}`)} disabled={locked} type='text' name='topping' defaultValue={item.topping} /></td>
                </tr>
                <tr>
                    <td className='right'>  
                        <select disabled={locked} value='Size' onChange={(e) => copyToText(e,'size')} >
                            <option>Size</option>
                            <option></option>
                            <option>6"</option>
                            <option>8"</option>
                            <option>10"</option>
                            <option>12"</option>
                            <option>14"</option>
                            <option>16"</option>
                        </select>
                    </td>
                    <td key={item.size}><input ref={setRef(`size_${index}`)} disabled={locked} type='text' name='size' defaultValue={item.size} /></td>
                </tr>
                <tr>
                    <td className='right' value='Shape' onChange={(e) => copyToText(e,'shape')} >
                        <select disabled={locked} >
                            <option>Shape</option>
                            <option></option>
                            <option>Square</option>
                            <option>Round</option>
                            <option>Oval</option>
                            <option>Rectangle</option>
                        </select>
                    </td>
                    <td key={item.shape}><input ref={setRef(`shape_${index}`)} disabled={locked} type='text' name='shape' defaultValue={item.shape} /></td>
                </tr>
                <tr>
                    <td className='right'><label>Message</label></td>
                    <td key={item.message}><input ref={setRef(`message_${index}`)} disabled={locked} name='message' defaultValue={item.message} /></td>
                </tr>
                <tr>
                    <td className='right top'><input className='right' disabled value='Notes' /></td>
                    <td key={item.notes}><textarea disabled={locked} ref={setRef(`notes_${index}`)} name='notes' defaultValue={item.notes} /></td>
                </tr>

                <tr>
                    <td className='right'><label>Price</label></td>
                    <td key={item.unit_cost}><input  style={{width:80}} ref={setRef(`unit_cost_${index}`)} disabled={locked} name='unit_cost' defaultValue={penceToCurrency(item.unit_cost)} /></td>
                </tr>


                {order?.id ? <tr><td className='right'><label>Photo</label></td>
                    <td ><Photo order disabled={locked} bucket='customs' path={order.id} filename={index+1} /></td>
                </tr> : ''}

            </table>
        </div>
    )
  }

  if (grandTotal === 0 && showInvoice) return null
  
  return (
    <>

        {order?.id ? <Modal
          isShowing={showModal}
          title={<><FontAwesomeIcon icon={faTimes} />&nbsp;Void Order!&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>}
          hide={toggleModal}
        >
        <div className='center'>
          <h3>Really void ORDER {order?.id}?</h3><br/><br/>
          <button className='inv' disabled={voiding} onClick={doVoid}><FontAwesomeIcon spin={voiding} icon={voiding ? faSpinner : faTimes} />&nbsp;&nbsp;{voiding ? 'Please wait...' : 'VOID THIS ORDER'}</button>
          </div>
        </Modal> : ''}
        
        {order?.id ? <Modal
          isShowing={showDeleteModal}
          title={<><FontAwesomeIcon icon={faTrashAlt} />&nbsp;Delete Order!&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>}
          hide={toggleDeleteModal}
        >
        <div className='center'>
          <h3>Really delete ORDER {order?.id}?</h3><br/><br/>
          <button className='inv' disabled={deleting} onClick={doDelete}><FontAwesomeIcon spin={deleting} icon={deleting ? faSpinner : faTrashAlt} />&nbsp;&nbsp;{deleting ? 'Please wait...' : 'DELETE THIS ORDER'}</button>
          </div>
        </Modal> : ''}


        

        {selectingCopyDate ? <Modal
                    isShowing={selectingCopyDate}
                    title={'Copy to...'}
                    hide={onSelectDateCancelled}
                    >

            <Calendar
                onChange={onSelectDate}
                showNeighboringMonth={false}
                next2Label={null}
                date={currentDate}
                prev2Label={null}
                minDate={sub(currentDate, {months: 6})}
                maxDate={add(currentDate, {months: 6})}
            />    
            <h4 className='copying-message'>{copyingMessage} {copyDate && <button className='inv'  onClick={doCopy}>Copy order to {formatDate(copyDate)}</button>}</h4>   

            </Modal> : ''}

            
            {order?.id && !readOnly ? <ul className='button-list'>
                {currentUser.p_orders>=2 && <li><button className='inv' disabled={order?.voided} onClick={duplicate}><FontAwesomeIcon icon={faCopy} />&nbsp;&nbsp;Duplicate</button></li>}
                {(pastCutOff || currentUser.superadmin) && grandTotal !== 0 ? <li><button className='inv' onClick={toggleFormat}><FontAwesomeIcon icon={showInvoice ? faFileAlt  : faFileInvoiceDollar} />&nbsp;&nbsp;{showInvoice ? 'View Order' : 'View Invoice'}</button></li> : ''}
                {locked && currentUser?.superadmin && !showInvoice ? <li><button disabled={order?.voided} className='inv' onClick={adminUnlock}><FontAwesomeIcon icon={faLockOpen} />&nbsp;&nbsp;UNLOCK</button></li> : ''}
                {currentUser?.superadmin && (!locked || showInvoice) ? <li><button className='inv' disabled><FontAwesomeIcon icon={faLockOpen} />&nbsp;&nbsp;UNLOCK</button></li> : ''}
                
                {<li><button className='inv'  onClick={doPrint}><FontAwesomeIcon icon={faPrint} />&nbsp;&nbsp;Print</button></li>}
                {!pastCutOff || currentUser.superadmin ? <li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<button disabled={order?.voided} className='serious' onClick={handleVoid}><FontAwesomeIcon icon={faTimes} />&nbsp;&nbsp;VOID</button></li> : ''}         
                {currentUser.superadmin ? <li><button className='serious' onClick={handleDelete}><FontAwesomeIcon icon={faTrashAlt} />&nbsp;&nbsp;DELETE</button></li> : ''}         
                
                </ul> : ''}

                {!order?.id && currentUser.superadmin && locked && order.order_type === 0 ? <ul className='button-list'>
                <li><button className='inv' onClick={adminUnlock}><FontAwesomeIcon icon={faLockOpen} />&nbsp;&nbsp;UNLOCK TO EDIT</button></li>
                </ul> : ''}
 
            <div key={`${shop.id}-${dateStr}-order`} className={`order ${showInvoice ? 'invoice' : ''} ${locked ? 'order-locked' : ''}`}>
                {order.voided ? <div className='voided-banner'>VOID</div> : ''}
            
                <table className='print-page'><thead><tr><th>
                {!loading && !showInvoice && statusMessage()}
                

                {showInvoice && order?.id && 
                
                <>
                    <table className='clear'>
                        
                    <tr>
                        <td className='top half'><h1>Sales Invoice</h1><br/>
                            
                            <table className='invoice-details'>
                            <tr>
                                <td>Invoice number</td>
                                <td><strong onClick={viewOrder} >{order.id}</strong></td>
                            </tr>
                            <tr>
                                <td>Invoice date</td>
                                <td><strong>{format(currentDate, 'dd MMMM yyyy')}</strong></td>
                            </tr>

                            <tr>
                                <td>PO num</td>
                                <td><strong>{order?.purchase_order_num || ''}</strong></td>
                            </tr>
                            </table>
                        </td>
                        <td className='top right half'>
                        <strong>Damask Catering Ltd (T/A EL&N B2B)</strong><br/>
                            19 Gorst Road, London, NW10 6LA, UK<br/>
                            Tel: 0208 205 4923<br/>
                            Email: admin@elnlondon.com<br/>
                        </td>
                    </tr>

                    
                    </table>

                    <table className='clear'>
                        <tr>
                            <td className='half'>Invoice to</td>
                            <td>&nbsp;&nbsp;&nbsp;&nbsp;</td>
                            <td className='half'>Deliver to</td>
                        </tr>

                        <tr>
                            <td className='top'><strong>{showBillingAddress()}</strong></td>
                            <td></td>
                            <td className='top'><strong>{showDeliveryAddress()}</strong></td>
                        </tr>
                    </table>    
                    
                </>}
                
                {!showInvoice && 
                <>
                    <h1><span>{shop.brands?.name}</span>{shop.name}</h1>
                    <h2 className='forDelivery'>For Delivery On</h2>
                    <h1 style={{fontSize:28, fontWeight:'bold', textAlign:'center', marginTop: 0}}>{format(currentDate, 'PPPP')}</h1>
                
                    {!loading && showPurchaseOrderNum && <div className='order-header'>
                    PO num: &nbsp;&nbsp; 
                    {locked ? <b>{order?.purchase_order_num || ''}</b> : <input key={`${shop.id}-${dateStr}-ponum`} type='text' onChange={e => setOrder({...order, purchase_order_num: e.target.value})} disabled={locked} defaultValue={order?.purchase_order_num} />}
                
                    </div>}

                    <h2 className='orderNumber'>{order?.id ? <>Order number: &nbsp;<strong>{order.id}</strong></> : ''}</h2>
                    
                    {!order?.id ? <div className='center'>
                        <button class='link' onClick={switchType}><FontAwesomeIcon icon={order?.order_type === 1 ? faCheckSquare : faSquare} />&nbsp;&nbsp;Custom Order</button>
                        
                    </div> : ''}

                </>}

                </th></tr>
                
                </thead>
                <tbody>
                {!loading && order?.order_type === 0 && products.map((category) => {

                    const visibleProducts = filterProducts(category[1]);
    
                    if (visibleProducts.length === 0) return null

                    const productsToShow = visibleProducts.filter(product => {
                        const orderProduct = order?.orderProducts?.find(op => op.products.id === product.id)
                        let quantity = orderProduct ? orderProduct.quantity : 0;
                        if (changes[product.id]) quantity = changes[product.id].quantity;
                        return quantity > 0
                    })

                    if (locked && productsToShow.length === 0) return null

                    return (
                        <>
                        <tr key={category[0]}><td className='category'><h3 className={`${visibleProducts.length === 0 ? 'zero' : ''}`}>{category[0].split('_').join('')}</h3><table className='clear'>
                        
                        {visibleProducts.map(product => {
                            
                            const shopProduct = product.shopProducts.find(s => s.shop_id === shop.id)

                            const isAvailable = order?.order_type === 1 ? true : !!shopProduct
                            const orderProduct = order?.orderProducts?.find(op => op.products.id === product.id)
                            
                            let quantity = orderProduct ? orderProduct.quantity : 0;

                            if (changes[product.id]) quantity = changes[product.id].quantity;

                            const isActive = product.active;

                            const isDisabled =  showInvoice || !isAvailable || !isActive || locked

                            const opCost = orderProduct?.unit_cost
                            const spCost = shopProduct?.unit_cost
                            const pCost = shop.brands.can_override_cost ? 0 : product.unit_cost

                            const unitCost = opCost || spCost || pCost || 0

                            let costClass = shop.brands.can_override_cost ? '' : 'p-cost'
                            if (spCost) costClass = 's-cost'
                            if (opCost) costClass = 'o-cost'

                            if (!currentUser.superadmin) costClass=''

                            const totalCost = unitCost * quantity

                            return (
                                
                                <tr>
                                    <td>
                                    <div key={`${shop.id}-${dateStr}-${product.id}`} className={`order-row ${!quantity  ? 'zero' : ''}`} >
                                    <input style={{width:'100%'}} name="product" disabled defaultValue={`${product.name} ${product.details ? `(${product.details})` : ''}`} />
                                    
                                    <input className='cost' disabled={isDisabled} onChange={e => changeQuantity(e, product.id, parseInt(e.target.value), unitCost)} type="number" onClick={handleFocus} min="0" name="quantity" defaultValue={quantity} />
                                

                                    <input className={`cost ${costClass}`} disabled={true} readOnly name="unit_cost" value={penceToCurrency(unitCost)} />

                                    <input className={`cost total-cost`} disabled={true} readOnly name="total_cost" value={penceToCurrency(totalCost)} />

                                    </div>

                                    </td>
                                    <td>{product.allergens?.map(a => <Allergen name={a} />)}</td>
                                </tr>
                                
                            )
                        })}

                        </table></td></tr>
                        </>
                    )
                }
                )}

                {!loading && 
                <>
                
                    {order?.order_type === 1 && showInvoice ? 
                        <>{order?.orderCustoms?.map((c,i) => renderOrderCustomInvoiceRow(order.orderCustoms[i],i) )}</>
                    : ''}

                    {order?.order_type === 1 && !showInvoice ?
                        <tr><td>
                        <div className='custom-fields'>
                            {order?.orderCustoms?.map((c,i) => renderOrderCustom(order.orderCustoms[i],setRef,i) )}
                            
                            {!locked && !showInvoice ? <div className='custom-item center no-print'>
                                <button class='inv' disabled={locked} onClick={addCustom}><FontAwesomeIcon icon={faPlus} />&nbsp;&nbsp;Add custom item</button>
                            </div> : ''}

                            <h3 className='center'>DELIVER TO</h3>
                            <table className='clear'>
                                <tr>
                                    <td colspan="2" className='center'>
                                        <label><input disabled={locked} type='radio' name='deliver_to_shop' value={true} defaultChecked={order?.deliver_to_shop !== false} onChange={e => setOrder({...order, deliver_to_shop: true})} /> <strong>{shop.brands?.name} {shop.name}</strong></label> &nbsp;&nbsp;&nbsp;&nbsp;
                                        <label><input disabled={locked} type='radio' name='deliver_to_shop' value={false} defaultChecked={order?.deliver_to_shop === false} onChange={e => setOrder({...order, deliver_to_shop: false})} /> <strong>Customer</strong></label>
                                    </td>
                                </tr>
                                

                                {order?.deliver_to_shop !== false ? '' : <>
                                <tr>
                                    <td className='right'>Customer name</td>
                                    <td ><input disabled={locked} name='delivery_customer' defaultValue={order?.delivery_customer} onChange={e => setOrder({...order, delivery_customer: e.target.value })} /></td>
                                </tr>
                                <tr>
                                    <td className='right'>Customer company</td>
                                    <td ><input disabled={locked} name='delivery_company' defaultValue={order?.delivery_company} onChange={e => setOrder({...order, delivery_company: e.target.value })} /></td>
                                </tr>
                                <tr>
                                    <td className='right'>Customer telephone</td>
                                    <td ><input disabled={locked} name='delivery_tel' style={{width: '150px'}}  defaultValue={order?.delivery_tel} onChange={e => setOrder({...order, delivery_tel: e.target.value })} /></td>
                                </tr>
                                <tr>
                                    <td className='right'>Address line 1</td>
                                    <td ><input disabled={locked} name='delivery_line_1'  defaultValue={order?.delivery_line_1} onChange={e => setOrder({...order, delivery_line_1: e.target.value })} /></td>
                                </tr>
                                <tr>
                                    <td className='right'>Address line 2</td>
                                    <td ><input disabled={locked} name='delivery_line_2'  defaultValue={order?.delivery_line_2} onChange={e => setOrder({...order, delivery_line_2: e.target.value })} /></td>
                                </tr>
                                <tr>
                                    <td className='right'>Address line 3</td>
                                    <td ><input disabled={locked} name='delivery_line_3' defaultValue={order?.delivery_line_3} onChange={e => setOrder({...order, delivery_line_3: e.target.value })} /></td>
                                </tr>
                                <tr>
                                    <td className='right'>Town / city</td>
                                    <td ><input disabled={locked} name='delivery_city'  defaultValue={order?.delivery_city} onChange={e => setOrder({...order, delivery_city: e.target.value })} /></td>
                                </tr>
                                <tr>
                                    <td className='right'>Postcode</td>
                                    <td ><input disabled={locked} name='delivery_postcode' style={{width: '150px'}} defaultValue={order?.delivery_postcode} onChange={e => setOrder({...order, delivery_postcode: e.target.value })} /></td>
                                </tr>
                                </>}
                            </table>

                        </div>
                        </td></tr>
                    
                : null}

                    {order?.order_type === 0 && !showInvoice && <tr><td><div className='order-row total-row'>
                        <input style={{width:'100%'}} disabled readOnly value='TOTAL' />
                        <input className='cost' disabled type="number" readonly value={quantityCount}/>
                        <input className='cost' disabled readOnly  />
                        <input className='cost total-cost'  disabled readOnly value={penceToCurrency(grandTotal, false)} />
                    </div></td></tr>}

                    {showInvoice && <tr><td><br/><br/>
                    <div className='invoice-total-row'>
                        <input style={{width:'100%'}} disabled readOnly value='NET AMOUNT £' />
                        <input className='cost total-cost'  disabled readOnly value={penceToCurrency(grandTotal, false)} />
                    </div>
                    <div className='invoice-total-row'>
                        <input style={{width:'100%'}} disabled readOnly value='VAT AMOUNT £' />
                        <input className='cost total-cost'  disabled readOnly value='0.00' />
                    </div>
                    <div className='invoice-total-row total'>
                        <input style={{width:'100%'}} disabled readOnly value='TOTAL £' />
                        <input className='cost total-cost'  disabled readOnly value={penceToCurrency(grandTotal, false)} />
                    </div>
                    </td></tr>}

                    {!showInvoice && 
                    <tr><td>
                        <br/>
                        <h3 className='center'>Special instructions</h3>
                        <div className='center' style={{margin: '0px 20px'}}>
                            <textarea className='special' key={`${shop.id}-${dateStr}-special`} onChange={e => setOrder({...order, notes:e.target.value})} disabled={locked} defaultValue={order?.notes || ''} />
                        </div>
                        <div style={{marginTop:30, width:'100%', display:'flex', flexDirection:'column', alignItems:'center', textAlign:'center'}}>
                            {order?.created_at && <h4>created: {formatDate(order?.created_at, true)} by {displayUser(allUsers, order?.created_by)}<br/></h4>}
                            {order?.updated_at && <h4>last updated: {formatDate(order?.updated_at, true)} {order.updated_by && <>by {displayUser(allUsers, order.updated_by)}</>}</h4>}
                            {order?.voided && <h4>voided: {formatDate(order?.voided_at, true)} {order.voided_by && <>by {displayUser(allUsers, order.voided_by)}</>}</h4>}
                            
                            {!locked && currentUser.p_orders>=2 && <button disabled={saving} className='large inv' onClick={order?.order_type === 0 ? handleSubmit : handleCustomSubmit}>
                            <div><FontAwesomeIcon spin={saving} icon={saving ? faSyncAlt : faSave} />&nbsp;{order?.id ? `Change this order` : `Create new order`}</div>
                                <h2>for delivery on {formatDate(currentDate)}</h2>
                            </button>
                            }
                            <div className='temporary'>{temporary}</div>
                            <br/>
                            {statusMessage()}
                        </div>
                    </td></tr>
                    }
                </>
                }
            
                </tbody>
                {showInvoice && order?.id && <tfoot><div class='empty-footer'></div></tfoot>}
                </table>

                {showInvoice && order?.id && 
                <div class='invoice-footer'>
                    Registered in England and Wales No. 07974944 , VAT Registration Number GB 294596054<br/>
                    Registered Address 19 Gorst Road, London, NW10 6LA, United Kingdom<br/><br/>
                    Damask Catering Ltd Business Current Account<br/>
                    Sort Code: 50-41-10 &nbsp;&nbsp;&nbsp; Account Number: 52737543<br/>
                </div>
                }

            </div>
        

    </>
  )
}

export default withRouter(OrderForm);
