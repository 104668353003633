import { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileImport } from '@fortawesome/free-solid-svg-icons'
import { Loader } from '../../helpers/loader';
import { supabase } from '../../api'
import { property } from 'lodash';

var Strings = {
    /**
     * Wrapped CSV line parser
     * @param s      String delimited CSV string
     * @param sep    Separator override
     * @attribution: http://www.greywyvern.com/?post=258 (comments closed on blog :( )
     */
    parseCSV : function(s,sep) {
        // http://stackoverflow.com/questions/1155678/javascript-string-newline-character
        var universalNewline = /\r\n|\r|\n/g;
        var a = s.split(universalNewline);
        for(var i in a){
            if (typeof a[i] !== 'function') {
            for (var f = a[i].split(sep = sep || ","), x = f.length - 1, tl; x >= 0; x--) {
                if (f[x].replace(/"\s+$/, '"').charAt(f[x].length - 1) === '"') {
                    if ((tl = f[x].replace(/^\s+"/, '"')).length > 1 && tl.charAt(0) === '"') {
                        f[x] = f[x].replace(/^\s*"|"\s*$/g, '').replace(/""/g, '"');
                      } else if (x) {
                    f.splice(x - 1, 2, [f[x - 1], f[x]].join(sep));
                  } else f = f.shift().split(sep).concat(f);
                } else f[x].replace(/""/g, '"');
              } a[i] = f;
            }
    }
    return a;
    }
}

export const RecipeImporter = () => {

    const [data, setData] = useState([])

    const [recipes, setRecipes] = useState()
    const [ingredients, setIngredients] = useState()
    const [steps, setSteps] = useState()

    const [recipeData, setRecipeData] = useState()

    const [products, setProducts] = useState()

    const [loading, setLoading] = useState(false)
    const [fileName, setFileName] = useState('')
    
    const [before, setBefore] = useState('')
    const [after, setAfter] = useState('')
    let activeRecipes = []
    
    const DO_SAVE_PRODUCTS = true
    const DO_SAVE_RECIPES = true
    const DO_SAVE_INGREDIENTS = true
    const DO_SAVE_STEPS = true

    useEffect ( () => {
        async function fetchData() {
            
            const { data: recipes, error: recipesErr } = await supabase
            .from('recipes')
            .select()
            setRecipes(recipes)

            const { data: ingredients, error: ingredientsErr } = await supabase
            .from('ingredients')
            .select()

            const { data: steps, error: stepsErr } = await supabase
            .from('steps')
            .select()
            
            setBefore(`${recipes?.length || 0} recipes, ${ingredients?.length || 0} ingredients, ${steps?.length || 0} steps`)

            const { data: products, error: productsErr } = await supabase
            .from('products')
            .select('*, categories (*), suppliers (*)')

            setProducts(products)
            if (productsErr) console.error(productsErr)
        }
        fetchData();
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [])

      

    const handleFile = async (e) => {
        const content = e.target.result;
        const csv = Strings.parseCSV(content)
        setData(csv)

        const processedRecipeData = await processRecipes(csv)
        
        await saveRecipes(processedRecipeData)
        setRecipeData(processedRecipeData)

        calculateAfter()

        setLoading(false)
    }

    const createProduct = async (recipe) => {

        const product = {
            pid: parseInt(recipe.pid),
            name: recipe.title,
            details: recipe.title === recipe.desc ? '' : recipe.desc, 
            category_id: 452, // empty
            supplier_id: 206, // elan
            unit_cost: 0,
            active: true,
        }

        if (DO_SAVE_PRODUCTS) {
            const { data: insertedProduct, error: productErr } = await supabase
                .from('products')
                .insert(product)
                .select()

            if (productErr) console.error('ERROR INSERTING PRODUCT', productErr)
            return insertedProduct
        }
        else {
            console.warn('WOULD INSERT PRODUCT', product)
            return product
        }
        
    }
      
    const handleChangeFile = (file) => {
        setFileName(file.name)
        setLoading(true)
        let fileData = new FileReader();
        fileData.onloadend = handleFile;
        fileData.readAsText(file);
    }

    if (loading) return (
        <div><Loader /></div>
    )

    const toBool = (val) => {
        if (val === 'Y' || val === '"Y"') return true
        return false
    }

    const clean = (val) => {
        if (val[0]==='"' && val[val.length-1]==='"') val = val.slice(1, -1)
        else if (val[0]==='"') val = val.slice(1)
        return val
    }

    const saveRecipes = async (recipeData) => {

        console.log('******************************************')
        console.log('******************************************')
        console.log('************  SAVING RECIPES  ************')
        console.log('******************************************')
        console.log('******************************************')
        console.log('****  RECIPE RECORDS WILL BE UPDATED  ****')
        console.log('*  INGREDIENT RECORDS WILL BE REPLACED  **')
        console.log('*****  METHOD STEPS WILL BE REPLACED  ****')
        console.log('******************************************')
        console.log('******************************************')
        
        let createdIngredients = []
        let matchedProducts = []
        let dup = []
        let updated = 0
        let inserted = 0
        let inactive = 0
        const keys = Object.keys(recipeData)

        for (let index = 0; index < keys.length; index++) {

            const recipe = recipeData[keys[index]]
        

            if (recipe.isActive) {

                // does this recipe match a product id?

                const productMatchedOnID = products.find(p => p.pid === parseInt(recipe.pid))
                const productMatchedOnName = products.find(p => p.name === recipe.title)

                let product = productMatchedOnID || productMatchedOnName

                if (!product) product = await createProduct(recipe)

                const ingredientIDs = recipe.ingredients && recipe.ingredients.length && recipe.ingredients.map(i => parseInt(i[3]))
                
                
                const childProduct = ingredientIDs && products.find(p => ingredientIDs.includes(parseInt(p.pid)) )               

            
                //if (product) console.log('RECIPE FOUND -', recipe.pid, recipe.title)
                //if (childProduct) console.log('RECIPE CHILD FOUND -', recipe.pid, recipe.title)
                
                if (product || childProduct) {
                    
                    //console.log('product?', !!product, 'child?', !!childProduct)
                    //no longer importing allergens as they are wrong apparently
                    //const allergens = Object.entries(recipe.allergens).filter(a=>!!a[1]).map(a => a[0])
                    const suitables = Object.entries(recipe.suitables).filter(a=>!!a[1]).map(a => a[0])
                    const recipeRecord = {
                        type: recipe.type,
                        pid: parseInt(recipe.pid),
                        name: recipe.title,
                        desc: recipe.title === recipe.desc ? '' : recipe.desc, 
                        terms: recipe.terms,
                        quantity: recipe.numToServe ? parseFloat(recipe.numToServe) : undefined,
                        quantity_unit: recipe.serveMeasurement,
                        suitables: suitables.length ? suitables.join(',') : undefined,
                        product_id: product?.id || childProduct?.id || null,
                        imported_at: (new Date()).toISOString()
                    }

                    const oldRecipe = recipes.find(r => r.pid === parseInt(recipe.pid))
                    if (oldRecipe) {
                        // keep allergens and display name
                        recipeRecord.allergens = oldRecipe.allergens
                        recipeRecord.display_name = oldRecipe.display_name
                    }

                    //console.log('RECIPE RECORD', recipeRecord)

                    if (DO_SAVE_RECIPES) {
                        console.warn('UPSERTING RECIPE:', recipe.title)
                        const { data, error } = await supabase
                        .from('recipes')
                        .upsert(recipeRecord)
                        .single()
                        .select()
                        if (error) {
                            console.error('error inserting new recipe', error)
                        }
                        if (data) {
                            //console.log('new recipe created', data)
                            activeRecipes.push(data)
                            inserted+=1
                        }
                    }
                    else {
                        console.warn('WOULD SAVE RECIPE: ', recipeRecord)
                        activeRecipes.push(recipeRecord)
                    }
                    
                   
                }
            }
            else inactive += 1
        }

        let stepsToInsert = []

        // process methods
        console.log('recipeData.length', keys.length)
        console.log('activeRecipes.length', activeRecipes.length)
        
        window.activeRecipes = activeRecipes

        for (let index = 0; index < keys.length; index++) {

            const recipe = recipeData[keys[index]]

            const activeRecipe = activeRecipes.find(r => parseInt(r.pid) === parseInt(recipe.pid))

            
            //insert steps
            if (recipe.isActive) {

                if (activeRecipe) {

                    if (recipe.method && recipe.method.length) {
                        const steps = recipe.method.map((m,i) => (
                            {
                                recipe_pid: activeRecipe.pid,
                                txt: m,
                                pos: i+1,
                            }
                        ))

                        stepsToInsert.push(steps)

                        /*if (DO_SAVE) {
                            const { data: deleteSteps, error: deleteStepsErr } = await supabase
                            .from('steps')
                            .delete()
                            .match({ recipe_pid: activeRecipe.pid })

                            //console.log(deleteSteps)
                            if (deleteStepsErr) console.error(deleteStepsErr)

                            console.log('steps', steps)
                            const { data: insertedSteps, error: insertedStepsErr } = await supabase
                                .from('steps')
                                .insert(steps)

                            //console.log(insertedSteps)
                            if (insertedStepsErr) console.error(insertedStepsErr)
                            if (insertedSteps) console.log(steps.length, 'steps created for ', recipe.pid)
                        }
                        else {
                            console.log('WOULD CREATE STEPS', steps)
                        }*/
                    }
                
                }

                if (recipe.ingredients && recipe.ingredients.length) {
                    for (let i = 0; i < recipe.ingredients.length; i++) {
                        const ing = recipe.ingredients[i]
    
                        const pid = parseInt(ing[3])
    
                        const activeRecipe = activeRecipes.find(r => parseInt(r.pid) === pid)
                        
                        const product = products.find(p => p.pid === pid)
    
                        if (product) matchedProducts.push(product)

                        if (!activeRecipe) {
    
                            const recipeRecord = {
                                type: 'PRODUCT',
                                pid: pid,
                                name: ing[4],
                                size: ing[5], 
                                product_id: product?.id || null,
                            }
    
                            const foundAlready = createdIngredients.find(c=>c.pid===pid)

                            if (foundAlready) {
                                dup.push(recipeRecord)
                                //console.log('ingredient found already: ', ing[4])
                            }
                            else {
                                createdIngredients.push(recipeRecord)
                                //console.log('ingredient needs creating: ', ing[4])
                            }
    
                            
                        }
                        else {
                            // found already
                        }
                        
    
                    }
                }

            }

            // create new recipe records for ingredients

            
        }

        stepsToInsert = stepsToInsert.flat()

        console.log('stepsToInsert',stepsToInsert)

        if (DO_SAVE_STEPS) {

            //delete all steps
            console.warn('DELETING OLD STEPS')
            const { data: deleteSteps, error: deleteStepsErr } = await supabase
            .from('steps')
            .delete()
            .neq('pos', 0)
            
            if (deleteStepsErr) console.error(deleteStepsErr)

            console.warn('CREATING NEW STEPS: ', stepsToInsert.length)
            const { data: insertedSteps, error: insertedStepsErr } = await supabase
                .from('steps')
                .insert(stepsToInsert)

            if (insertedStepsErr) console.error(insertedStepsErr)

        }
        else {
            console.warn('WOULD CREATE STEPS', stepsToInsert.length)
        }

         // save new recipe records
         console.log('> > > > > > > > > > ')
         console.log('> > > > > > > > > > ')
         console.log('> > > > > > > > > > ')
         console.log('createdIngredients to create', createdIngredients)
         console.log('> > > > > > > > > > ')
         console.log('> > > > > > > > > > ')


        if (DO_SAVE_RECIPES) {
            console.warn('UPSERTING NEW RECIPES: ', createdIngredients.length)
            const { data: newRecipes, error: newRecipesError } = await supabase
            .from('recipes')
            .upsert(createdIngredients)
            .select()
            if (newRecipesError) {
                console.error('error inserting new recipes', newRecipesError)
            }
            if (newRecipes) {
                activeRecipes = [...activeRecipes, ...newRecipes]
            }

        }
        else {
            activeRecipes = [...activeRecipes, ...createdIngredients]
        }
        // process ingredient records


        const ingredientRecords = []
        for (let index = 0; index < keys.length; index++) {

            const recipe = recipeData[keys[index]]

            const parentRecipe = activeRecipes.find(r => parseInt(r.pid) === parseInt(recipe.pid)) || recipes.find(r => parseInt(r.pid) === parseInt(recipe.pid))

            // only process active Recipes
            if (parentRecipe) {
                if (recipe.isActive && recipe.ingredients && recipe.ingredients.length) {
                    for (let i = 0; i < recipe.ingredients.length; i++) {
                        const ing = recipe.ingredients[i]

                        const pid = parseInt(ing[3])

                        const childRecipe = activeRecipes.find(r => parseInt(r.pid) === pid) || recipes.find(r => parseInt(r.pid) === pid)

                        if (childRecipe) {
                            const iRecord = {
                                parent_pid: parentRecipe.pid,
                                child_pid: childRecipe.pid,
                                quantity: parseFloat(ing[6]),
                                quantity_unit: ing[7],
                                position: parseInt(ing[9]) || 0
                            }

                            ingredientRecords.push(iRecord)
                            
                        }
                        else {
                            console.error('??? >>> recipe record not found for >>>', ing)
                        }
                    }

                }
            }
        }

        // CLEAN INGREDIENT DUPLICATES........
        console.log('ingredients to INSERT:', ingredientRecords)

        const irs = []
        const cleanedIngredients = []
        ingredientRecords.forEach(i => {
            const ref = `${i.parent_pid}:${i.child_pid}:${i.position}`
            if (irs.includes(ref)) {
                console.log('DUPLICATE: ', i)
            }
            else {
                irs.push(ref)
                cleanedIngredients.push(i)
            }
        })
    
        console.log('irs.length', irs.length)
        console.log('cleanedIngredients', cleanedIngredients.length, cleanedIngredients)


        if (DO_SAVE_INGREDIENTS) {

            // delete ALL ingredients
            console.warn('DELETING ALL INGREDIENT RELATIONS')
            const { data: deleteIng, error: deleteIngErr } = await supabase
                .from('ingredients')
                .delete()
                .neq('parent_pid', 0)

            // insert new
            console.warn('INSERTING NEW INGREDIENT RELATIONS')
            const { data: newIngredients, error: newIngredientsError } = await supabase
                .from('ingredients')
                .insert(cleanedIngredients)
                .select()
                if (newIngredientsError) {
                    console.error('error inserting new ingredients', newIngredientsError)
                }
                if (newIngredients) {
                    //console.log(newIngredients.length, ' new ingredients created', newIngredients)
                }

        }


        console.log('.')
        console.log('.')
        console.log('.')
        console.log('.')
        console.log('.')
        console.log('.')
        console.log('.')
        console.log('.')
        console.log('.')
        console.log('.')
        console.log('.')
        console.log('DONE')
        

    }

    const processRecipes = async (data) => {

        const dataSet = {}
        const cleanData = []

        //clean data - for DESCRIPTIONS with carriage returns in
        data.forEach(row => {
            if (row.length < 4) {
                if (row[0].length) {
                    const txt = row.join('')
                    const lastRow = cleanData[cleanData.length-1]
                    lastRow[3]+=' ' + txt
                }
                return
            }
            cleanData.push(row)
        })  

        cleanData.map((row,i) => {

            const pid = row[0]
            const group = row[1]
            const field = row[2]
            const val = clean(row[3])

            
            if (!dataSet[pid]) dataSet[pid] = { 
                pid: parseInt(pid),
                allergens: {},
                suitables: {},
                method: [],
                ingredients: [],
            }

            if (group === 'RECIPE') {
                dataSet[pid].type = 'RECIPE'
                if (field === 'TITLE [E]') dataSet[pid].title = val
                if (field === 'DESCRIPTION [E]') dataSet[pid].desc = val

                if (field === 'SEARCH TERMS [E]') dataSet[pid].terms = val
                if (field === 'AUTHOR [E]') dataSet[pid].author = val
                if (field === 'NUMBER TO SERVE [E]') dataSet[pid].numToServe = val
                if (field === 'SERVE MEASUREMENT [E]') dataSet[pid].serveMeasurement = val

                if (field === 'IS PUBLIC [E]') dataSet[pid].isPublic = toBool(val)
                if (field === 'IS ACTIVE [E]') dataSet[pid].isActive = toBool(val)
                if (field === 'SHARE WITH ALL USERS [E]') dataSet[pid].shareAll = toBool(val)
            }
            else if (group === 'DISH') {
                dataSet[pid].type = 'DISH'
                if (field === 'TITLE [E]') dataSet[pid].title = val
                
                if (field === 'SEARCH TERMS [E]') dataSet[pid].terms = val
                if (field === 'AUTHOR [E]') dataSet[pid].author = val
                if (field === 'WASTAGE [E]') dataSet[pid].wastage = val
                if (field === 'STAFF FEEDING [E]') dataSet[pid].staffFeeding = val

                if (field === 'SEASONAL VARIATION [E]') dataSet[pid].seasonalVariation = val
                if (field === 'IS ACTIVE [E]') dataSet[pid].isActive = toBool(val)
                if (field === 'SHARE WITH ALL USERS [E]') dataSet[pid].shareAll = toBool(val)
            }
            else if (group === 'ALLERG') {
                if (field.includes('SUITABLE')) {
                    if (toBool(val)) dataSet[pid].suitables[field.split('SUITABLE').join('')] = toBool(val)
                }
                else if (toBool(val)) dataSet[pid].allergens[field] = toBool(val)
            }
            else if (group === 'METHOD') {
                dataSet[pid].method.push(val)
            }
            else if (group === 'ITEMHD') {
                // ?
            }
            else if (group === 'INGRED') {
                dataSet[pid].ingredients.push(row)
            }

        })

        window.dataSet = dataSet

        return dataSet

    }


    const calculateAfter = async () => {
        const { data: recipes, error: recipesErr } = await supabase
            .from('recipes')
            .select()
            setRecipes(recipes)

        const { data: ingredients, error: ingredientsErr } = await supabase
        .from('ingredients')
        .select()

        const { data: steps, error: stepsErr } = await supabase
        .from('steps')
        .select()
        
        setAfter(`${recipes?.length || 0} recipes, ${ingredients?.length || 0} ingredients, ${steps?.length || 0} steps`)

        
    }


    if (recipeData) {

        return (
            <>
                <h1>Filename</h1>
                <div className='center'>{fileName}</div>
                
                <br/><br/><br/>
                <h1>Before</h1>
                <div className='center'>{before}</div>
                <h1>After</h1>
                <div className='center'>{after}</div>

            </>
        )
    }

    return (
        <div className='outer'>
            <div className='file-upload-wrapper'>
            <label tabIndex={0} className='file-upload-button' htmlFor='recipe-upload'><FontAwesomeIcon icon={faFileImport} />&nbsp;&nbsp;Import PW Recipes</label>
            <input id='recipe-upload' type="file" accept=".csv" onChange={e => handleChangeFile(e.target.files[0])} /> 
            </div>
        </div>
    )
    
}

export default RecipeImporter;
