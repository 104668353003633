
import { supabase } from '../../api'

import { useHistory } from "react-router-dom"
import { useState } from "react";


import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
import ELNStore from "../../store";
import { Logo } from "../../components/Logo";


export default function SignIn() {

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [status, setStatus] = useState('NEW')

  const currentUser = ELNStore.useState(s => s.currentUser);

  const history = useHistory();

  const signin = async () => {

    setStatus('SAVING')
    const { data, error } = await supabase.auth.signInWithPassword({
      email,
      password,
    })

    if (error) {
      setStatus('FAILED')
    }
    else document.location.reload()
  }

  if (currentUser) {
    return (<div className='outer'><h1>You are already logged in</h1></div>)
  }

    return (
      <>
       
            
        <div className='outer signed-out'>

        <Logo />
          <div className='login-form'>
          <form>
         
  <h2>Sign in:</h2>
  <label><span>Email address</span><input type='text' name='email' autoComplete="email" onChange={e => setEmail(e.target.value)} /></label>
        <label><span>Password</span><input type='password' autoComplete="current-password" name='password' onChange={e => setPassword(e.target.value)}/></label>
        <br/><button disabled={status === 'SAVING'} className="inv large" onClick={signin}>Sign In</button>
        <br/><br/>
        {status === 'SAVING' && <strong>Signing in...</strong>}
        {status === 'FAILED' && <strong>Sorry, either your email or password is incorrect</strong>}
        <br/><br/>
          <NavLink to={`/reset-password`}>I want to reset my password</NavLink>
          <br/><br/>
          <NavLink to={`/sign-up`}>I want to sign up as a new user</NavLink>

          </form>
          </div>
         
        </div>
        </>
    )
}