
import React, { useState, useEffect } from "react";

import { Shop } from '../../components/Shop';
import { ShopForm } from '../../components/ShopForm';
import Modal from '../../components/Modal';
import useModal from '../../helpers/useModal';
import { Loader } from '../../helpers/loader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { supabase } from '../../api'
import ELNStore from "../../store";
import { add, sub } from "date-fns";

export const Shops = () => {

  const currentUser = ELNStore.useState(s => s.currentUser);

  const { showModal, toggleModal } = useModal()
  const [ shop, setShop ] = useState({})

  const [ shops, setShops ] = useState()
  const [ brands, setBrands ] = useState()
  const [ shopCount, setShopCount ] = useState()

  const handleSelect = (shop) => {
    setShop(shop)
    toggleModal(true)
  }

  useEffect ( () => {
    async function fetchData() {

      const today = new Date()
    today.setHours(0,0,0,0)

    const startDate = sub(today, {days: 7})
    const endDate = add(today, { days: 7, hours: 2})

      const { data: shops, error: shopsErr } = await supabase
        .from('shops')
        .select('*, brands (*), products (*), orders (delivery_dt)')
        .gte('orders.delivery_dt', startDate.toISOString())
        .lte('orders.delivery_dt', endDate.toISOString())
        .order('name', { foreignTable: 'brands'})   
        .order('delivery_dt', { foreignTable: 'orders'})   
        

      if (shopsErr) console.error(shopsErr)

      const { data: brands, error: brandsErr } = await supabase
        .from('brands')
        .select('*')
        .order('name')
      
      if (brandsErr) console.error(brandsErr)
      

      setBrands(brands)

      setShopCount(shops.length)
      const sortedShops = shops.sort(function(a, b) { return (`${a.brands.name} ${a.name}` > `${b.brands.name} ${b.name}` ? 1 : -1) })


      const groupedShops = sortedShops.groupBy(s => s.brands.name)
      setShops(groupedShops)

    }
    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal])

  if (!shops) return <Loader />

  const displayShops = () => {

    if (!shops) return
    return Object.keys(shops).map(b => {
      return (
        <>
          <tr><td colspan='5'><h2><br/>{b || <br/>}</h2></td></tr>
          {shops[b].map(s => <Shop readOnly={currentUser.p_admin<2} handleSelect={handleSelect} shop={s || {}} />)}
        </>
    )})
  }

  return (
    <div className='outer'>
        
        <h1>Shops ({shopCount})</h1>

        {currentUser.p_admin>=2 && <ul className='button-list'>
          <li><button onClick={() => handleSelect()}><FontAwesomeIcon icon={faPlus} />&nbsp;ADD NEW SHOP</button></li>
        </ul>}
        <Modal
          isShowing={showModal}
          title={shop ? 'Edit Shop' : 'Add New Shop'}
          hide={toggleModal}
        >

          <ShopForm brands={brands} toggleModal={toggleModal} defaultShop={shop || {}} />

        </Modal>

        <div className='table-wrapper'>
          <table>
            <tr>
                <th>ID</th>
                <th>DISPLAY NAME</th>
                <th>ORDERS PLACED</th>
                <th>LAST ORDER</th>
                <th>NEXT ORDER</th>
                <th>PRODUCTS AVAILABLE</th>
                <th>SHOW IN DELIVERY TABLE?</th>
                <th>CAN CREATE NEW ORDER?</th>
                <th></th>
            </tr>
          {displayShops()}
          </table>
        </div>

        
    </div>
  )
}

export default Shops;