import { NavLink, useLocation } from "react-router-dom";
import { ELNStore } from '../../store';

import { supabase } from '../../api'
import { format } from 'date-fns'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStore, faCheese, faTable, faFile, faFileAlt, faFileImport, faFileExport, faUser, faUserLock, faLock, faList, faSearch } from '@fortawesome/free-solid-svg-icons'
import AdminMenu from "../AdminMenu";
import { useHistory } from "react-router-dom";

export const Menu = () => {

    const currentUser = ELNStore.useState(s => s.currentUser);
    const currentDate = ELNStore.useState(s => s.currentDate);
    const viewAs = ELNStore.useState(s => s.viewAs);

    const location = useLocation();

    const history = useHistory()

    const signOut = async () => {

        const { error } = await supabase.auth.signOut()
        console.log(error)
        ELNStore.update(s => { s.currentUser = undefined })

        document.location.reload()
      }

    if (!currentUser) return ''



    const isAdmin = location.pathname.includes('/admin')
    return (
        <>
        <nav>
            {viewAs ? <div className="view-as">VIEWING AS <strong>{currentUser.firstname} {currentUser.lastname}</strong> - RELOAD PAGE TO RETURN TO ADMIN</div> : ''}
            <div className="nav-top">
                <div className="left"></div>
                <div className="logo">
                    <img src='/eln-london.png' alt="EL&amp;N London" />
                    <h1>BUSINESS 2 BUSINESS</h1>
                </div>
                <div className="right">
                    {currentUser && (
                    <div className='currentUser'>
                        <h2>{currentUser.firstname} {currentUser.lastname}</h2> 
                        <h3>
                            {currentUser.superadmin ? 'SUPERADMIN' : ''}
                            {!currentUser.superadmin && currentUser.admin && currentUser.p_admin===2 ? 'ADMIN' : ''}
                            {!currentUser.superadmin && currentUser.admin && currentUser.p_admin===1 ? 'ADMIN - READ ONLY' : ''}
                        </h3>
                        <button onClick={signOut}>sign out</button>
                    </div>
                    )}
                </div>
            </div>

            <ul className='row'>
          
                    <li>
                        <NavLink to={`/orders/${format(currentDate,'dd-MMM-yyyy')}`} exact activeClassName="current"><FontAwesomeIcon icon={faFileAlt} />&nbsp;&nbsp;Orders</NavLink>
                    </li>
                    <li>
                        <NavLink to={`/orders/search`} exact activeClassName="current">&nbsp;<FontAwesomeIcon icon={faSearch} />&nbsp;</NavLink>
                    </li>
                    <li>
                        <NavLink to={`/production-sheet/${format(currentDate,'dd-MMM-yyyy')}`} activeClassName="current"><FontAwesomeIcon icon={faTable} />&nbsp;&nbsp;Production Sheet</NavLink>
                    </li>
                    {currentUser.p_speccards >= 1 &&
                    <li>
                        <NavLink to="/spec-cards" activeClassName="current"><FontAwesomeIcon icon={faFile} />&nbsp;&nbsp;Spec Cards</NavLink>
                    </li>}
                
                {currentUser.admin && currentUser.p_admin>=1 && 
                    <>
                    
                    <li>
                        <NavLink to="/admin" activeClassName="current"><FontAwesomeIcon icon={faLock} />&nbsp;&nbsp;Admin</NavLink>
                    </li>
                    </>
                    }

                    </ul>
                    
        </nav>
        <nav className={`admin-menu ${isAdmin && currentUser.admin ? 'active' : ''}`}>
        {isAdmin && currentUser.admin ? <AdminMenu /> : ''}
        </nav>
        </>
        
    )
}

export default Menu;