
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";

import { Loader } from '../../helpers/loader';
import { useHistory, useLocation } from 'react-router-dom';

import { supabase } from '../../api'
import {DebounceInput} from 'react-debounce-input';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faFile, faPencilAlt, faMinus, faPrint, faReceipt, faBars, faFileAlt } from '@fortawesome/free-solid-svg-icons'

import { ELNStore } from '../../store'
import useModal from "../../helpers/useModal";
import RecipeForm from "../../components/RecipeForm";
import Modal from '../../components/Modal';
import { formatDate, getUniqueBy } from "../../helpers/utils";
import Photo from "../../components/Photo";

export const SpecCards = ({match}) => {


  const { showModal: showEditModal, toggleModal: toggleEditModal } = useModal()
  const { showModal: showLabelModal, toggleModal: toggleLabelModal } = useModal()

  const { showModal: showCardModal, toggleModal: toggleCardModal } = useModal()

  

  const history = useHistory();
  const location = useLocation();

  const defaultSearch = match.params.search ? decodeURIComponent(match.params.search).toUpperCase() : ''

  const [loading, setLoading] = useState(true)

  const [item, setItem] = useState()

  const [lastImported, setLastImported] = useState()

  const [filter, setFilter] = useState('S')
  const [types, setTypes] = useState('A')


  const storedRecipes = ELNStore.useState(s => s.recipes);
  const currentUser = ELNStore.useState(s => s.currentUser);

  const [recipe, setRecipe] = useState()

  const [recipes, setRecipes] = useState(storedRecipes)
  const [search, setSearch] = useState(defaultSearch || '')
  const [filteredRecipes, setFilteredRecipes] = useState([])
  const [matchType, setMatchType] = useState('')

  const [mini, setMini] = useState(false)

  const toggleMini = () => {
    setMini(m => !m)
  }

  useEffect(() => {
    async function initialize() {

        if (!recipes) {
            setLoading(true)

            const { data: recipes, error: recipesErr } = await supabase
            .from('recipes')
            .select('*, steps (*), ingredients!ingredients_parent_pid_fkey (*))')
            .order('position', { foreignTable: "ingredients", ascending: true })
            
            //const nonProductRecipes = recipes.filter(r => r.type !== 'PRODUCT')
            setRecipes(recipes)
            ELNStore.update(s => { s.recipes = recipes }) 

            setFilteredRecipes([])

            if (search) filterRecipes(recipes, search)
            else setFilteredRecipes(recipes)
            setLoading(false)
        }

    }
    initialize();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  

  

  useEffect(() => {
    // get pid from location

    if (recipes) {
      const dt = recipes.filter(r => r.imported_at).sort((a,b)=>(a.imported_at < b.imported_at ? 1 : -1))[0]
      setLastImported(dt.imported_at)
    }
  }, [recipes]);

  useEffect(() => {

    // get pid from location
    const pid = parseInt(location.pathname.split('/').pop())
    if (pid && recipes) {
        const selectedRecipe = recipes.find(r => r.pid === pid)
        if (selectedRecipe) {
          setRecipe(selectedRecipe)
          if (!showCardModal && selectedRecipe.ingredients.length) toggleCardModal()
        }
    }
  }, [location]);


  
  useEffect(() => {

    if (showCardModal) document.body.classList.add('no-print-root');
    else document.body.classList.remove('no-print-root');

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showCardModal])

  useEffect(() => {
    updateSearch(defaultSearch)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultSearch])

  const handleChangeSearch = (e) => {
      const search = e.target.value.toUpperCase()

      history.push(`/spec-cards/${search}`)

      //updateSearch(search)
  }

  const updateSearch = (txt) => {
    setSearch(txt.toUpperCase())

    if (!txt.length) setFilter('S')
    filterRecipes(recipes, txt)
  }

  const filterRecipes = (recipes, search) => {
    
    setLoading(true)
    if (!recipes) return
    
    let results = []
    if (!search || search.length === 0) {
        results = recipes
    }
    else if (isNaN(search) || parseInt(search)<10000) {  // text search

        results = recipes.filter(r => 
            r.display_name?.toUpperCase().includes(search) ||
            r.name?.toUpperCase().includes(search) ||
            r.desc?.toUpperCase().includes(search) ||
            r.terms?.toUpperCase().includes(search)
        )
        setMatchType(`for '${search}'`)

    }
    else {

        search = parseInt(search)
        const match = recipes.find(r => 
            r.pid === search
        )

        if (match) {
            //find related
            results = [match]
            setMatchType(`for ${match.type.toLowerCase()} ${match.name.toUpperCase()}`)
        }
        
        
    }

    // filter out duplicates
    const pids = []
    let cleanResults = []
    results.forEach(r => {
        if (!pids.includes(r.pid)) {

            //if (r.ingredients.length > 1) {
                pids.push(r.pid)
                cleanResults.push(r)
            //}
        }   
    })

    setFilteredRecipes(cleanResults)
    setTimeout(() => setLoading(false),1000)
    
  }

  
  const getChildRecipes = (recipe) => {

    let subRecipes = [recipe]
    recipe.ingredients.forEach( i => {

      const ingredient_recipe = recipes.find(r => r.pid === i.child_pid)
      const ingredientsCount = ingredient_recipe?.ingredients?.length


      if (ingredientsCount) {
        subRecipes.push(getChildRecipes(ingredient_recipe))
      }
        
    })
    return subRecipes.flat()

  }

  const SpecCardList = ({recipe}) => {

    let allRecipes = getChildRecipes(recipe).flat()
    allRecipes = getUniqueBy(allRecipes, 'pid')

    return (
      <>
      {allRecipes.map((r,i) => <SpecCard recipe={r} index={i} total={allRecipes.length-1} parentRecipe={recipe} />)}
      </>
    )
  }

  const Allergen = ({name}) => {
    return <div className='allergen'>{name}</div>
    }

    const Suitable = ({name}) => {
        return <div className='suitable'>{name}</div>
    }

  const ListRow = ({recipe}) => {

    const selectRecipe = () => {
        history.push(`/spec-cards/${search}/${recipe.pid}`)
    }

    const editItem = () => {
        setItem(recipe)
        toggleEditModal()
    }

    const allergens = getAllergensForRecipe(recipe)


    return (
        <tr key={`recipe-${recipe.pid}`}>
            <td>{recipe.pid}</td>
            <td>{recipe.type}</td>
            <td>{recipe.name}{recipe.desc ? <><br/><span className='sml'>{recipe.desc}</span></> : ''}</td>
            <td>{recipe.display_name}</td>
            <td>{allergens?.sort((a,b)=>(a > b ? 1 : -1)).map(a => <Allergen key={a} name={a} />)}</td>
            <td>{recipe.ingredients?.length || '-'}</td>
            <td>{recipe.steps?.length || '-'}</td>
            <td className='no-wrap right'>{recipe.ingredients?.length ? <button className='inv' onClick={selectRecipe}><FontAwesomeIcon icon={faFile} /></button> : ''}{currentUser.p_speccards>=2 ? <button className='inv' onClick={editItem}><FontAwesomeIcon icon={faPencilAlt} /></button> : ''}</td>
        </tr>
    )
  }

  const showQuantity = (quantity, multiplier, unit) => {
    return `${Math.round(100*parseFloat(quantity)*parseFloat(multiplier||1))/100} ${unit}`
  }

  const IngredientRow = ({ingredient, multiplier, expanded}) => {

    const [open, setOpen] = useState(false)

    const ingredient_recipe = recipes.find(r => r.pid === ingredient.child_pid)
    const ingredientsCount = ingredient_recipe?.ingredients?.length

    const allergens = getAllergensForRecipe(ingredient_recipe)

    const togglePanel = () => {
        setOpen(!open)
    }

    const showCard = () => {
        history.push(`/spec-cards/${search}/${ingredient_recipe.pid}`)
    }

    const editItem = () => {
        setItem(ingredient_recipe)
        toggleEditModal()
    }

    return (
        <tr key={`${recipe.pid}-${ingredient_recipe.pid}`}>
            <td className='row'>
              
              <div className='quantity'>{showQuantity(ingredient.quantity, multiplier, ingredient.quantity_unit)}</div>
            
              <div className='name'>
                <div>{ingredient_recipe.display_name || ingredient_recipe.name} {ingredient_recipe.type === 'PRODUCT' ? allergens?.map(a => <Allergen name={a} />) : ''}</div>

            
                {ingredientsCount ? <div className={`${open || expanded ? 'open' : ''} indent`}><IngredientTable child expanded={expanded} recipe={ingredient_recipe} multiplier={multiplier} /></div> : ''}
              </div>

            {ingredientsCount ?
                <div className='ingredient-menu'>
                    {!expanded ? <button className='inv' onClick={togglePanel}><FontAwesomeIcon icon={open ? faMinus : faPlus} /> {ingredientsCount}</button> : ''}

                    <button className='inv' onClick={showCard}><FontAwesomeIcon icon={faFile} /></button>

                    {currentUser.p_speccards>=2 ? <button className='inv' onClick={editItem}><FontAwesomeIcon icon={faPencilAlt} /></button> : ''}

                </div>
            : 
                <div className='ingredient-menu'>
                    {currentUser.p_speccards>=2 ? <button className='inv' onClick={editItem}><FontAwesomeIcon icon={faPencilAlt} /></button> : ''}
                </div>
            }

            </td>
        </tr>
    )
  }

  const IngredientTable = ({recipe, multiplier, expanded, child}) => {

    return (
        <table>
            {recipe.ingredients.map(i => {
                return <IngredientRow ingredient={i} multiplier={multiplier} expanded={expanded} />
            })}
            {!child ? <tr>
                <td><Allergens recipe={recipe} /></td>
            </tr> : ''}
        </table>
    )
  }

  const getAllergensForRecipe = (recipe) => {

    const topArr = recipe.allergens ? recipe.allergens.split(',') : []
    recipe.ingredients?.forEach(i => {
        
        const ingredient_recipe = recipes.find(r => r.pid === i.child_pid)

        if (ingredient_recipe) {
            const arr = []
            arr.push(ingredient_recipe.allergens ? ingredient_recipe.allergens.split(',') : [])
            arr.push(getAllergensForRecipe(ingredient_recipe))
            topArr.push(arr.flat())
        }
    })

    return [...new Set(topArr.flat().filter(Boolean))].sort((a,b)=>(a > b ? 1 : -1))
  }

  const Allergens = ({recipe}) => {
    const allergens = getAllergensForRecipe(recipe)
    return allergens.length ? <div className='allergens-list'><h3>Allergens</h3>{allergens.map(a => <Allergen name={a} />)}</div> : ''
  }

  const getIngredientsForRecipe = (recipe) => {

    const topArr = []
    if (recipe.ingredients.length === 0) return {name: recipe.display_name || recipe.name, label_name: recipe.label_name, allergens: recipe.allergens}

    recipe.ingredients.forEach(i => {
        const ingredient_recipe = recipes.find(r => r.pid === i.child_pid)
        if (ingredient_recipe) {
            const arr = []
            const sub = getIngredientsForRecipe(ingredient_recipe)
            arr.push(sub)
            topArr.push(arr.flat())
        }
    })
    return topArr.flat();
  }

  const Label = ({recipe}) => {
    let ingredients = getIngredientsForRecipe(recipe)
    ingredients = getUniqueBy(ingredients, 'name')
    
    let allergens = getAllergensForRecipe(recipe)
    
    const allergenList = allergens.join(', ')

    const ingredientList = ingredients.map((ing,i) => {

      const allergenList = ing.allergens && ing.allergens.split(',').join(', ')
      return (
        <>
          {ing.label_name || ing.name}
          {allergenList ? <strong> ({allergenList})</strong> : ''}
          {i<ingredients.length-1 ? ', ' : ''}
        </>
      )
    })

    return (
      <div className='ingredients-label'>
        <strong>{recipe.label_name || recipe.display_name || recipe.name}</strong><br/><br/>
        {ingredientList}
        <br/><br/>
        This product contains:<br/>
        <strong>{allergenList}</strong>
      </div>
    )
  }

  const Steps = ({steps}) => {
    return <div><h3>Method</h3><table className='steps'>{steps.map((m,i) => <tr><td>{m.txt}</td></tr>)}</table></div>
  }

  const Logo = () => {
    return <div className="specCard-logo">
            <img src='/eln-london.png' alt="EL&amp;N London" />

            <div className='specCard-terms'>Property of E L &amp; N LONDON - CONFIDENTIAL</div>
          </div>
  }

  const SpecCard = ({recipe, index, total, parentRecipe}) => {

    const [multiplier, setMultiplier] = useState(1)
    const [expandAll, setExpandAll] = useState(false)
    const updateMultiplier = (e) => {
        const m = e.target.value
        
        setMultiplier(parseFloat(m))
    }

    const isSub = recipe.pid !== parentRecipe.pid

    const editItem = () => {
        setItem(recipe)
        toggleEditModal()
    }

    if (!recipe.active && isSub) return ''  // dont show archived recipes when subs

    if (recipe.ingredients.length < 1) return ''
    const stepsChars = recipe.steps?.join(' ').length || 0

    let stepsLen = 'lge'
    if (stepsChars < 80 || recipe.ingredients?.length > 8) stepsLen = 'sml'

    return (
      <div className={`specCard ${mini ? 'mini' : ''}`}>

        {!mini && <Logo />}     

        

        <div className='specCardHeading'>
          
          

          <div className='col1'>    
            <div className='specCardTop'>


                
            </div>

            {isSub ? <div className="sub">
          SUB RECIPE {index} OF {total} for {(parentRecipe.display_name || parentRecipe.name).toUpperCase()} ({parentRecipe.pid}) 
        </div> : ''}

            <h1>{(recipe.display_name || recipe.name).toUpperCase()} </h1>

            <h4 >{recipe.type} {recipe.pid} {recipe.quantity && <> - {showQuantity(recipe.quantity, multiplier, recipe.quantity_unit)}</>}</h4>

            
          </div>
          <div className='col2'>
              <Photo bucket='recipes' path={recipe.pid} filename={0} />
          </div>
        </div>

        {recipe.ingredients && recipe.ingredients?.length ? 

        <>
            <div className={mini ? 'row' : ''}>
            <div className={`ingredients-column size-${stepsLen}`}>
              <h3 className='ingredients-calc'>Ingredients <span>x<input type="number" className='multiplier' onChange={updateMultiplier} defaultValue={recipe.multiplier || 1} min={1} /></span>
              <div className='ingredients-menu'>
              
              <button className='inv' onClick={() => setExpandAll(!expandAll)}><FontAwesomeIcon icon={expandAll ? faMinus : faPlus} /> all</button>
              <button className='inv' onClick={toggleLabelModal}><FontAwesomeIcon icon={faReceipt} /> label</button>
              {currentUser.p_speccards>=2 ? <button className='inv' onClick={editItem}><FontAwesomeIcon icon={faPencilAlt} /></button> : ''}
              
              </div>
              </h3>

              
              <IngredientTable expanded={expandAll} recipe={recipe} multiplier={multiplier} />
            </div>
            {mini && recipe.steps?.length ? <div className={`steps-column size-${stepsLen}`}><Steps steps={recipe.steps}/></div> : ''}
            
            </div>
        </>
        : ''}
    
    {!mini && recipe.steps?.length ? <Steps steps={recipe.steps}/> : ''}
    
    {recipe.steps?.length ? <div className='photo-section'>{recipe.steps.map((m,i) => <Photo bucket='recipes' path={recipe.pid} filename={i+1} />)}</div> : ''}
    
      </div>
    )
  }

  const handleChange = (updatedRecipe) => {

    const newRecipes = [...recipes.filter(r => r.pid !== updatedRecipe.pid), updatedRecipe]
    setRecipes(newRecipes)

    ELNStore.update(s => { s.recipes = newRecipes }) 
    const newFilteredRecipes = [...filteredRecipes]
    setFilteredRecipes([...newFilteredRecipes.filter(r => r.pid !== updatedRecipe.pid), updatedRecipe])

  }
  
 

  const recipesToShow = () => {
    let list = filteredRecipes
    if (filter === 'I') {
        list = filteredRecipes.filter(r => r.ingredients.length && r.active)
    }
    else if (filter === 'S') {
        list = filteredRecipes.filter(r => r.steps.length && r.active)
    }
    else if (filter === 'V') {
      list = filteredRecipes.filter(r => !r.active)
    }
    else if (filter === 'A') {
      list = filteredRecipes.filter(r => r.active)
    }

    if (types === 'R') {
      list = list.filter(r => r.type === 'RECIPE')
    }
    else if (types === 'D') {
      list = list.filter(r => r.type === 'DISH')
    }
    else if (types === 'P') {
      list = list.filter(r => r.type === 'PRODUCT')
    }
    return list.slice(0,100).sort((a,b)=>(a.name > b.name ? 1 : -1))
  }

  if (!recipes) return <Loader />

  const withSteps = filteredRecipes.filter(r => r.steps.length && r.active).length
  const withIngredients = filteredRecipes.filter(r => r.ingredients.length && r.active).length
  const withActive = filteredRecipes.filter(r => r.active).length
  const withArchived = filteredRecipes.filter(r => !r.active).length

  const showRecipes = recipesToShow()

  const doPrint = () => {
    window.print()
}

  return (

    <div className='outer'>

        <Modal
          isShowing={showEditModal}
          title='Edit Recipe Item'
          hide={toggleEditModal}
        >
          <RecipeForm toggleModal={toggleEditModal} handleChange={handleChange} defaultItem={item} />
        </Modal>

        <Modal
          isShowing={showLabelModal}
          title='Label'
          hide={toggleLabelModal}
        >
          <Label recipe={recipe}/>
        </Modal>

        <Modal
          isShowing={showCardModal}
          title='Spec Card'
          hide={toggleCardModal}
        >

          <div className='center no-print'>
            <ul className='button-list'>
              <li className={`inv ${mini ? 'selected' : ''}`}><button  onClick={toggleMini}><FontAwesomeIcon icon={faBars} />  Compact view</button></li>
              <li className={`inv ${!mini ? 'selected' : ''}`}><button  onClick={toggleMini}><FontAwesomeIcon icon={faFile} />  Page view</button></li>
              <li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</li>
              <li><button className='inv' onClick={doPrint}><FontAwesomeIcon icon={faPrint} /> PRINT</button>
            </li>
            </ul>
          </div>

          {mini && <Logo />}

          <div className='specCardList'>
          <SpecCardList recipe={recipe} />
          </div>

        </Modal>


        <h1 className='no-print'>Spec Cards</h1>
        
        

        <div className='center no-print'>

        <>
            <DebounceInput disabled={loading} placeholder='search PID or text...' debounceTimeout={1000} className='search' onChange={handleChangeSearch} value={search} />
            
            <br/><br/><h4>

            {!loading && <>{filteredRecipes.length ? `${filteredRecipes?.length} matches ${matchType}` : 'No matches found'}</>}
            
            
            


            {loading && <>Searching {recipes?.length} items...</>}

        {!loading && !recipes.length ? <>No data found</> : ''}

        </h4><br/>
        </>
        </div>

        <div className='center no-print'>
            <ul className='button-list'>

              <li className={types === 'A' ? 'selected' : ''}>
                <button onClick={() => setTypes('A')}>All types</button>
              </li>
              <li className={types === 'R' ? 'selected' : ''}>
                <button onClick={() => setTypes('R')}>Recipes</button>
              </li>
              <li className={types === 'D' ? 'selected' : ''}>
                <button onClick={() => setTypes('D')}>Dishes</button>
              </li>
              <li className={types === 'P' ? 'selected' : ''}>
                <button onClick={() => setTypes('P')}>Products</button>
              </li>
            </ul>
            <ul className='button-list'>
              <li className={filter === 'S' ? 'selected' : ''}>
                    <button onClick={() => setFilter('S')}>With steps: {withSteps}</button>
              </li>
              <li className={filter === 'I' ? 'selected' : ''}>
                    <button onClick={() => setFilter('I')}>With Ingredients: {withIngredients}</button>
              </li>
              <li className={filter === 'A' ? 'selected' : ''}>
                    <button onClick={() => setFilter('A')}>All Active: {withActive}</button>
              </li>
              <li className={filter === 'V' ? 'selected' : ''}>
                    <button onClick={() => setFilter('V')}>Archived: {withArchived}</button>
              </li>
              
            </ul>
          </div>

        

        {loading && <Loader />}

        {!loading && showRecipes.length ? 
            <><div style={{marginTop:'18px'}} className='table-wrapper'><table>
            <tr>
                <th>PID</th>
                <th>Type</th>
                <th>Original Name / Desc</th>
                <th>Display Name</th>
                <th>Allergens</th>
                <th>Ingredients</th>
                <th>Steps</th>
                <th></th>
            </tr>

            {showRecipes.map( r => (
            <ListRow key={r.pid} recipe={r} />
        ))}
        </table>
        
        
        </div>
        {showRecipes.length === 100 ? <div className='center'><br/><h4>Only showing first 100 results.  Please refine search</h4></div> : ''}
        </> : ''}

        <br/><br/>
        <div className='center'>Last imported: {formatDate(lastImported)}</div>
    </div>

  )
}

export default withRouter(SpecCards);
