import { formatDate, toStartOfDay, getCutoff, inPast, formatDateInWords, penceToCurrency, displayUser } from '../../helpers/utils'
import { format, isEqual } from 'date-fns'

export const triggerEmail = async (action, order, orderProducts, shop, currentDate, products, allUsers) => {

    const subject = `EL&N ORDER ${order.id} ${action} - ${shop.brands.name} ${shop.name} - for delivery: ${format(new Date(currentDate),'eee PP')}`

    let body = `<html><head><style>`
    body += `table td { border: 1px solid black; padding: 4px 8px; }`

    body += `</style></head><body>`
    body += `<h2>EL&N ORDER ${action}</h2>`

    body += `<table style="border-collapse: collapse;""><tr><td>For delivery on</td><td><strong>${format(new Date(currentDate),'PPPP')}</strong></td></tr>`
    body += `<tr><td>Shop</td><td><strong>${shop.brands.name} ${shop.name}</strong></td></tr>`
    body += `<tr><td>Order / Invoice num</td><td><strong>${order.id}</strong></td></tr>`
    body += `<tr><td>Purchase order num</td><td><strong>${order.purchase_order_num}</strong></td></tr>`
    body += `<tr><td>Notes</td><td><strong>${order.notes}</strong></td></tr>`

    body += `<tr><td>Created</td><td><strong>${formatDate(order?.created_at, true)} by ${displayUser(allUsers, order?.created_by)}</strong></td></tr>`
    if (action === 'UPDATED') body += `<tr><td>Updated</td><td><strong>${formatDate(order?.updated_at, true)} by ${displayUser(allUsers, order?.updated_by)}</strong></td></tr>`

    body += `</table><br/><a href='https://elnb2b.com/orders/${format(currentDate,'dd-MMM-yyyy')}/${order.id}'>View order on website</a><br/><br/>`
    body += `<table style="border-collapse: collapse;"">`

    let quantityCount = 0
    let costTotal = 0

    const recipients = [
        { name: "EL&N Admin", email: "admin@elnlondon.com" }
    ]

    if (shop.email) {
        recipients.push({ name: shop.name, email: shop.email })
    }

    body += products.map((category) => {
        if (category[1].length === 0) return ""
        const productRows = category[1].map(product => {

            const change = orderProducts.find(op => op.product_id === product.id)
            
            if (change) {
                const total = change.quantity * change.unit_cost
                quantityCount += change.quantity
                costTotal += total
                return `<tr><td style='padding-left:16px'> ${product.name} ${product.details ? `(${product.details})` : ''}</td><td style="text-align:right">${change.quantity}</td><td style="text-align:right">${penceToCurrency(change.unit_cost)}</td><td style="text-align:right">${penceToCurrency(total)}</td></tr>`
            }
        }).join('')
        if (productRows.length === 0) return ''
        return `<tr><td colspan='4'><strong>${category[0]}</strong></td></tr>${productRows}`

    }).join('')

    body += `<tr><td colspan='4'>&nbsp;</td></tr><tr><td><strong>TOTAL</strong></td><td><strong>${quantityCount}</strong></td><td></td><td><strong>${penceToCurrency(costTotal)}</strong></td></tr>`
    body += "</table>"
    body += `<br/><br/>Emailed to: ${(recipients.map(r => r.email)).join(', ')}<br/><br/>`
    body += "</body></html>"

    try { 
        await fetch("https://ciemyksmehzdtljwdyot.functions.supabase.co/order-created",
            {
                headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${process.env.REACT_APP_SUPABASE_ANON_KEY}`
                },
                method: "POST",
                mode: 'cors',
                body: JSON.stringify({
                    subject: subject,
                    content: body, 
                    recipients: recipients,
                })
            })
      }
      catch (e) {
          console.error(e)
      }

  }