import groupBy from "lodash.groupby";
import { Store } from "pullstate";
import { supabase } from "./api";

const today = new Date()
today.setHours(0,0,0,0)

export const ELNStore = new Store({
  allUsers: [],
  currentUser: undefined,
  currentDate: today,
  shopFilter: [],
  products: [],
  productsLastRequested: new Date().getTime()-100000,
  range: [new Date(), new Date('31 dec 2100')]
});

export const updateProducts = async () => {

  const { data: products, error: productsErr } = await supabase
    .from('products')
    .select('*, categories (*), suppliers (*), shopProducts (*)')
         
  let categorisedProducts = Object.entries(groupBy(products, p => p.categories?.name))

  // sort - supabase sort not working?
  categorisedProducts = categorisedProducts.sort(function(a, b) { return (a[0] > b[0] ? 1 : -1) })

  ELNStore.update(s => { 
    s.products = categorisedProducts
    s.productsLastRequested = new Date().getTime()
  })
  
}

export default ELNStore;
