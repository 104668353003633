import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave, faSyncAlt } from '@fortawesome/free-solid-svg-icons'
import { supabase } from '../../api'

export const ShopForm = ({ defaultShop, toggleModal, brands }) => {

    const [ shop, setShop ] = useState(defaultShop || {});
    const [ saving, setSaving ] = useState(false);
    const [ version, setVersion ] = useState(0)

    const save = async (shop) => {

        let success = true
        //save shop
        if (shop.id) {
          const { data, error } = await supabase
          .from('shops')
          .update(shop)
          .match({ id: shop.id })
          .single()
          if (error) {
            console.error('error updating', error)
            success = false
          }
        }
        else {
            const { data, error } = await supabase
            .from('shops')
            .insert([
                shop
            ])
            .single()
            if (error) {
                console.error('error inserting', error)
                success = false
            }
        }

        return success
    }

      
    const handleSave = async () => {
        setSaving(true)

        const cleanShop = {...shop}
        delete cleanShop.brands
        delete cleanShop.orders
        delete cleanShop.products

        const result = await save(cleanShop)
        setSaving(false)
        if (result) toggleModal()
    }

    const doCopy = () => {

        setShop({
            ...shop,
            delivery_line_1: shop.billing_line_1,
            delivery_line_2: shop.billing_line_2,
            delivery_town: shop.billing_town,
            delivery_postcode: shop.billing_postcode
        })

        setVersion(v => v+1)
    }


    

    return (
    <div>
        <table>
            <tr>
                <td>Brand</td>
                <td colSpan='2'>
                    
                    <select name='brand_id' style={{width:'150px'}} onChange={e => setShop({...shop, brand_id: e.target.value })} defaultValue={shop.brands?.id}>
                        <option>Select a brand...</option>
                        {brands.map(b => (
                            <option value={b.id}>{b.name}</option>
                        ))}

                    </select>
                </td>
            </tr>
            <tr>
                <td>Name</td>
                <td colSpan='2'><input name='name' placeholder='Name' style={{width:'300px'}} defaultValue={shop.name} onChange={e => setShop({...shop, name: e.target.value })} /></td>
            </tr>
            <tr>
                <td>Email address</td>
                <td colSpan='2'><input name='email' placeholder='Email address' style={{width:'300px'}} defaultValue={shop.email} onChange={e => setShop({...shop, email: e.target.value })} /></td>
            </tr>
            <tr>
                <td>Name in Import</td>
                <td colSpan='2'><input name='import_as' placeholder='Name in Import' style={{width:'300px'}} defaultValue={shop.import_as} onChange={e => setShop({...shop, import_as: e.target.value })} /></td>
            </tr>
            <tr>
            <td>Show in Delivery Table?</td>
            <td colSpan='2'><label><input type="checkbox" name='show_in_delivery_table' defaultChecked={shop.show_in_delivery_table} onClick={e => setShop({...shop, show_in_delivery_table: e.target.checked })} /></label>
            </td>
            </tr>
            <tr>
            <td>Active? - Can create new order?</td>
            <td><label><input type="checkbox" name='active' defaultChecked={shop.active} onClick={e => setShop({...shop, active: e.target.checked })} /></label>
            </td>
            </tr>


        <tr>
            <td>Addresses</td>
            <td>Billing</td>
            <td>Delivery <button onClick={doCopy}>Copy billing address</button></td>
        </tr>        
        <tr>
            <td>Line 1</td>
            <td><input name='billing_line_1' placeholder='' style={{width:'300px'}} defaultValue={shop.billing_line_1} onChange={e => setShop({...shop, billing_line_1: e.target.value })} /></td>
            <td><input key={`v-${version}-delivery_line_1`} name='delivery_line_1' placeholder='' style={{width:'300px'}} defaultValue={shop.delivery_line_1} onChange={e => setShop({...shop, delivery_line_1: e.target.value })} /></td>
 
        </tr>
        <tr>
            <td>Line 2</td>
            <td><input name='billing_line_2' placeholder='' style={{width:'300px'}} defaultValue={shop.billing_line_2} onChange={e => setShop({...shop, billing_line_2: e.target.value })} /></td>
            <td><input key={`v-${version}-delivery_line_2`} name='delivery_line_2' placeholder='' style={{width:'300px'}} defaultValue={shop.delivery_line_2} onChange={e => setShop({...shop, delivery_line_2: e.target.value })} /></td>
 
        </tr>
        <tr>
            <td>Town</td>
            <td><input name='billing_town' placeholder='' style={{width:'300px'}} defaultValue={shop.billing_town} onChange={e => setShop({...shop, billing_town: e.target.value })} /></td>
            <td><input key={`v-${version}-delivery_town`} name='delivery_town' placeholder='' style={{width:'300px'}} defaultValue={shop.delivery_town} onChange={e => setShop({...shop, delivery_town: e.target.value })} /></td>
 
        </tr>
        <tr>
            <td>Postcode</td>
            <td><input name='billing_postcode' placeholder='' style={{width:'300px'}} defaultValue={shop.billing_postcode} onChange={e => setShop({...shop, billing_postcode: e.target.value })} /></td>
            <td><input key={`v-${version}-delivery_postcode`} name='delivery_postcode' placeholder='' style={{width:'300px'}} defaultValue={shop.delivery_postcode} onChange={e => setShop({...shop, delivery_postcode: e.target.value })} /></td>
 
        </tr>



        </table>
        <div className='right'>
            <button className='clear' onClick={handleSave}>
                <FontAwesomeIcon spin={saving} icon={saving ? faSyncAlt : faSave} />&nbsp;
                Save
            </button>
        </div> 
        <br/><br/>
        <div className='sml'>
            Name in Import: This value needs to match the 'Purchaser Unit' column in the import
        </div>
    </div>
    )
}

export default ShopForm;