import { faBirthdayCake, faCheck, faClock, faFileAlt, faFileImport, faSyncAlt, faTimes, faTruck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { format, sub, formatDistanceToNow, differenceInSeconds, differenceInCalendarDays } from 'date-fns'
import { useHistory } from 'react-router-dom';

export const DEFAULT_CUT_OFF_HOURS = 24;  // 25 hours before midnight on delivery day (11:00pm)

export const toJSDate = (dt) => {
    if (!dt) return ''
    if (!dt instanceof Date && !dt.seconds) return ''
    const dtObj = (dt instanceof Date) ? dt : new Date(dt.seconds * 1000)
    return dtObj
}

export const BakingIcon = ({size = ''}) => {
    return <FontAwesomeIcon size={size} spin icon={faSyncAlt} />
}

export const OrderIcon = ({order}) => {
    let icon = order.imported_at ? faFileImport : faFileAlt
    if (order.order_type === 1) icon = faBirthdayCake

    return <span className={`icon-order-${order.order_type} ${order.imported_at ? 'icon-imported' : ''}`}><FontAwesomeIcon icon={icon} /></span>
}

export const OrderLink = ({order}) => {

    const history = useHistory();

    const viewOrder = (e) => {
        e.preventDefault()
        history.push(`/orders/${format(new Date(order.delivery_dt),'dd-MMM-yyyy')}/${order.id}`)
    }

    return (
        <a href='#' className='order-link' onClick={viewOrder}>{order.id}</a>
    )
}

export const StatusToken = ({order}) => {

    const old = inPast(new Date(order.delivery_dt))
    let cls = old ? ' old ' : ''
    let statusIcon = old ? faCheck : faClock;
    let statusTxt = old ? 'Delivered' : 'Scheduled';
    

    const pastCutOff = inPast(getCutoff(new Date(order.delivery_dt)))
    
    let processing = false
    if (pastCutOff && !old) processing = true;

    if (sameDay(new Date(order.delivery_dt), new Date())) {
        statusIcon = faTruck;
        statusTxt = 'Delivering...'
    }
    

    if (processing) {
        statusIcon = faSyncAlt;
        statusTxt = 'Baking...'
        cls += ' baking '
    }

    if (order.voided) {
        statusIcon = faTimes
        statusTxt = 'VOIDED'
    }

    let icon = <FontAwesomeIcon icon={statusIcon} />

    return <span className={`status-token ${cls}`}>{icon} {statusTxt}</span>

}

export const sortShops = (shops) => {
    return [...shops].sort((a,b) => `${a.brands.name}  ${a.name}` > `${b.brands.name}  ${b.name}` ? 1 : -1)
}

export const deliveryAddress = (order) => {
    let name = []
    let tel = ''
    let addr = []
    if (order.delivery_customer) name.push(order.delivery_customer)
    if (order.delivery_company) name.push(order.delivery_company)
    if (order.delivery_tel) tel = 'tel: ' + order.delivery_tel + '\n'
    if (order.delivery_line_1) addr.push(order.delivery_line_1)
    if (order.delivery_line_2) addr.push(order.delivery_line_2)
    if (order.delivery_line_3) addr.push(order.delivery_line_3)
    if (order.delivery_city) addr.push(order.delivery_city)
    if (order.delivery_postcode) addr.push(order.delivery_postcode)
    
    return `${name.join('\n')}\n${tel}\n${addr.join(', ')}`

}

export const perm = (number) => {
    if (number === 1) return 'R'
    if (number === 2) return 'R/W'
    return '-'
}

export const wait = async (ms) => {
    await new Promise(resolve => setTimeout(resolve, ms))
}

export const formatDate = (dt, withTime) => {
    if (!dt) return ''
    if (typeof dt === 'string') dt = new Date(dt)
    if (!dt instanceof Date && !dt.seconds) return ''
    const dtObj = (dt instanceof Date) ? dt : new Date(dt.seconds * 1000)
    return format(dtObj, withTime ? 'EEE d LLL HH:mm' : 'EEE d LLL');
}

export const formatFullDate = (dt) => {
    if (!dt) return ''
    if (typeof dt === 'string') dt = new Date(dt)
    if (!dt instanceof Date && !dt.seconds) return ''
    const dtObj = (dt instanceof Date) ? dt : new Date(dt.seconds * 1000)
    return format(dtObj, 'EEE d LLL yyyy');
}

export const getUniqueBy = (arr, prop) => {
    const set = new Set();
    return arr.filter(o => !set.has(o[prop]) && set.add(o[prop]));
  };

export const formatTime = (dt) => {
    if (!dt) return ''
    if (!dt instanceof Date && !dt.seconds) return ''
    const dtObj = (dt instanceof Date) ? dt : new Date(dt.seconds * 1000)
    return format(dtObj, 'HH:mm');
}

export const toStartOfDay = (dt) => {
    const dtObj = (dt instanceof Date) ? new Date(dt.getTime()) : new Date(dt.seconds * 1000)
    dtObj.setHours(0,0,0,0)
    return dtObj
}

export const toEndOfDay = (dt) => {
    const dtObj = (dt instanceof Date) ? new Date(dt.getTime())  : new Date(dt.seconds * 1000)
    dtObj.setHours(23,59,59)
    return dtObj
}

export const formatDateInWords = (dt) => {
    if (!dt) return ''
    if (typeof dt === 'string') dt = new Date(dt)
    if (!dt || (!dt instanceof Date && !dt.seconds)) return ''
    const dtObj = (dt instanceof Date) ? dt : new Date(dt.seconds * 1000)
    return formatDistanceToNow(dtObj, { addSuffix:true});
}

export const getCutoff = (date, hours = DEFAULT_CUT_OFF_HOURS) => {
    return sub(date, {
        hours: hours,
        minutes: 1
    })
}

export const inPast = (date) => {
    return differenceInSeconds(date, new Date()) < 0;
}

export const sameDay = (d1, d2) => {
    return differenceInCalendarDays(d1, d2) === 0
}

export const stringToPence = (str) => {
    return Math.abs(parseInt((parseFloat(str*100)).toFixed(0)))
}

export const penceToCurrency = (pence, hideIfZero = true) => {
    if (!pence && hideIfZero) return ''
    return (pence/100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const plural = (num, text, addn) => {
    if (num === 1) return `${num} ${text}`
    else return `${num} ${text}${addn || 's'}`
}

export const displayUser = (allUsers, id) => {
    const user = allUsers?.find(u => u.id === id)
    if (!user) return '?'
    return `${user.firstname || ''} ${user.lastname || ''}`
}


export const ALLERGENS = [
    'ALMONDS',
    'BARLEY',
    'BRAZILNUTS',
    'CASHEWS',
    'CELERY',
    'CEREALS',
    'CRUSTACEANS',
    'EGGS',
    'FISH',
    'GLUTEN',
    'HAZELNUTS',
    'KAMUT',
    'LUPIN',
    'MACADAMIA',
    'MILK',
    'MOLLUSCS',
    'MUSTARD',
    'OATS',
    'PEANUTS',
    'PECAN',
    'PISTACHIO',
    'QUEENSLANDNUTS',
    'RYE',
    'SESAME',
    'SOYA',
    'SPELT',
    'SULPHURDIOXIDE',
    'TREENUTS',
    'WALNUTS',
    'WHEAT',
]
