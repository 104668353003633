
import { useState, useEffect } from 'react'
import Calendar from 'react-calendar';
import { format, sub, add, getDay, eachDayOfInterval, isEqual } from 'date-fns'
import { ELNStore } from '../../store'
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faClock, faSave, faSyncAlt, faTruck, faCalendarDay, faCalendarAlt, faExclamationTriangle, faArrowRight, faArrowDown } from '@fortawesome/free-solid-svg-icons'
import Modal from '../../components/Modal';
import useModal from '../../helpers/useModal';

import 'react-calendar/dist/Calendar.css';
import { formatDate, getCutoff, DEFAULT_CUT_OFF_HOURS, sameDay, inPast, BakingIcon } from '../../helpers/utils';
import { supabase } from '../../api'


const Cal = ({gridPage, orderPage, tablePage}) => {

    const history = useHistory()

    const { showModal, toggleModal } = useModal()

    const [highlightedShop, setHighlightedShop] = useState()

    const currentDate = ELNStore.useState(s => s.currentDate);
    const currentUser = ELNStore.useState(s => s.currentUser);

    const [orders, setOrders] = useState()

    const [showCutoff, setShowCutoff] = useState(false)

    const [date, setDate] = useState(currentDate);

    const [hours, setHours] = useState(DEFAULT_CUT_OFF_HOURS)
    const [saving, setSaving] = useState(false)

    const today = new Date()
    today.setHours(0,0,0,0)
    const tomorrow = add(today, {days: 1})

    const startDate = sub(date, {days: 2})
    const endDate = add(date, { days: 9, hours: 2})

    const dates = eachDayOfInterval( { start: startDate, end: endDate })
    
    useEffect(() => {
        async function initialize() {  

            let op = 'not.eq'
            let arr = '0'

            if (!currentUser.admin) {
                op = 'in'
                arr = `(${currentUser.shops.map(s => s.id)})`
            }

            const { data: orders } = await supabase
                .from('orders')
                .select('id, delivery_dt, shop_id, order_type')
                .gte('delivery_dt', startDate.toISOString())
                .lte('delivery_dt', endDate.toISOString())
                .filter('shop_id', op, arr)

            setOrders(orders)

            updateCutoff(currentDate)
            
        }
        initialize();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentDate])

    const showCal = () => {
        toggleModal(true)
    }

    const showCutoffForm = async () => {
        setShowCutoff(true)
    }

    const closeCutoff = () => {
        setShowCutoff(false)
    }

    const updateCutoff = async (dt) => {
        /*let hrs = DEFAULT_CUT_OFF_HOURS
        const { data } = await supabase
                .from('cutoffs')
                .select()
                .eq('delivery_dt', format(dt,'dd-MMM-yyyy'))
                .single()

        if (data && !isNaN(data.hours)) {
            hrs = data.hours
        }*/

        setHours(getCutoff(dt))
        ELNStore.update(s => { s.cutOff = getCutoff(dt) })
    }

    const saveCutoff = async () => {
        setSaving(true)

        const { error } = await supabase
            .from('cutoffs')
            .delete()
            .eq('delivery_dt', format(date,'dd-MMM-yyyy'))

        const cutoff = {
            delivery_dt: format(date,'dd-MMM-yyyy'),
            hours: parseInt(hours),
            updated_by: currentUser.id,
            updated_at: (new Date()).toISOString()
        }

        const { error: insertErr } = await supabase
            .from('cutoffs')
            .insert(cutoff)
            .single()
        
        if (insertErr) {
            console.error('error', error)
        }

        setSaving(false)
    }

    const showToday = () => {
        gotoDate(new Date())
    }

    const gotoDateFromFull = async (dt) => {
        toggleModal()
        gotoDate(dt)
    }

    const fullCallCancelled = () => {
        toggleModal()
    }

    const gotoDate = async (dt) => {
        const path = document.location.pathname
        
        ELNStore.update(s => { s.currentDate = dt })
        setDate(dt)
        await updateCutoff(dt)
        
        if (path.indexOf('/orders')!==-1) return history.push(`/orders/${format(dt,'dd-MMM-yyyy')}`)
        if (path.indexOf('/production-sheet')!==-1) return history.push(`/production-sheet/${format(dt,'dd-MMM-yyyy')}`)
    }

    const day = (dt) => {
        const handleSelectDate = () => {
            gotoDate(dt)
        }

        
        const numOrders = orders?.filter(o => {
            return sameDay(new Date(o.delivery_dt), dt)
         } ).length
       
        const label = (num) => {
            const numOrEmpty = num || '-'
            if (isEqual(dt,today)) return <>{numOrEmpty}&nbsp;&nbsp;<FontAwesomeIcon icon={faTruck}/></>
            else if (isEqual(dt,tomorrow)) return <>{numOrEmpty}&nbsp;&nbsp;<BakingIcon /></>
            else if (dt > tomorrow && num>0) return <>{numOrEmpty}&nbsp;&nbsp;<FontAwesomeIcon icon={faExclamationTriangle}/></> 
            else return numOrEmpty
        }

        return (
            <th className={`${isEqual(dt,currentDate) ? 'selected' : ''}`} key={dt}>

                <button onClick={handleSelectDate} className={`day ${currentUser.admin ? 'with-count' : ''} ${getDay(dt)===6 || getDay(dt) === 0 ? 'we' : ''}`}>
                    <b>{format(dt,'EE')}</b>
                    <strong>{format(dt,'dd')}</strong>
                    <b>{format(dt,'MMM')}</b>
                    
                    {false && isEqual(dt,currentDate) && !inPast(currentDate) && currentUser && currentUser.admin && currentUser.p_admin>=2 ? <button className='cut-off-button' title='Set Cut-off time' onClick={showCutoffForm}><FontAwesomeIcon icon={faClock} /></button> : ''}
            
                </button>

                {isEqual(dt,today) ? <div className='today'>TODAY</div> : ''}
                <span className={`center ${numOrders === 0 ? 'noOrders' : 'someOrders'} ${dt>tomorrow && numOrders>0 ? 'future' : ''}`}><span>{label(numOrders)}</span></span>
            </th>
        )
    }

    const shopCell = (s, dt) => {

        const so = orders?.filter(o => {
            return o.shop_id === s.id && sameDay(new Date(o.delivery_dt), dt)
        })

        const selectShopAndDay = () => {
            setHighlightedShop(s)
        }

        const newOrder = () => {
            history.push(`/orders/${format(dt,'dd-MMM-yyyy')}/NEW/${s.id}`)
        }


        const canCreateNewOnThisDate = highlightedShop?.id === s.id && currentUser.p_orders>=2 && s.active
        const cutOffPassed = inPast(getCutoff(dt))

        return (
            <td className={`shop-cell ${sameDay(currentDate,dt) ? 'selected' : ''}`} onClick={selectShopAndDay}>
                {so?.map(o => {
                    const viewOrder = () => {
                        history.push(`/orders/${format(dt,'dd-MMM-yyyy')}/${o.id}`)
                    }

                    return (
                        <><button className={`inv sml order-${o.order_type}`} onClick={viewOrder}>{o.id}</button><br/></>
                    )
                }) || ''}
                {canCreateNewOnThisDate && (!cutOffPassed || currentUser.superadmin) ? <button className='light sml' onClick={newOrder}>New</button> : ''}
            </td>
        )
    }

    //const cutOffTime = getCutoff(currentDate, hours)
    
    let lastBrandName = 0

    return (
        <>
        <div className='calendar'>

            <Modal
                    isShowing={showModal}
                    title={'Select Date'}
                    hide={fullCallCancelled}
                    >

            {showModal && <Calendar
                onChange={gotoDateFromFull}
                value={date}
                showNeighboringMonth={false}
                next2Label={null}
                prev2Label={null}
                minDate={sub(date, {months: 2})}
                maxDate={add(date, {months: 6})}
            />}         

            </Modal>

            <div className='row separate' style={{marginTop:8, marginBottom:8}}>
            </div>

            {showCutoff && 
            
            <Modal
                    title={<><FontAwesomeIcon icon={faClock} />&nbsp;Set cut-off for <b>{formatDate(date, false)}</b>&nbsp;&nbsp;&nbsp;</>}
                    isShowing={showCutoff}
                    hide={closeCutoff}
                    >
                        
                        <div className='cutoff'>
                <input style={{width: 70, textAlign:'center'}} value={hours} name='hours' type='number' min='0' onChange={e => setHours(e.target.value)} /> &nbsp;hours &nbsp;&nbsp;=&nbsp;&nbsp;
                <input className='date' type='text' disabled value={formatDate(getCutoff(date,hours),true)} />
                
                <div className='center'>
                    <br/><br/>
                    <button onClick={saveCutoff}><FontAwesomeIcon spin={saving} icon={saving ? faSyncAlt : faSave} />&nbsp;&nbsp;Save</button>
                </div>

            </div>
            </Modal>}

            <div className='days'>
            <div className='table-wrapper'>
                <table className='shop-grid'>
                    <thead className='stickyTop'>
                    <tr>
                        <th></th>
                        <th className='calendar-menu'>
                            <div className='left'>
                                {gridPage ? <>Select a delivery date here <FontAwesomeIcon icon={faArrowRight} /><br/>Full order details are below <FontAwesomeIcon icon={faArrowDown} /><br/><br/>Click on a shop name <FontAwesomeIcon icon={faArrowDown} /><br/>to start a NEW order<br/><br/></> : ''}
                                {tablePage ? <>Select a delivery date here <FontAwesomeIcon icon={faArrowRight} /><br/><br/>The products below are due to be delivered on {formatDate(date, false)} <FontAwesomeIcon icon={faArrowDown} /></> : ''}
                                {orderPage ? <>This is an individual order<br/>for delivery on {formatDate(date, false)}<br/><br/>A button on the order lets you flip between ORDER and INVOICE views</> : ''}
                                
                            </div>
                            <div className='right col'>
                                <button onClick={showCal} title="Month view - go to ANY date"><FontAwesomeIcon icon={faCalendarAlt} /></button>
                                <button disabled={sameDay(new Date(), currentDate) ? true : false} title="Go to TODAY" onClick={showToday}><FontAwesomeIcon icon={faCalendarDay} /></button>
                            </div>
                        </th>
                        {dates.map(d => day(d))}
                        <th></th>
                    </tr>
                    </thead>

                    {gridPage && currentUser.shops.map(s => {

                        if (!s.active) return <></>
                        const showBrand = s.brands.id !== lastBrandName
                        lastBrandName = s.brands.id

                        const selectShop = () => {
                            setHighlightedShop(s)
                        }
                        return (
                            <>
                        {showBrand ? <tr><td colspan='15' className='brand-col'><h2><strong>{s.brands.name || ''}</strong></h2></td></tr> : ''}
                        <tr className={highlightedShop?.id === s.id ? 'highlighted' : ''}><td></td><td className='shop-col' onClick={selectShop}>{s.name}</td>{dates.map(d => shopCell(s, d))}<td></td></tr>
                        </>
                        )
                    })}
                
                </table>
                </div>
            </div>
            
            </div>
        </>
    )


}

export default Cal;