import { formatDate } from '../../helpers/utils'

export const Shop = ({ shop, readOnly, handleSelect }) => {

    const handleClickRow = () => {
        handleSelect(shop)
    }

    if (!shop) return ''

    const past = shop.orders.filter(o => new Date(o.delivery_dt) <= new Date())
    const future = shop.orders.filter(o => new Date(o.delivery_dt) >= new Date())
    
    const lastOrder = past && past.length ? past.slice(-1)[0] : undefined
    const nextOrder = future && future.length ? future[0] : undefined

    return (
        <tr>
            <td>{shop.id}</td>
            <td>{shop.name}</td>
            <td>{shop.orders ? shop.orders.length : 0}</td>
            <td>{!!lastOrder ? formatDate(lastOrder.delivery_dt) : ''}</td>
            <td>{!!nextOrder ? formatDate(nextOrder.delivery_dt) : ''}</td>
            <td>{shop.products ? shop.products.length : 0}</td>
            <td>{shop.show_in_delivery_table ? 'Yes' : 'No'}</td>
            <td>{shop.active ? 'Yes' : 'No'}</td>
            <td><button disabled={readOnly} onClick={handleClickRow}>Edit</button></td>
            
        </tr>

    )
}

export default Shop;