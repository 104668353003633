import { supabase } from '../../api'

import { useState } from "react";


export default function UpdatePassword() {

  const [status, setStatus] = useState('NEW')
  const [password, setPassword] = useState('')
  const [message, setMessage] = useState('')

  const changePassword = async () => {

    setStatus('SAVING')
    setMessage('Saving...')
    const { data, error } = await supabase.auth.updateUser({
      password,
    })

    if (data) {
      setMessage("Password updated successfully!")
      setStatus('DONE')
    }
    if (error) {
      setStatus('FAILED')
      setMessage(error.message)
    }

  }




    return (
      <>

        <div className='outer'>

          <div className='login-form'>
            <form>
              <h2>Update your password</h2>
              <br/>
              <label><span>Enter a new password of at least 6 characters</span>
              <input type='text' name='new-password' className='password-field' onChange={e => setPassword(e.target.value)} /></label>
              <br/>
              <button className='inv' disabled={status==='SAVING' || status==='DONE'} onClick={changePassword} >Save New Password</button>
              <br/><br/>
              <strong>{message}</strong>
              
            </form>
          </div>
        </div>
        </>
    )
}