import React from 'react';
import ReactDOM from 'react-dom';
import FocusTrap from 'focus-trap-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons'

const Modal = ({ title, isShowing, hide, children, showHeader = true }) => isShowing ? ReactDOM.createPortal(
  <React.Fragment>
      <div className="modal-overlay"/>
      <FocusTrap>
        <div className="modal-wrapper" aria-modal aria-hidden tabIndex={-1} role="dialog" onClick={hide}>
          <div className="modal" onClick={(e)=>{ e.stopPropagation() }}>
            <div className={`modal-header ${showHeader ? '' : 'minimum'}`}>
              <h1>{title}</h1>
              <button className='inv' type="button" data-dismiss="modal" aria-label="Close" onClick={hide}>
                <span aria-hidden="true"><FontAwesomeIcon icon={faTimes} /></span>
              </button>
            </div>
            <div className='modal-body'>
            {children}
            </div>
          </div>
        </div>
      </FocusTrap>
  </React.Fragment>, document.body
) : null;

export default Modal;