import { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave, faSyncAlt, faUser, faUserCog } from '@fortawesome/free-solid-svg-icons'
import { supabase } from '../../api'

export const UserForm = ({ defaultUser, shops, toggleModal }) => {

    const [ user, setUser ] = useState(defaultUser || {});
    const [ saving, setSaving ] = useState(false);
    
    const save = async (user, shops) => {

        let success = true
        let savedUser
        //save user
        if (user.id) {
          const { data, error } = await supabase
          .from('users')
          .update(user)
          .match({ id: user.id })
          .single()
          .select()
          if (data) {
              savedUser = data

              // delete existing userShops of existing user
              const { data: usData, error: usError } = await supabase
                .from('userShops')
                .delete()
                .match({ user_id: user.id })

          }
          if (error) {
            console.error('error updating', error)
            success = false
          }
        }
        else {
            const { data, error } = await supabase
            .from('users')
            .insert([
                user
            ])
            .single()
            .select()
            if (data) savedUser = data
            if (error) {
                console.error('error inserting', error)
                success = false
            }
        }

        if (!success) return success

        const userShops = shops?.map(s => ({ user_id: savedUser.id, shop_id: s}))

        if (userShops) {
            // create user shops
            const { data, error } = await supabase
                .from('userShops')
                .insert(userShops)
                if (error) {
                    console.error('error inserting us', error)
                    success = false
                }
        }
        return success
    }

      
    const handleSave = async () => {
        setSaving(true)
        const shopIds = user.shops?.map(s => s.id)
        const cleanUser = {...user, active: !!user.active, email: user.email.toLowerCase()}
        delete cleanUser.shops
        const result = await save(cleanUser, shopIds)
        setSaving(false)
        if (result) toggleModal()
    }

    const setShops = (id, checked) => {
        let userShops = user.shops ? [...user.shops] : []
        const changedShop = shops.find(s => s.id === id)
        if (checked) userShops.push(changedShop)
        if (!checked) userShops = userShops.filter(s => s.id !== id)
        setUser({...user, shops: userShops})
    }


    const displayShops = () => {

        const brands = shops.groupBy(s => s.brands.name)

        return Object.keys(brands).map(b => (
            <div className='brand'><h3>{b || ''}</h3>
            {brands[b].map(s => <div className='client-select'><label><input type="checkbox" name='clients' defaultChecked={user.shops && user.shops.map(s=>s.id).includes(s.id)} value={s.id} onClick={e => setShops(s.id, e.target.checked)} /> <b>{s.brand}</b> {s.name}</label></div>)}
            </div>
        ))
    }
    return (
        <>
        <table>
        <tr>
            <td></td>
            <td><FontAwesomeIcon icon={user.admin ? faUserCog : faUser} />&nbsp;{user.admin ? 'ADMINISTRATOR' : 'STANDARD USER'}<br/><br/></td>
        </tr>
        <tr>
            <td>First name</td>
            <td><input name='firstName' placeholder='First name' style={{width:'150px'}} defaultValue={user.firstname} onChange={e => setUser({...user, firstname: e.target.value })} />
        </td>
        </tr>
        <tr>
            <td>Last name</td>
            <td><input name='lastName' placeholder='Last name' style={{width:'150px'}} defaultValue={user.lastname} onChange={e => setUser({...user, lastname: e.target.value })} /></td>
        </tr>
        <tr>
            <td>Email</td>
            <td><input name='email' placeholder='Email address' style={{width:'250px'}} defaultValue={user.email} onChange={e => setUser({...user, email: e.target.value })} /></td>
        </tr>
        <tr>
            <td>Active?</td>
            <td><label><input type="checkbox" name='active' defaultChecked={user.active} onClick={e => setUser({...user, active: e.target.checked })} /></label></td>
        </tr>  
        <tr>
            <td>Orders</td>
            <td>
                <select name='p_orders' defaultValue={user.p_orders} onChange={e => setUser({...user, p_orders: e.target.value })} >
                    <option value={0}>NO ACCESS</option>
                    <option value={1}>READ ONLY</option>
                    <option value={2}>READ/WRITE</option>
                    
                </select>
            </td>
        </tr>
        <tr>
            <td>Spec Cards</td>
            <td>
                <select name='p_speccards' defaultValue={user.p_speccards} onChange={e => setUser({...user, p_speccards: e.target.value })} >
                    <option value={0}>NO ACCESS</option>
                    <option value={1}>READ ONLY</option>
                    <option value={2}>READ/WRITE</option>
                    
                </select>
            </td>
        </tr>
        {user.admin && <tr>
            <td>Admin</td>
            <td>
                <select name='p_admin' defaultValue={user.p_admin} onChange={e => setUser({...user, p_admin: e.target.value })} >
                    <option value={0}>NO ACCESS</option>
                    <option value={1}>READ ONLY</option>
                    <option value={2}>READ/WRITE</option>
                    
                </select>
            </td>
        </tr>}

        <tr>
            <td>Shops</td>
            <td><div className='client-select-window'>{displayShops()}</div></td>
        </tr>
        </table>

        <div className='right'>
            
            {!user.superadmin && <button className='clear' onClick={handleSave}>
                <FontAwesomeIcon spin={saving} icon={saving ? faSyncAlt : faSave} />&nbsp;
                Save
            </button>}
        </div>
    </>
    )
}

export default UserForm;