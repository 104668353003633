import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faTimes, faUser, faUserCog, faUserSlash } from '@fortawesome/free-solid-svg-icons'
import { formatDateInWords, perm, sortShops } from '../../helpers/utils'
import ELNStore from '../../store';

export const User = ({ user, handleSelect, handleToggle }) => {

    const currentUser = ELNStore.useState(s => s.currentUser);

    const handleEdit = () => {
        handleSelect(user)
    }

    const handleSwitch = () => {
        ELNStore.update(s => {
            s.currentUser = user.admin ? {...user, shops: currentUser.shops } : { ...user, shops: sortShops(user.shops) };
            s.viewAs = true;
        })
    }
    
    if (!user) return ''

    let shops = user.admin ? 'ALL' : (user.shops?.length || 0)
    if (!user.admin && user.shops.length <= 5) shops = <span className='sml'>{user.shops.map(s => s.name).join(', ')}</span>
    return (
        <tr className={`${user.active ? '' : 'inactive'}`}>
            <td>
                {user.active ? <FontAwesomeIcon icon={user.admin ? faUserCog : faUser} /> : ''}
                {!user.active ? <FontAwesomeIcon icon={faUserSlash} /> : ''}
                </td>
            <td>{user.firstname} {user.lastname}<br/>{user.email}</td>
            <td className='center'>{perm(user.p_orders)}</td>
            <td className='center'>{perm(user.p_speccards)}</td>
            <td className='center'>{perm(user.p_admin)}</td>
            <td className='center'>{shops}</td>
            <td className='no-wrap'>{user.uuid ? '' : 'NEVER'} {formatDateInWords(user.lastsignin_at)}</td>
            <td className='no-wrap'> <button className='sml' onClick={handleSwitch} >View As</button></td>
            <td>{!user.superadmin ? <button className='sml' onClick={handleEdit} >Edit</button> : ''}</td>
        </tr>
    )
}

export default User;