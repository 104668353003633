export const ShopList = ({ brands, handleSelect }) => {
    
    return (
        <select className='shop-filter' name='shop' onChange={(e) => handleSelect(e.target.value)}>
        <option value=''>ALL SHOPS</option>
        {brands && Object.keys(brands).map(k => {

          const brandIds = brands[k].sort((a,b)=>(a.name > b.name ? 1 : -1)).map(s => s.id).join(',')
          return (
            <>
              <optgroup value=''></optgroup>
              <option value={`${brandIds}`}>{(k || '[NOT SET]').toUpperCase()}</option>

              {brands[k].sort((a,b)=>(a.name > b.name ? 1 : -1)).map(s => <option value={s.id} >&nbsp;&nbsp;&nbsp;{s.name} {s.active ? '' : '(inactive)'}</option>)}

            </>
          )
        })}
            
      </select>
    )
}

export default ShopList;