
import { useState } from 'react'
import Calendar from 'react-calendar';

import { ELNStore } from '../../store'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faCalendarAlt, faTimes } from '@fortawesome/free-solid-svg-icons'

import Modal from '../../components/Modal';
import useModal from '../../helpers/useModal';

import 'react-calendar/dist/Calendar.css';
import { formatFullDate, sameDay } from '../../helpers/utils';

const ExportCal = ({showReset}) => {

    const defaultRange = ELNStore.useState(s => s.range);

    const { showModal, toggleModal } = useModal()

    const [range, setRange] = useState(defaultRange || [new Date(), new Date()])

    const showCal = () => {
        toggleModal(true)
    }

    const doReset = (e) => {
        e.preventDefault()
        e.stopPropagation()
        const newRange = [new Date(), new Date('31 Dec 2100')]
        setRange(newRange)
        ELNStore.update(s => { s.range = newRange })
    }

    const handleCalendarChange = async (range) => {
        toggleModal()
        setRange(range)
        ELNStore.update(s => { s.range = range })    
    }

    const fullCallCancelled = () => {
        toggleModal()
    }

    let rangeInWords = ''
    

    rangeInWords = sameDay(range[0], range[1]) ? formatFullDate(range[0]) : `${formatFullDate(range[0])} - ${formatFullDate(range[1])}`
    if (sameDay(range[0], new Date()) && sameDay(range[1], new Date('31 dec 2100'))) rangeInWords = `Today onwards`

    return (
        <>
            <Modal
                    isShowing={showModal}
                    title={'Select date range'}
                    hide={fullCallCancelled}
                    >

            {showModal && <Calendar
                onChange={handleCalendarChange}
                selectRange={true}
                showDoubleView={false}
                returnValue="range"
                next2Label={null}
                showNeighboringMonth={false}

                prev2Label={null}
            />}         

            </Modal>

            <button className='today clear' onClick={showCal}><FontAwesomeIcon icon={faCalendarAlt} />&nbsp;&nbsp;{rangeInWords}
            
            {showReset ? <a href='#' className='button-in-button' onClick={doReset}><FontAwesomeIcon icon={faTimes} /></a> : ''}</button>
            
        </>
    )


}

export default ExportCal;