
import React, { useState, useEffect } from "react";
import { withRouter } from "react-router";
import { Product } from '../../components/Product';
import { ProductForm } from '../../components/ProductForm';
import Modal from '../../components/Modal';
import useModal from '../../helpers/useModal';
import { Loader } from '../../helpers/loader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faArchive, faCheck, faImage } from '@fortawesome/free-solid-svg-icons'
import { supabase } from '../../api'
import ELNStore from "../../store";
import { DebounceInput } from "react-debounce-input";

export const Products = () => {

  const currentUser = ELNStore.useState(s => s.currentUser);

  const { showModal, toggleModal } = useModal()
  const [ showAvailability, toggleShowAvailability ] = useState(false)
  const [ showArchived, toggleShowArchived ] = useState(false)
  
  const [ product, setProduct ] = useState()
  const [products, setProducts] = useState()
  const [filteredProducts, setFilteredProducts] = useState()
  const [count, setCount] = useState()

  const [shops, setShops] = useState()
  
  const [filteredShops, setFilteredShops] = useState()
  const [brands, setBrands] = useState()
  const [brand, setBrand] = useState()
  const [categories, setCategories] = useState()
  const [suppliers, setSuppliers] = useState()

  const [term, setTerm] = useState()
  const [category, setCategory] = useState({})
  const [supplier, setSupplier] = useState({})


  useEffect ( () => {
    async function fetchData() {

      const { data: shops, error: shopsErr } = await supabase
        .from('shops')
        .select('*, brands (*)')
        .order('name')   

      //.select('*, categories (*), suppliers (*), shopProducts(*), recipes (pid)')
      const { data: products, error: productsErr } = await supabase
        .from('products')
        .select('*, categories (*), suppliers (*), shopProducts(*)')
         
      const { data: suppliers, error: suppliersErr } = await supabase
        .from('suppliers')
        .select('*')
        .order('name')

      const { data: categories, error: categoriesErr } = await supabase
        .from('categories')
        .select('*')
        .order('name')
      
      // duplicates problem  
      products.forEach(p => {
        p.duplicates = products.filter(q => q.pid === p.pid).length
      })

      console.log('cats = ', categories)

      const brandArray = shops.groupBy(s => s.brands.name)
      const brands = Object.keys(brandArray).map(b => { return { ...brandArray[b][0].brands, size: brandArray[b].length } })

      setShops(shops)
      setFilteredShops(shops)
      setBrands(brands)
      setBrand(brands[0])
      setSuppliers(suppliers)
      setCategories(categories)
      setProducts(products)
    }
    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal])


  useEffect(() => {
    setFilteredShops(brand ? shops.filter(s => s.brands.id === brand.id) : shops)
  }, [brand])

  useEffect(() => {
    
    if (!products) return 
    const allData = showArchived ? products.filter(product => product.active === false) : products.filter(product => product.active === true)
    const filteredBySupplier = supplier.id ? allData.filter(product => product.suppliers?.id === supplier.id) : allData
  
    let filteredProducts = category.id ? filteredBySupplier.filter(product => product.categories?.id === category.id) : filteredBySupplier
  
    const count = (category.id || supplier.id) ? `(${filteredProducts.length}/${allData.length})` : `(${allData.length})`
  
    filteredProducts = filteredProducts.sort((a,b) => { return `${a.suppliers.name} ${a.categories.name} ${a.name}` > `${b.suppliers.name} ${b.categories.name} ${b.name}` ? 1 : -1})

    if (term) {
      filteredProducts = filteredProducts.filter(p => p.pid === parseInt(term) || p.name.toUpperCase().includes(term.toUpperCase()) || p.details?.toUpperCase().includes(term.toUpperCase()))
    }
    setFilteredProducts(filteredProducts)
    setCount(count)

  }, [products, showArchived, supplier, category, term])


  const handleChangeSearch = (e) => {
    setTerm(e.target.value)
    //updateSearch(search)
  }

  const handleSelect = (product) => {
    setProduct(product)
    toggleModal(true)
  }

  const handleToggle = async (product, shop, set, cost) => {

    const shopProduct = { shop_id: shop.id, product_id: product.id }
    if (cost!==undefined) shopProduct.unit_cost = cost || null;

    if (set) {
      const { data, error } = await supabase
        .from('shopProducts')
        .upsert(shopProduct)
        .single()
        .select()
      if (error) console.error('error upserting shopProduct', error)
      else {
        const newProducts = [...filteredProducts]
        const edittedProduct = newProducts.find(p => p.id === product.id)
        edittedProduct.shopProducts = [...edittedProduct.shopProducts.filter(s => s.shop_id !== shop.id), data]
        setFilteredProducts(newProducts)
      }
    }
    else {
      const { data, error } = await supabase
        .from('shopProducts')
        .delete()
        .match(shopProduct)
    
      if (error) console.error('error deleting shopProduct', error)
      else {
        const newProducts = [...filteredProducts]
        const edittedProduct = newProducts.find(p => p.id === product.id)
        edittedProduct.shopProducts = edittedProduct.shopProducts.filter(s => s.shop_id !== shop.id)
        setFilteredProducts(newProducts)
      }
    }    
  }

  const toggleAvailability = () => {
    toggleShowAvailability(t => !t)
  }

  const toggleArchived = () => {
    toggleShowArchived(t => !t)
  }

  if (!products) return <Loader />
  
  const displayShops = () => {

    if (!filteredShops) return

    const brands = filteredShops.groupBy(s => s.brands.name)

    return Object.keys(brands).map(b => {
      return brands[b].map(s => <th className='center nowrap'><div className='sml'>&nbsp;{s.brands.name}&nbsp;</div>{s.name}</th>)
    })
  }

  return (
    <div className='outer'>
        <h1>Products {count}</h1>

        <div className='center'>
        <DebounceInput autoFocus placeholder='search PID, name, description' debounceTimeout={1000} className='search' onChange={handleChangeSearch} value={term} />
        <br /><br/>
        </div>

        <div className='group'>
          <div className='group-name'>Filter Supplier</div>
          <ul className='button-list'>
            {suppliers.map(s => {
              const setThisSupplier = () => {
                  setSupplier(supplier.id === s.id ? {} : s)
              }
              return (
                  <li key={`s-${s.id}`} className={supplier.id === s.id ? 'selected' : ''}>
                      <button onClick={setThisSupplier}>{s.name || '[NOT SET]'}</button>
                  </li>
              )
            })}
          </ul>
        </div>

        <div className='group'>
          <div className='group-name'>Filter category</div>
          <ul className='button-list'>
            {categories.map(c => {
              const setThisCategory = () => {
                  setCategory(category.id === c.id ? {} : c)
              }
              return (
                  <li key={`c-${c.id}`} className={category.id === c.id ? 'selected' : ''}>
                      <button onClick={setThisCategory}>{c.name || '[NOT SET]'}</button>
                  </li>
              )
            })}
          </ul>
        </div>


        <ul className='button-list'>
          {currentUser.p_admin>=2 && <li><button className='clear' onClick={() => handleSelect()}><FontAwesomeIcon icon={faPlus} />&nbsp;ADD NEW PRODUCT</button></li>}
          <li className={showAvailability ? 'selected' : ''}><button className='clear' onClick={() => toggleAvailability()}><FontAwesomeIcon icon={faCheck} />&nbsp;Show Availability</button></li>

          <li className={showArchived ? 'selected' : ''}><button className='clear' onClick={() => toggleArchived()}><FontAwesomeIcon icon={faArchive} />&nbsp;Show Archived</button></li>
        </ul>
        <Modal
          isShowing={showModal}
          title={product ? 'Edit Product' : 'Add New Product'}
          hide={toggleModal}
        >

          <ProductForm toggleModal={toggleModal} defaultProduct={product} categories={categories} suppliers={suppliers} />


        </Modal>

        {showAvailability && 
        <div className='group'>
          <div className='group-name'>Showing Availability For The Selected Brand</div>
          <ul className='button-list'>
            {brands?.map(b => {
              const setThisBrand = () => {
                  setBrand(b)
              }
              return (
                  <li key={`b-${b.id}`} className={brand?.id === b.id ? 'selected' : ''}>
                      <button onClick={setThisBrand}>{`${b.name || '[NOT SET]'} (${b.size})` }</button>
                  </li>
              )
            })}
          </ul>
        </div>}

        {filteredProducts && filteredProducts.length ? 
        <div className='table-wrapper'>
        <table>
          <thead className='stickyTop'>
          {showAvailability && 
            <tr>
                <th>PID</th>
                <th>UNIT PRICE</th>
                <th className='stickyLeft'>NAME</th>

                {displayShops()}
            </tr>
          }
          {!showAvailability && 
            <tr>
                <th>SUPPLIER</th>
                <th>CATEGORY</th>
                <th>PID</th>
                <th>UNIT PRICE</th>
                <th className='stickyLeft'>NAME</th>
                <th>DESCRIPTION</th>
                <th>RECIPES</th>
                <th>ACTIVE</th>
                <th>AVAILABILITY</th>
                <th></th>
            </tr>
          }
          </thead>
          <tbody>
          {filteredProducts.slice(0,300).map(product => <Product readOnly={currentUser.p_admin<2} shops={filteredShops} showAvailability={showAvailability} handleToggle={handleToggle} handleSelect={handleSelect} product={product} />)}
          </tbody>
        </table>

        {filteredProducts.length>300 ? <div className='center'><br/><br/><br/>Over 300 products found - change filter</div> : ''}
        </div>
        : <div className='center'><br/><br/><br/>No products found - change filter</div>}
    </div>

  )
}

export default withRouter(Products);
