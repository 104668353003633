import { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave, faSyncAlt, faTrash } from '@fortawesome/free-solid-svg-icons'
import { penceToCurrency } from '../../../helpers/utils';

import to from 'await-to-js';
import { supabase } from '../../../api'

export const WeddingForm = () => {


    const [ lead, setLead ] = useState({})
    
    const [ saving, setSaving ] = useState(false);

    const [ done, setDone ] = useState(false)


    const save = async (lead) => {

        let success = true

            const { data, error } = await supabase
            .from('leads')
            .insert(lead)
            .single()
            if (error) {
                console.error('error inserting', error)
                success = false
            }
        

        return success
    }

      
    const handleSave = async () => {
        

        if (!lead.first_name || (!lead.tel && !lead.email)) {
            alert('Please enter either a telephone number or email address for us to contact you if you win')
            return
        }

        setSaving(true)
        const result = await save(lead)

        if (result) setDone(true)

        setSaving(false)

    }

    return (
    <div className='campaignWrapper'>
    <div className='campaign'>
    <div className="logo">
              <img src='/eln-london.png' alt="EL&amp;N London" />
              <h1>Celebration Cakes</h1><br/>
              <a  style={{padding:'8px 16px', background:'#fff', textDecoration: 'none', borderBottom:'2px solid #666', borderRadius:8, display:'flex', flexDirection:'column', alignItems:'center'}} href='https://elnlondon.com/collections/el-n-celebration-cakes' target='_blank' rel="noreferrer"><span style={{fontSize:20}}>Order Celebration Cakes Online</span><span style={{paddingTop:5, color:'#333'}}>Use code <strong>HELLOEL&amp;N</strong> for 10% off all cakes</span></a><br/>

              <h1 style={{fontSize:30}}>Win a wedding cake</h1>
              <h2 style={{textAlign:'center', margin:10}}>Enter your details below for a chance to win a wedding cake up to &pound;500</h2>
            </div>

        <table className='customerForm'>
 
        <tr>
            <td>First name<br/><input name='first_name' style={{width:280}} placeholder=''  defaultValue={lead.first_name} onChange={e => setLead({...lead, first_name: e.target.value })} /></td>
        </tr>
        <tr>
            <td>Last name<br/><input name='last_name' style={{width:280}} placeholder=''  defaultValue={lead.last_name} onChange={e => setLead({...lead, last_name: e.target.value })} /></td>
        </tr>
        <tr>
            <td>Telephone number<br/><input name='tel' style={{width:280}} placeholder=''  defaultValue={lead.tel} onChange={e => setLead({...lead, tel: e.target.value })} /></td>
        </tr>
        <tr>
            <td>Email address<br/><input name='email' style={{width:280}} placeholder=''  defaultValue={lead.email} onChange={e => setLead({...lead, email: e.target.value })} /></td>
        </tr>

        <tr>
            <td>Wedding details<br/><textarea name='details' style={{width:280, height:200}} placeholder='Date, venue, cake requirements etc' defaultValue={lead.details} onChange={e => setLead({...lead, details: e.target.value })} /></td>
        </tr>

        {done && <tr>
            <td>
                <h1>Thank you.  Your entry has been received. Good luck!</h1>
            </td>
            </tr>}

        {!done && <tr>
            <td className='right'><button className='inv' onClick={handleSave}>
                <FontAwesomeIcon spin={saving} icon={saving ? faSyncAlt : faSave} />&nbsp;
                Submit
            </button></td>
        </tr>}

        <tr>
            <td>
                <br/><br/><br/>
                <b>Terms and conditions</b><br/><br/>
                <ol>
                <li>The winner will receive a wedding cake up to the value of &pound;500 (or &pound;500 off a wedding cake)</li>
                <li>No alternative prize or cash in lieu will be offered and the prize is non-transferable</li>
                <li>Delivery charges may apply</li>
                <li>Only one entry per household is permitted, multiple entries will be disqualified</li>
                <li>The winner will be the first eligible entry, drawn at random, after the closing date</li>
                <li>By entering the competition, you confirm that all information submitted by you is true, current and complete</li>
                <li>EL&amp;N reserves the right to disqualify any entrant if it has reasonable grounds to believe the entrant has breached any of the rules</li>
                <li>In the event of any dispute regarding the rules, conduct, results and all other matters relating to a competition, the decision of the judge(s) shall be final and no correspondence or discussion shall be entered into</li>
                <li>EL&amp;N will not be liable if a prize does not reach a prize-winner for any reason outside of their control or if a prize is damaged during delivery</li>
                <li>EL&amp;N reserves the right to cancel or amend the competition in the event of unforeseen circumstances or circumstances outside its reasonable control</li>
                <li>EL&amp;N reserves the right to re-draw an alternative winner and re-allocate the prize, if after a period of 28 days, the original winner has not responded</li>
                

                </ol>
            </td>
        </tr>
        </table>

        
            

    </div>
    </div>
    )
}

export default WeddingForm;