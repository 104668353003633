
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";

import { ELNStore, updateProducts } from '../../store'
import groupBy from 'lodash.groupby';
import { formatDate, toStartOfDay, getCutoff, inPast, formatDateInWords, penceToCurrency, displayUser, wait, sameDay, BakingIcon } from '../../helpers/utils'
import { Loader } from '../../helpers/loader'

import { useHistory } from 'react-router-dom';
import { add, format, isEqual, sub } from 'date-fns'

import { supabase } from '../../api'

import OrderForm from "../../components/OrderForm";

export const Order = ({match}) => {

  const history = useHistory();

  const orderID = match.params.orderID;

  const shopID = match.params.shopID;

  const currentUser = ELNStore.useState(s => s.currentUser);

  const allUsers = ELNStore.useState(s => s.allUsers);

  const products = ELNStore.useState(s => s.products);

  const dateStr = match.params.date;
  const currentDateFromPath = toStartOfDay(new Date(dateStr))
  let currentDate = ELNStore.useState(s => s.currentDate);

  const productsLastRequested = new Date().getTime() - ELNStore.useState(s => s.productsLastRequested)

  if (!isEqual(currentDateFromPath, currentDate)) {
    ELNStore.update(s => { s.currentDate = currentDateFromPath })
    currentDate = currentDateFromPath
  }

  const [loading, setLoading] = useState(false)

  const [error, setError] = useState()
  const [order, setOrder] = useState()
  const [shop, setShop] = useState()

  useEffect ( () => {
    async function fetchData() {

        setLoading(true)

        // load products
        if (productsLastRequested > 60000) updateProducts()

        // load existing order
        if (orderID) getOrder(orderID)
        else { // new order

            setOrder({order_type: 0, orderCustoms: []})
            const shop = currentUser.shops.find(s => s.id === parseInt(shopID))
            if (shop) {
                setShop(shop)
            }
            else {
                setError(`SHOP ${shopID} NOT FOUND`)
            }
        }

        
        setLoading(false)

    }
    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  const getOrder = async (orderID) => {
    let op = 'not.eq'
    let arr = '0'
    if (!currentUser.admin) {
        op = 'in'
        arr = `(${currentUser.shops.map(s => s.id)})`
    }

    const { data: order, error } = await supabase
      .from('orders')
      .select('*, orderProducts (quantity, unit_cost, products:product_id (*)), orderCustoms (*)')
      .match({id: orderID})
      .filter('shop_id', op, arr)
      .single()

    if (error) {
        console.error('error getting order', error)
        setError(`ORDER ${orderID} NOT FOUND`)
        
    }
    if (order) {
        
        setOrder(order)
        const shop = currentUser.shops.find(s => s.id === order.shop_id)
        setShop(shop)
    
    }

    setLoading(false)

  }
  
  if (error) {
    return (
        <div className='outer'>
            <h2>{error}</h2>
        </div>
    )
  }

  if (loading || !shop) return (
    <div className='outer'>
        <Loader />
    </div>
  )

  return (
    <div className='outer'>
        <OrderForm defaultOrder={order} shop={shop} products={products} currentUser={currentUser} allUsers={allUsers} currentDate={currentDate} />
    </div>
  )
}

export default withRouter(Order);
