
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";

import { ELNStore } from '../../store'

import { toStartOfDay,  penceToCurrency, OrderIcon, StatusToken } from '../../helpers/utils'
import { Loader } from '../../helpers/loader'

import { useHistory } from 'react-router-dom';
import { format, isEqual } from 'date-fns'
import Cal from '../../components/Cal'
import { supabase } from '../../api'

export const Orders = ({match}) => {

  const history = useHistory();

  const currentUser = ELNStore.useState(s => s.currentUser);

  const dateStr = match.params.date;
  const currentDateFromPath = toStartOfDay(new Date(dateStr))
  let currentDate = ELNStore.useState(s => s.currentDate);


  if (!isEqual(currentDateFromPath, currentDate)) {
    ELNStore.update(s => { s.currentDate = currentDateFromPath })
    currentDate = currentDateFromPath
  }

  const [orders, setOrders] = useState(null)
  const [loading, setLoading] = useState()

  useEffect ( () => {
    async function fetchData() {
        setLoading(true)
        await getOrders()
        setLoading(false)
    }
    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateStr])

  const getOrders = async () => {
    let op = 'not.eq'
    let arr = '0'

    if (!currentUser.admin) {
        op = 'in'
        arr = `(${currentUser.shops.map(s => s.id)})`
    }

    const { data, error } = await supabase
      .from('orders')
      .select('*, orderProducts (quantity, unit_cost), orderCustoms (*)')
      .match({delivery_dt: format(new Date(currentDate),'yyyy-MM-dd')})
      .filter('shop_id', op, arr)

    if (error) console.error('error getting orders', error)
    if (data) {


        let orders = [...data]

        orders.forEach(o => {
            const shop = currentUser.shops.find(s => s.id === o.shop_id)

            o.shop = shop
            if (o.order_type === 0) {
              o.items = o.orderProducts?.reduce(function (acc, row) { return acc + (row.quantity) }, 0)
              o.total = o.orderProducts?.reduce(function (acc, row) { return acc + (row.quantity * row.unit_cost) }, 0)
            }
            else {
              o.items = o.orderCustoms?.length || 0
              o.total = o.orderCustoms?.reduce((agg, oc) => agg + oc.unit_cost, 0);

            }
        })

        orders = orders.sort((a,b) => `${a.shop.brands.name}  ${a.shop.name}` > `${b.shop.brands.name}  ${b.shop.name}` ? 1 : -1)

        setOrders(orders)
        
    }
  }

  if (loading || !orders) return (
    <>
        <Cal gridPage />
        <div className='outer'>
            <Loader />
        </div>
    </>
  )

  let lastBrandName = ''
  
  let totalItems = 0
  let totalCost = 0

  return (
    <>
        <Cal gridPage />

        <div className='outer'>
            <h1 style={{marginBottom:10}}>For delivery on {format(currentDate,'PPPP')}</h1>

            <div className='table-wrapper max'>
            <table className='orders-list'>
              <thead>
                <tr>
                    <th>Shop</th>
                    <th>Order num</th>
                    <th>PO num</th>
                    <th>Status</th>
                    <th className='right'>Items</th>
                    <th className='right'>Total</th>
                    <th></th>
                </tr>
              </thead>
              <tbody>
                {orders.map(o => {

                    const shop = currentUser.shops.find(s => s.id === o.shop_id)

                    const viewOrder = () => {
                        history.push(`/orders/${format(currentDate,'dd-MMM-yyyy')}/${o.id}`)
                    }

                    const showBrandName = shop.brands.name !== lastBrandName
                    lastBrandName = shop.brands.name

                    totalItems += o.items 
                    totalCost += o.total
                    return (
                      <>
                        {showBrandName ? <tr className='brand'>
                          <td colspan={7}><h2><br/>{shop.brands.name}</h2></td>
                        </tr> : ''}

                        <tr>
                            <td>{shop.name}{shop.active ? '' : <i>inactive</i>}</td>
                            <td className='no-wrap'><OrderIcon order={o} />&nbsp;<strong>{o.id}</strong></td>
                            <td>{o.purchase_order_num}</td>
                            <td><StatusToken order={o} /></td>
                            <td className='right'>{o.items}</td>
                            <td className='right'>{penceToCurrency(o.total)}</td>
                            <td><button onClick={viewOrder} className={`inv sml order-${o.order_type}`}>{o.id}</button></td>
                        </tr>
                      </>
                    )
                })}
              </tbody>
              <tfoot>
<tr>
                  <td colspan='4'></td>
                  <td className='right'>{totalItems}</td>
                  <td className='right'>{penceToCurrency(totalCost)}</td>
                  <td></td>
              </tr>
              </tfoot>
            </table>
            </div>

        </div>
    </>
  )
}

export default withRouter(Orders);
