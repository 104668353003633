import React from "react";

export const NoPermission = () => {

    return (
    <div className='outer'>        
        <h1>Permission denied</h1>
        <h2 className='center'>Sorry but you cannot access this page</h2>
    </div>
    )
}

export default NoPermission;