
import React, { useState, useEffect } from "react";
import { UserForm } from '../../components/UserForm';
import { User } from '../../components/User';
import useModal from '../../helpers/useModal';
import Modal from '../../components/Modal';
import { Loader } from '../../helpers/loader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { supabase } from '../../api'
import ELNStore from "../../store";

export const Users = () => {

  const currentUser = ELNStore.useState(s => s.currentUser);

  const { showModal, toggleModal } = useModal()
  const [shops, setShops] = useState()
  const [users, setUsers] = useState()
  const [ user, setUser ] = useState()
  
  useEffect ( () => {
    async function fetchData() {

      const { data: users, error: usersErr } = await supabase
        .from('users')
        .select('*, shops (*, brands (*))') 
        .order('lastsignin_at', { ascending: false})    

      if (usersErr) console.error(usersErr)

      const { data: shops, error: shopsErr } = await supabase
        .from('shops')
        .select('*, brands (*)')
        .order('name')
        .order('name', {foreignTable: 'brands'})
      setUsers(users)
      setShops(shops)

      if (shopsErr) console.error(shopsErr)

    }
    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSelect = (user) => {
    setUser(user)
    toggleModal(true)
  }

  if (!users || !shops) return <Loader />
  
  if (!currentUser.superadmin) return <div className='center'><br/><br/>Permission denied</div>
  return (
    <div className='outer'>
        <h1>Users</h1>
        
        {currentUser.superadmin && <ul className='button-list'>
          <li><button className='clear' onClick={() => handleSelect()}><FontAwesomeIcon icon={faPlus} />&nbsp;ADD NEW USER</button></li>
        </ul>}

        <Modal
          isShowing={showModal}
          title={user ? 'Edit User' : 'Add New User'}
          hide={toggleModal}
        >

          <UserForm shops={shops} toggleModal={toggleModal} defaultUser={user} />

        </Modal>

        <div className='table-wrapper'>
        <table>
            <tr>
                <th></th>
                <th>Name / Email</th>
                <th>Orders</th>
                <th>Spec Cards</th>
                <th>Admin</th>
                <th className='center'>Shops</th>
                <th>LAST SIGN IN</th>
                <th></th>
                <th></th>
            </tr>
            <tr>
              <td colspan='9'><br/><strong>Shops</strong></td>
            </tr>
          {users.filter((u) => { return !u.admin}).map(user => <User user={user} handleSelect={handleSelect} />)}
          <tr>
              <td colspan='9'><br/><strong>Admins</strong></td>
            </tr>
          {users.filter((u) => { return u.admin && !u.superadmin}).map(user => <User user={user} handleSelect={handleSelect} />)}

          <tr>
              <td colspan='9'><br/><strong>Super Admins</strong></td>
            </tr>
          {users.filter((u) => { return u.superadmin}).map(user => <User user={user} handleSelect={handleSelect} />)}
        
        </table>
        </div>

    </div>
  )
}

export default Users;