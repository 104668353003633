import { supabase } from '../../api'

import { useState } from "react";

import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
import ELNStore from "../../store";
import { Logo } from "../../components/Logo";


export default function SignUp() {

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [status, setStatus] = useState('NEW')

  const currentUser = ELNStore.useState(s => s.currentUser);

  const signup = async () => {

    setStatus('SAVING')
    const { data, error } = await supabase.auth.signUp({
      email,
      password,
    })

    if (error) {
        setStatus('FAILED')
        return
      }
    if (data && data.user?.identities?.length === 0) {
        setStatus('FOUND')
        return
    }
    
    else setStatus('SUCCESS')
  }

  if (currentUser) {
    return (<div className='outer'><h1>You are already logged in</h1></div>)
  }

    return (
      <>
       
            
        <div className='outer signed-out'>

        <Logo />
          <div className='login-form'>
          <form>
         
  <h2>To sign up as a new user:</h2>

  <ol>
                <li>Enter your username and choose a password</li>
                <li>Click the button</li>
                <li>Wait for an email to arrive in your inbox</li>
                <li>Click the link in the email to verify your account</li>
            </ol>

  <label><span>Email address</span><input type='text' name='new-email' onChange={e => setEmail(e.target.value)} /></label>
        <label><span>Create a password of at least six characters</span><input type='password' name='new-password' onChange={e => setPassword(e.target.value)}/></label>
        <br/><button disabled={status === 'SAVING' || status === 'SUCCESS' || status === 'FOUND'} className="inv large" onClick={signup}>Sign Up As A New User</button>
        <br/><br/>
        {status === 'SAVING' && <strong>Please wait...</strong>}
        {status === 'FAILED' && <strong>Sorry, please try again in a few minutes</strong>}
        {status === 'FOUND' && <strong className='important'>A user account for this email address already exists. Please sign in or contact us.</strong>}
        {status === 'SUCCESS' && <strong className='important'>Thank you.  You should receive an email shortly.<br/>Click the link inside it to log in.</strong>}
        <br/><br/>
          
          <NavLink to={`/sign-in`}>I already have an account and I want to sign in</NavLink>

          </form>
          </div>
         
        </div>
        </>
    )
}