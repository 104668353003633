
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";

import { ELNStore } from '../../store'
import groupBy from 'lodash.groupby';
import { Loader } from '../../helpers/loader';
import { format, eachDayOfInterval, add, sub } from 'date-fns'
import ExportCal from '../../components/ExportCal'
import { CsvBuilder } from 'filefy';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPrint, faFileExport, faPlayCircle, faSpinner, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import { supabase } from '../../api'
import { penceToCurrency } from "../../helpers/utils";

export const Exports = ({match}) => {

  const MAX_RECORDS = 2000

  const currentUser = ELNStore.useState(s => s.currentUser);
  
  const range = ELNStore.useState(s => s.range);

  const fromDate = (range && range.length && range[0]) || new Date()
  const toDate = (range && range.length && range[1]) || new Date()

  const [loading, setLoading] = useState(false)
  const [active, setActive] = useState(false)
  const [complete, setComplete] = useState(false)

  const [orders, setOrders] = useState()
  const [shops, setShops] = useState()
  const [brands, setBrands] = useState()

  const [shopFilter, setShopFilter] = useState()

  const rangeInWords = () => {
    if (!fromDate || !toDate) return 'Loading...'
    const fromStr = format(fromDate,'dd MMM yyyy')
    const toStr = format(toDate,'dd MMM yyyy')
    if (fromStr === toStr) return fromStr
    return `${fromStr} - ${toStr}`
  }

  const abort = () => {
      setActive(false)
      setComplete(false)
      setLoading(false)
  }



  useEffect ( () => {
    async function fetchData() {

      // load shops
    const { data: shops, error: sErr } = await supabase
    .from('shops')
    .select('*, brands (*)')

  setShops(shops)
  const brandArray = shops.sort((a,b)=>(a.brands.name > b.brands.name ? 1 : -1)).groupBy(s => s.brands.name)
  setBrands(brandArray)

    }
    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const loadAll = async () => {

    setActive(true)
    setLoading(true)
    setComplete(false)

    const fromDateAdjusted = add(fromDate, { hours: 4}).toISOString().slice(0,10)
    const toDateAdjusted = sub(toDate, { hours: 4}).toISOString().slice(0,10)
    
    let query = supabase
      .from('orders')
      .select('*, orderProducts (quantity, unit_cost), shop:shops (*, brands (*)), orderCustoms (*)')
      .gte('delivery_dt', fromDateAdjusted)
      .lte('delivery_dt', toDateAdjusted)
      .match({voided: false})
      .limit(MAX_RECORDS)
    // shop
    if (shopFilter) {
      let arr = shopFilter.split(',')
      let op = 'eq'
      if (arr.length === 1) {
        arr = arr[0]
      }
      else {
        op = 'in'
        arr = `(${arr.join(',')})`
      }

      query = query.filter('shop_id', op, arr)
    }

    query = query.order('delivery_dt')

    const { data: orders, error } = await query

    setOrders(orders)

    setComplete(true)
    
    setLoading(false)
    setActive(false)
  }

  
  useEffect(() => {
    setComplete(false)
    setLoading(false)
  }, [range])


  const exportCSV = () => {
    new CsvBuilder(`Invoice-${rangeInWords()}.csv`)
    .setColumns(headings)
    .addRows(exportTable())
    .exportFile();
  }

  const doPrint = () => {
    window.print()
  }

  const headings = ['Type','Customer Reference','Date','Customer Name','Reference','Ledger Account','Details','Net','VAT Rate','VAT','Total','Analysis Type 1','Analysis Type 2','Analysis Type 3']
  
  const getTotal = (order) => {

    let total = 0

    if (order.order_type === 0) total = order.orderProducts?.reduce(function (acc, row) { return acc + (row.quantity * row.unit_cost) }, 0);
    if (order.order_type === 1) total = order.orderCustoms?.reduce((agg, oc) => agg + oc.unit_cost, 0)

    return total
  }

  let grandTotal = 0

  const exportTable = () => {

    grandTotal = 0

    const tbl = orders.map((order,i) => {

        const total = getTotal(order)
        grandTotal += total
        return [
            'Invoice',
            order.purchase_order_num,
            format(new Date(order.delivery_dt),'dd/MM/yyyy'),
            order.shop.name,
            order.id,
            '',
            '',
            penceToCurrency(total),
            'Zero Rated',
            0,
            penceToCurrency(total),
            '',
            '',
            '',

        ]

    })
    
    //sort on extra date field
    //tbl = tbl.sort(function(a, b) { return (`${a[8]} ${a[7]}` > `${b[8]} ${b[7]}` ? 1 : -1) })
    // remove date field
    //tbl = tbl.map(t => t.slice(0,-1))
  
    return tbl
  }

  const drawTable = () => {
      const tbl = exportTable()
      return (
          <>
          <div className='table-wrapper'>
            <table>
                <thead>
                <tr>
                    {headings.map(h => <th>{h}</th>)}
                </tr>  
                </thead>
                <tbody>

                    {tbl.map((row,i) => {

                        return (
                            <tr>
                                {row.map((r,i) => (
                                    <td className={i===7 || i===10 ? 'right' : ''}>{r}</td>
                                ))}        
                            </tr>
                        )
                    })}

                    <tr className='total-row'>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td className='right'>{penceToCurrency(grandTotal)}</td>
                      <td></td>
                      <td></td>
                      <td className='right'>{penceToCurrency(grandTotal)}</td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                </tbody>
            </table>
          </div>
          </>
      )
  }

  return (
    <>
        
        <div className='outer'>
          <h1 className={''}>For Delivery: {rangeInWords()}</h1>
          <div className='center'>
            <ExportCal />

            <select className='shop-filter' name='shop' onChange={(e) => setShopFilter(e.target.value)}>
              <option value=''>ALL SHOPS</option>
              {brands && Object.keys(brands).map(k => {

                const brandIds = brands[k].sort((a,b)=>(a.name > b.name ? 1 : -1)).map(s => s.id).join(',')
                return (
                  <>
                    <optgroup value=''></optgroup>
                    <option value={brandIds}>{(k || '[NOT SET]').toUpperCase()}</option>

                    {brands[k].sort((a,b)=>(a.name > b.name ? 1 : -1)).map(s => <option value={s.id}>&nbsp;&nbsp;&nbsp;&nbsp;{s.name}</option>)}

                  </>
                )
              })}
              
            </select>
            {active && <button disabled onClick={abort}><FontAwesomeIcon icon={faSpinner} spin={true} />&nbsp;&nbsp;Processing...</button>}
            {!active && <button onClick={loadAll}><FontAwesomeIcon icon={faPlayCircle} />&nbsp;&nbsp;START LOAD</button>}
          </div>
          
          {loading && <Loader />}
          {orders?.length && !active && complete && !loading ? 
          <>
            <ul className='button-list'>
                

              <li><button onClick={exportCSV}><FontAwesomeIcon icon={faFileExport} />&nbsp;&nbsp;Export CSV</button></li>
              <li><button onClick={doPrint}><FontAwesomeIcon icon={faPrint} />&nbsp;&nbsp;Print</button></li>
            </ul>
            {drawTable()}

            <br/><br/><div className='center'>{orders.length} orders shown {orders.length === MAX_RECORDS ? ' - Limit reached! - change filter' : ''}</div>
          </>
          : ''}
        </div>
    </>
  )
}

export default withRouter(Exports);
