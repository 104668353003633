import { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave, faSyncAlt, faTrash } from '@fortawesome/free-solid-svg-icons'
import { penceToCurrency } from '../../helpers/utils';

import to from 'await-to-js';
import { supabase } from '../../api'

export const ProductForm = ({defaultProduct, toggleModal, suppliers, categories }) => {


    const [ product, setProduct ] = useState(defaultProduct || {});
    const [ saving, setSaving ] = useState(false);
    const [ newCategory, setNewCategory ] = useState();

    const save = async (product) => {

        let success = true

        //save shop
        if (product.id) {
          const { data, error } = await supabase
          .from('products')
          .update(product)
          .match({ id: product.id })
          .single()
          if (error) {
            console.error('error updating', error)
            success = false
          }
        }
        else {
            const { data, error } = await supabase
            .from('products')
            .insert([
                product
            ])
            .single()
            if (error) {
                console.error('error inserting', error)
                success = false
            }
        }

        return success
    }

      
    const handleSave = async () => {
        setSaving(true)

        const cleanProduct = {...product, active: !!product.active}

        //new category?
        if (newCategory) {
            alert('new category')

            const { data: inserted, error: insertErr } = await supabase
            .from('categories')
            .insert({name: newCategory})
            .single()
            .select()
            
            if (insertErr) {
                console.error('error', insertErr)
            }
            else {
                setProduct({...product, category_id: inserted.id})
                cleanProduct.category_id = inserted.id
            }
        }


        
        delete cleanProduct.categories
        delete cleanProduct.shops
        delete cleanProduct.recipes
        delete cleanProduct.suppliers
        delete cleanProduct.duplicates
        delete cleanProduct.shopProducts

        const result = await save(cleanProduct)
        setSaving(false)
        if (result) toggleModal()
    }

    return (
    <>
        <table style={{width:500}}>
        <tr>
            <td>Supplier</td>
            <td>
                <select name='supplier_id' style={{width:'280px'}} onChange={e => setProduct({...product, supplier_id: e.target.value })} defaultValue={product.supplier_id}>
                    <option>Select a supplier...</option>
                    {suppliers.map(s => (
                        <option value={s.id}>{s.name}</option>
                    ))}

                </select>
            </td>
        </tr>
        <tr>
            <td>Category</td>
            <td>
                <select name='category_id' style={{width:'280px'}} onChange={e => setProduct({...product, category_id: e.target.value })} defaultValue={product.category_id}>
                    <option>Select a category...</option>
                    {categories.map(c => (
                        <option value={c.id}>{c.name}</option>
                    ))}

                </select>
            </td>
        </tr>
        <tr>
            <td>New Category</td>
            <td><input name='new_category' style={{width:280}} placeholder='New Category' onChange={e => setNewCategory(e.target.value)} /></td>
        </tr>
        <tr>
            <td>PID</td>
            <td><input name='pid' placeholder='PID'  defaultValue={product.pid} onChange={e => setProduct({...product, pid: e.target.value })} />
        </td>
        </tr>
        <tr>
            <td>Name</td>
            <td><input name='name' style={{width:280}} placeholder='name'  defaultValue={product.name} onChange={e => setProduct({...product, name: e.target.value })} /></td>
        </tr>
        <tr>
            <td>Details</td>
            <td><input name='details' placeholder='details' style={{width:280}}  defaultValue={product.details} onChange={e => setProduct({...product, details: e.target.value })} />
        </td>
        </tr>    
        <tr>
            <td>Unit Cost (£)</td>
            <td><input name='unit_cost' placeholder='Unit Cost'  defaultValue={penceToCurrency(product.unit_cost)} onChange={e => setProduct({...product, unit_cost: e.target.value.length ? Math.floor(parseFloat(e.target.value)*100) : null })} />
        <br/><span className='sml'>This value can be set manually.  It could be overwritten in a PW import.  When this product is made available to a shop, orders will show this default value unless a different cost is set for the shop</span>
        </td>
        </tr>  
        <tr>
            <td>Active?</td>
            <td><label><input type="checkbox" name='active' defaultChecked={product.active} onClick={e => setProduct({...product, active: e.target.checked })} /></label>
            </td>
        </tr>
        </table>

        <div className='right'>
            <button className='clear' onClick={handleSave}>
                <FontAwesomeIcon spin={saving} icon={saving ? faSyncAlt : faSave} />&nbsp;
                Save
            </button>
        </div>
    </>
    )
}

export default ProductForm;