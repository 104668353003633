import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faTimes, faSearch, faExclamationTriangle, faSave, faSyncAlt } from '@fortawesome/free-solid-svg-icons'
import { useHistory } from 'react-router-dom';
import { penceToCurrency, stringToPence } from '../../helpers/utils';

export const Product = ({ product, shops, readOnly, handleSelect, handleToggle, showAvailability, showImage }) => {

    const history = useHistory()

    const handleEdit = () => {
        handleSelect(product)
    }
    
    if (!product || !shops) return ''

    const Flag = ({shop, product, shopProduct}) => {

        const [status, setStatus] = useState(!!shopProduct)
        const [cost, setCost] = useState(shopProduct?.unit_cost)
        const [dirty, setDirty] = useState(false)
        const [saving, setSaving] = useState(false)

        const handleSelect = async () => {
            const success = await handleToggle(product, shop, true)
            if (success) {
                setStatus(true)
            }
        }

        const handleUnSelect = async () => {
            const success = await handleToggle(product, shop, false)
            if (success) {
                setStatus(false)
                setCost('')
            }
        }

        const handleSave = async () => {
            setSaving(true)
            const success = await handleToggle(product, shop, true, cost)
            if (success) {
                setSaving(false)
                setDirty(false)
            }
        }

        const handleCostChange = (e) => {

            if (isNaN(e.target.value)) e.target.value=''
            if (e.target.value<0) e.target.value = Math.abs(e.target.value)
            let newCost = stringToPence(e.target.value)
            if (newCost === 0) {
                newCost = null
                e.target.value = ''
            }
            if (newCost !== shopProduct?.unit_cost) {
                setCost(newCost)
                setDirty(true)
            }
            else setDirty(false)
        }

        return <td>
            <div className='cost-toggle'>
            <input className={`cost ${!dirty && cost ? 'has-value' : ''}`}  disabled={!status} placeholder={status ? penceToCurrency(product.unit_cost) : ''} type='text' defaultValue={penceToCurrency(cost)} onChange={handleCostChange} />
            {dirty && !saving && 
                <button className={`icon`} onClick={handleSave}>
                    <FontAwesomeIcon icon={faSave} />
                </button>}
            {saving && <button disabled className={`icon`} >
                    <FontAwesomeIcon spin={true} icon={faSyncAlt} />
                </button>}
            {!dirty && <button className={`icon ${status ? 'selected' : ''}`} onClick={status ? handleUnSelect : handleSelect}>
                <FontAwesomeIcon icon={status ? faCheck : faTimes} />
            </button>}
            
            </div>
        </td>
    }


    const displayShops = () => {
        return shops.map(s => {
        const shopProduct = product.shopProducts.find(sp => sp.shop_id === s.id)
        return <Flag shop={s} product={product} shopProduct={shopProduct} />
        })
      }

    const searchIngredient = () => {
        history.push(`/spec-cards/${encodeURIComponent(product.name)}`)
    }


    const numRecipes = product.recipes?.filter(r => r.type !== 'PRODUCT')?.length || 0

    

    return (
        <tr className={product.active ? '' : 'inactive'}>
            {!showAvailability && 
                <>
                <td>{product.suppliers?.name || '?'}</td>
                <td>{product.categories?.name || '?'}</td>
                </>
            }
            <td className='no-wrap'>{product.pid > 0 ? product.pid : ''} {product.pid && product.duplicates>1 ? <FontAwesomeIcon className='pink' title='Duplicated PID' icon={faExclamationTriangle} /> : ''}
            </td>
            <td className='right'>{penceToCurrency(product.unit_cost)}</td>
            <td className='stickyLeft'>{product.name}</td>
            {!showAvailability && 
            <>
                <td>{product.details}</td>
                <td><button onClick={searchIngredient}><FontAwesomeIcon icon={faSearch} /></button> {numRecipes > 0 ? numRecipes : ''}</td>
                <td>{product.active ? 'ACTIVE' : 'ARCHIVED'}</td>
                <td>{product.shopProducts.length}</td>
                <td><button disabled={readOnly} onClick={handleEdit}>Edit</button></td>
            </>}
            {showAvailability && shops && displayShops()}
        </tr>
    )
}

export default Product;